import React, { useEffect, useState } from "react";

import { CalendarioListadoHotelHabitacion } from "./CalendarioListadoHotelHabitacion";

import { useParams } from "react-router-dom";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { useDispatch } from "react-redux";
import { set_landing_house } from "../../../redux/reducers/landingReducer";
import Spinner from "../../../components/helpers/Spinner";
import { NavbarHotel } from "../NavbarHotel";
import { NavbarMobileHotel } from "../NavbarMobileHotel";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
    terminado: string;
    precio_base: string;
    tipo_moneda: string;
    publicado: string;
    porcentaje: number;
    disponibilidades: Disponibilidades;
}

export interface Disponibilidades {
    arrayDias: ArrayDia[];
    arrayFechas: ArrayFecha[];
}

export interface ArrayDia {
    dia: number;
    fecha_inicio: string;
    fecha_final: string;
    motivo: string;
}

export interface ArrayFecha {
    fecha: string;
    motivo: string;
}

export const CalendarioHotelHabitacion = () => {
    const dispatch = useDispatch();
    const [casas, setCasas] = useState<Casa | null>(null);

    const { id_casa } = useParams();

    useEffect(() => {
        getCasa();
    }, [id_casa]);

    const getCasa = async () => {
        if (id_casa) {
            const data = await fetchAxiosToken({
                url: `/hotel_habitaciones/detalle/${id_casa}`,
            });
            console.log(data, "data");
            setCasas(data);
        }
    };

    return (
        <div>
            {id_casa && casas ? (
                <div>
                    <NavbarHotel titulo={`Calendario ${casas?.nombre}`}>
                        <div className="w-full mx-auto">
                            <CalendarioListadoHotelHabitacion
                                casa={casas}
                                getCasa={getCasa}
                            />
                        </div>
                        <NavbarMobileHotel />
                    </NavbarHotel>
                </div>
            ) : (
                <div className="flex-col items-center justify-center">
                    <div className="flex items-center justify-center mx-auto w-28 h-28">
                        <Spinner width={28} />
                    </div>
                    <div className="mt-5 text-xl text-center">
                        Por favor, aguarde mientras cargamos los datos.
                    </div>
                </div>
            )}
        </div>
    );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import capacidad from "../../../img/onboarding/capacidad.jpg";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import "../../../assets/css/styles_input_number.css";

import { loadStripe } from "@stripe/stripe-js";
import { useDispatchDominioCorredor } from "../../../hooks/useDispatchDominioCorredor";

const listado_beneficios = [
    {
        titulo: "Marketplace de casas",
    },
    {
        titulo: "Módulo de casas",
    },
    {
        titulo: "Calendario global",
    },
    /*  {
        titulo: "ChatRoom whatsapp",
    }, */
    {
        titulo: "Módulo de CRM (Casa, whatsapp)",
    },
    /*  {
        titulo: "Mensajes masivos por whatsapp",
    }, */
    {
        titulo: "Reseñas",
    },
    {
        titulo: "Notificaciones por correo o whatsapp",
    },
    {
        titulo: "Modificación precios / noche casas de manera masiva",
    },
    {
        titulo: "Bloquear casas de manera masiva",
    },
    {
        titulo: "Historial de reservas",
    },
    {
        titulo: "Pagos por transacción o tarjeta a corredor",
    },
    /*   {
        titulo: "Plantillas para contratos por alquiler",
    },
    {
        titulo: "Integración plataformas 3eros - Urbania / Idealista",
    }, */
    {
        titulo: "Integración Airbnb / Booking - Calendario",
    },
];

interface Input {
    grupo_trabajo: string;
    token: string;

    idCasa: number | null;
}

export const PagarSuscripcionCorredor = () => {
    const { idCasa } = useParams();
    const { id: id_casa, plan } = useAppSelector(
        (state) => state.landing_house.house
    );

    const { id: id_usuario, grupo_trabajo } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
    );

    const Dos_casas = {
        plan: "2 a 4 casas",
        costo: 169,
        tipo_pago: "Mensual",
        anual: 2028,
        descuento: 406,
        price_id_mensual: process.env.REACT_APP_PLAN_DOS_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DOS_ANUAL,
    };

    const Cinco_casas = {
        plan: "5 a 9 casas",
        costo: 299,
        tipo_pago: "Mensual",
        anual: 3588,
        descuento: 718,
        price_id_mensual: process.env.REACT_APP_PLAN_CINCO_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_CINCO_ANUAL,
    };

    const Diez_casas = {
        plan: "10 a 15 casas",
        costo: 449,
        tipo_pago: "Mensual",
        anual: 5388,
        descuento: 1078,
        price_id_mensual: process.env.REACT_APP_PLAN_DIEZ_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DIEZ_ANUAL,
    };

    const PremiumEUR = {
        plan: "Premium",
        costo: 179.0,
        tipo_pago: "Anual",
        anual: 1749.0,
        descuento: 399,
    };

    const dispatchCorredor = useDispatchDominioCorredor();

    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const [currency, setCurrency] = useState("USD");
    const [moneda, setMoneda] = useState("$");

    const [suscripcion, setSuscripcion] = useState("0");

    const [Plan, setPlan] = useState(false);

    const handlePlan = () => {
        setPlan(!Plan);
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    const handlePrueba = async () => {
        // Mostrar alerta de confirmación
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "¿Deseas habilitar tu prueba gratuita de 14 días?",
            icon: "warning",
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            showCancelButton: true,
            confirmButtonText: "Sí, habilitar",
            cancelButtonText: "Cancelar",
        });

        // Si el usuario confirma
        if (result.isConfirmed) {
            try {
                const response = await fetchAxiosToken({
                    url: `/suscripcion_pagos/postPrueba`,
                    method: "post",
                    body: {
                        id_casa: 0,
                        id_corredor: id_usuario,
                    },
                });

                // Mostrar alerta de éxito
                await Swal.fire({
                    title: "¡Habilitado!",
                    text: "Tu prueba de 14 días ha sido habilitada.",
                    icon: "success",
                });
                dispatchCorredor();
                navigate(`/dashboard-corredor/onboarding/redes`);
            } catch (error) {
                // Mostrar alerta de error
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un error al habilitar la prueba.",
                    icon: "error",
                });
            }
        }
    };

    const handleSubmit = async (pagoUnico: boolean) => {
        let costo = get_costo(suscripcion);
        const amountx100 = Math.trunc(Number(costo) * 100);
        let priceId = getCodePrice(suscripcion);
        let plan =
            Number(1) > 0
                ? suscripcion === "1"
                    ? "2 a 4 casas"
                    : suscripcion === "2"
                    ? "5 a 9 casas"
                    : "10 a 15 casas"
                : suscripcion === "1"
                ? "Starter"
                : suscripcion === "2"
                ? "Stándar"
                : "Premium";

        let tipo_pago = pagoUnico ? "Mensual" : "Anual";

        let datos = {
            costo: amountx100, //Es en centavos osea x 100
            id_corredor: 0,
            plan: plan,
            tipo_moneda: currency,
            tipo_pago: tipo_pago,
            id_casa: id_casa,
            priceId,
            user: {
                subdomain: {
                    name: grupo_trabajo,
                },
                id: id_usuario,
            },
            cancel_url: "/dashboard-corredor/onboarding/suscripcion",
            success_url: "/dashboard-corredor/onboarding/redes",
        };

        try {
            if (idCasa && Number(idCasa) > 0) {
                /*    await fetchAxiosToken({
                        url: `/casa/putPersonasMascotas/${idCasa}`,
                        method: "put",
                        body: input,
                    }); */
                /*   await fetchAxiosToken({
                    url: `/casa/putPagina/${idCasa}`,
                    method: "put",
                    body: {
                        pagina: "tema",
                    },
                }); */
                /* dispatchDominio(); */
                /* navigate(`/dashboard-corredor/onboarding/tema/${idCasa}`); */
            } else {
                try {
                    const response = await fetchAxiosToken({
                        url: `/suscripcion_pagos/create-stripe/`,
                        method: "post",
                        body: datos,
                    });

                    /*  console.log(response.createSession.sessionUrl); */
                    const urlStripe = response.createSession.sessionUrl;
                    window.location.href = urlStripe;
                } catch (error) {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "error",
                        title: "Hubo un error contactarse con soporte",

                        showConfirmButton: true,
                        // timer: 2000,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getCodePrice = (suscripcion: any) => {
        if (suscripcion === "1") {
            return Plan ? Dos_casas.price_id_mensual : Dos_casas.price_id_anual;
        } else if (suscripcion === "2") {
            return Plan
                ? Cinco_casas.price_id_mensual
                : Cinco_casas.price_id_anual;
        } else {
            return Plan
                ? Diez_casas.price_id_mensual
                : Diez_casas.price_id_anual;
        }
    };

    const get_costo = (suscripcion: any) => {
        if (suscripcion === "1") {
            return Plan
                ? currency === "USD"
                    ? Dos_casas.costo
                    : Dos_casas.costo
                : currency === "USD"
                ? Number(Dos_casas.anual) - Number(Dos_casas.descuento)
                : Number(Dos_casas.anual) - Number(Dos_casas.descuento);
        } else if (suscripcion === "2") {
            return Plan
                ? currency === "USD"
                    ? Cinco_casas.costo
                    : Cinco_casas.costo
                : currency === "USD"
                ? Number(Cinco_casas.anual) - Number(Cinco_casas.descuento)
                : Number(Cinco_casas.anual) - Number(Cinco_casas.descuento);
        } else {
            return Plan
                ? currency === "USD"
                    ? Diez_casas.costo
                    : Diez_casas.costo
                : currency === "USD"
                ? Number(Diez_casas.anual) - Number(Diez_casas.descuento)
                : Number(Diez_casas.anual) - Number(Diez_casas.descuento);
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/3 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={capacidad}
                        alt="capacidad"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Elige tu plan
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center h-screen gap-2 p-3 text-center text-black  bg-white">
                <ButtonRegresar
                    url={`/dashboard-corredor/onboarding/datos`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />

                <div className="flex flex-col items-center justify-center w-11/12 gap-4 mx-auto mb-10 lg:w-5/6  2xl:mt-0 xl:mt-72 pt-56 ">
                    <div className="border rounded-2xl border-[#C0D345] md818:p-7 p-4 bg-[rgba(14,25,40,0.03)] mx-auto md818:mx-0">
                        <div className="text-xl font-bold md818:text-2xl ">
                            Nuestros beneficios
                        </div>
                        <div className="flex flex-col items-start justify-center gap-1 mt-3 ml-4 md818:ml-6">
                            {listado_beneficios.map((beneficio, index) => (
                                <div
                                    className="flex items-center justify-start gap-3"
                                    key={index}
                                >
                                    <svg
                                        className="w-2 h-2 md818:h-4 md818:w-4"
                                        viewBox="0 0 11 9"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.40761 9L0 5.3617L0.836956 4.46809L3.40761 7.21277L10.163 0L11 0.893617L3.40761 9Z"
                                            fill="#179BD8"
                                        />
                                    </svg>
                                    <div className="text-xs md818:text-sm">
                                        {beneficio.titulo}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full px-2 md818:px-5 ">
                        <div className="flex flex-col items-center justify-between gap-4 md818:flex-row md818:gap-0">
                            <div className="w-full text-lg font-bold text-center md818:text-xl md818:w-52 ">
                                Selecciona la cantidad de casas
                            </div>
                            <div className="">
                                <div className="flex items-center justify-center px-1 py-1 mx-auto border-2 w-52 rounded-3xl">
                                    <div
                                        className={`${
                                            !Plan
                                                ? "bg-[#0E1928] text-[#C0D345]"
                                                : " text-[#0E1928]"
                                        }   text-center  rounded-3xl cursor-pointer w-1/2 h-full `}
                                        onClick={handlePlan}
                                    >
                                        <p className="text-base ">Anual</p>
                                        <p
                                            className={`text-[8px] w-full  mb-1 ${
                                                !Plan
                                                    ? "text-white"
                                                    : " text-[#0E1928]"
                                            }`}
                                        >
                                            Ahorra un 20% de dscto
                                        </p>
                                    </div>
                                    <div
                                        className={` ${
                                            Plan
                                                ? "bg-[#0E1928] text-[#C0D345]"
                                                : " text-[#0E1928]"
                                        }   px-4 text-base   rounded-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                        onClick={handlePlan}
                                    >
                                        Mensual
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center w-full mt-5 ">
                            <div
                                onClick={() => setSuscripcion("1")}
                                className={`py-5 md818:px-5 px-1 border border-[#C0D345] ${
                                    suscripcion === "1"
                                        ? " bg-[#C0D345]"
                                        : "bg-[rgba(14,25,40,0.03)]"
                                }  rounded-2xl flex justify-center items-center md818:gap-10 gap-3 w-full cursor-pointer`}
                            >
                                <div className="flex items-center justify-center gap-1 md818:gap-3">
                                    <div className="relative flex items-center justify-center">
                                        <svg
                                            className="w-6 h-6 md818:w-8 md818:h-8"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15.5"
                                                fill="white"
                                                stroke="#C0D345"
                                            />
                                        </svg>
                                        {suscripcion === "1" && (
                                            <svg
                                                className="absolute w-4 h-4 md818:w-6 md818:h-6"
                                                viewBox="0 0 15 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.64674 12L0 7.14894L1.1413 5.95745L4.64674 9.61702L13.8587 0L15 1.19149L4.64674 12Z"
                                                    fill="#C0D345"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="text-xs md818:text-sm">
                                        De 2 a 4 casas
                                    </div>
                                </div>

                                <div className="flex items-center justify-center gap-3 md818:gap-7">
                                    {!Plan ? (
                                        <div
                                            className={`text-white ${
                                                suscripcion === "1"
                                                    ? "bg-[#A4B919] "
                                                    : "bg-[#0E1928]"
                                            }  md818:text-[10px] text-[9px] rounded-md py-1 md818:px-3 px-1 `}
                                        >
                                            Ahorra 20%
                                        </div>
                                    ) : (
                                        <div className=" md818:w-20 w-14"></div>
                                    )}
                                    <div className="flex items-start justify-center">
                                        <div className=" text-[#0E1928] font-semibold text-sm md818:text-base ">
                                            $
                                        </div>
                                        <div className="flex items-end justify-center">
                                            <div
                                                className={` ${
                                                    suscripcion === "1"
                                                        ? "text-white"
                                                        : "text-[#179BD8]"
                                                }  font-bold md818:text-4xl text-2xl `}
                                            >
                                                {!Plan ? "1,622" : "169"}
                                            </div>
                                            <div className="text-[#0E1928] md818:text-sm text-xs">
                                                {!Plan ? "/anual" : "/mensual"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-center w-full mt-5">
                            <div
                                onClick={() => setSuscripcion("2")}
                                className={`py-5 md818:px-5 px-1 border border-[#C0D345] ${
                                    suscripcion === "2"
                                        ? " bg-[#C0D345]"
                                        : "bg-[rgba(14,25,40,0.03)]"
                                }  rounded-2xl flex justify-center items-center md818:gap-10 gap-3 w-full cursor-pointer`}
                            >
                                <div className="flex items-center justify-center gap-1 md818:gap-3">
                                    <div className="relative flex items-center justify-center">
                                        <svg
                                            className="w-6 h-6 md818:w-8 md818:h-8"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15.5"
                                                fill="white"
                                                stroke="#C0D345"
                                            />
                                        </svg>
                                        {suscripcion === "2" && (
                                            <svg
                                                className="absolute w-4 h-4 md818:w-6 md818:h-6"
                                                viewBox="0 0 15 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.64674 12L0 7.14894L1.1413 5.95745L4.64674 9.61702L13.8587 0L15 1.19149L4.64674 12Z"
                                                    fill="#C0D345"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="text-xs md818:text-sm">
                                        De 5 a 9 casas
                                    </div>
                                </div>
                                <div className="flex items-center justify-center gap-3 md818:gap-7">
                                    {!Plan ? (
                                        <div
                                            className={`text-white ${
                                                suscripcion === "2"
                                                    ? "bg-[#A4B919] "
                                                    : "bg-[#0E1928]"
                                            }  md818:text-[10px] text-[9px] rounded-md py-1 md818:px-3 px-1 `}
                                        >
                                            Ahorra 20%
                                        </div>
                                    ) : (
                                        <div className=" md818:w-20 w-14"></div>
                                    )}
                                    <div className="flex items-start justify-center">
                                        <div className=" text-[#0E1928] font-semibold text-sm md818:text-base ">
                                            $
                                        </div>
                                        <div className="flex items-end justify-center">
                                            <div
                                                className={` ${
                                                    suscripcion === "2"
                                                        ? "text-white"
                                                        : "text-[#179BD8]"
                                                }  font-bold md818:text-4xl text-2xl `}
                                            >
                                                {!Plan ? "2,870" : "299"}
                                            </div>
                                            <div className="text-[#0E1928] md818:text-sm text-xs">
                                                {!Plan ? "/anual" : "/mensual"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-center w-full mt-5 ">
                            <div
                                onClick={() => setSuscripcion("3")}
                                className={`py-5 md818:px-5 px-1 border border-[#C0D345] ${
                                    suscripcion === "3"
                                        ? " bg-[#C0D345]"
                                        : "bg-[rgba(14,25,40,0.03)]"
                                }  rounded-2xl flex justify-center items-center md818:gap-10 gap-3 w-full cursor-pointer`}
                            >
                                <div className="flex items-center justify-center gap-1 md818:gap-3">
                                    <div className="relative flex items-center justify-center">
                                        <svg
                                            className="w-6 h-6 md818:w-8 md818:h-8"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="16"
                                                cy="16"
                                                r="15.5"
                                                fill="white"
                                                stroke="#C0D345"
                                            />
                                        </svg>
                                        {suscripcion === "3" && (
                                            <svg
                                                className="absolute w-4 h-4 md818:w-6 md818:h-6"
                                                viewBox="0 0 15 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4.64674 12L0 7.14894L1.1413 5.95745L4.64674 9.61702L13.8587 0L15 1.19149L4.64674 12Z"
                                                    fill="#C0D345"
                                                />
                                            </svg>
                                        )}
                                    </div>
                                    <div className="text-xs md818:text-sm">
                                        De 10 a 15 casas
                                    </div>
                                </div>
                                <div className="flex items-center justify-center gap-3 md818:gap-7">
                                    {!Plan ? (
                                        <div
                                            className={`text-white ${
                                                suscripcion === "3"
                                                    ? "bg-[#A4B919] "
                                                    : "bg-[#0E1928]"
                                            }  md818:text-[10px] text-[9px] rounded-md py-1 md818:px-3 px-1 `}
                                        >
                                            Ahorra 20%
                                        </div>
                                    ) : (
                                        <div className=" md818:w-20 w-14"></div>
                                    )}
                                    <div className="flex items-start justify-center">
                                        <div className=" text-[#0E1928] font-semibold text-sm md818:text-base ">
                                            $
                                        </div>
                                        <div className="flex items-end justify-center">
                                            <div
                                                className={` ${
                                                    suscripcion === "3"
                                                        ? "text-white"
                                                        : "text-[#179BD8]"
                                                }  font-bold md818:text-4xl text-2xl `}
                                            >
                                                {!Plan ? "4,310" : "449"}
                                            </div>
                                            <div className="text-[#0E1928] md818:text-sm text-xs">
                                                {!Plan ? "/anual" : "/mensual"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-start justify-center mt-5 md818:justify-end ">
                            <div
                                onClick={() => handleSubmit(Plan)}
                                className="text-white rounded-full bg-[#0E1928] py-1 px-5 hover:scale-105 cursor-pointer"
                            >
                                Comprar plan
                            </div>
                        </div>
                    </div>

                    <div
                        onClick={handlePrueba}
                        className="rounded-2xl  bg-[#0E1928]/5 p-5 w-full cursor-pointer hover:scale-105 mb-5"
                    >
                        <div className="flex items-center justify-between">
                            <div className="font-semibold">
                                Prueba gratuita de 14 días
                                <div className="text-xs">
                                    En tu prueba gratuita tendrás todas las
                                    características de 2 a 4 casas
                                </div>
                            </div>
                            <div className="px-5  py-3 bg-[#0E1928] text-white rounded-2xl text-sm">
                                De 2 a 4 casas
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import { Paginado } from "../../../components/Paginado";
// import SearchBar from "./SearchBar";
import { useNavigate, useParams } from "react-router-dom";
// import ModalUsuariosCasa from "./ModalUsuariosCasa";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { useAppSelector } from "../../../hooks/hooks";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { ModalOnePago } from "./ModalOnePago";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
}

export interface CasasChats {
    id: number;
    count_chats: number;
    casa: Casa;
}

export interface SuscripcionActual {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
}
export interface PagoSuscricion {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    fecha_inicio: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
    casa: Casa;
    actual: string;
    tipo_moneda: string;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

export const Suscripciones = () => {
    AOS.init();

    const { headers } = useHeaders();
    const [suscricionActual, setSuscripcionActual] =
        useState<SuscripcionActual>();
    const [pagoSelect, setPagoSelect] = useState<PagoSuscricion>();
    const [comforts, setComforts] = useState<PagoSuscricion[]>([]);
    const { id: id_casa } = useAppSelector(
        (state) => state.landing_house.house
    );

    const { idCasa } = useParams();

    const {
        id: id_usuario,
        tipo_usuario,
        grupo_trabajo,
    } = useAppSelector((state) => state.usuario_logeado);

    const [Plan, setPlan] = useState(false);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [moneda, setMoneda] = useState("$");
    // const [comfortEdit, setComfortEdit] = useState<UsuariosCasa>();
    const [cargando, setCargando] = useState<boolean>(false);

    const navigate = useNavigate();
    const [mes, setMes] = useState<number>(new Date().getMonth() + 1);
    const [ano, setAno] = useState<number>(new Date().getFullYear());
    const [currency, setCurrency] = useState("USD");
    const [verMas, setVerMas] = useState<boolean>(false);

    const Starter = {
        plan: "Starter",
        costo: 19,
        tipo_pago: "Mensual",
        anual: 228,
        descuento: 36,
        price_id_mensual: process.env.REACT_APP_PLAN_STARTER_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_STARTER_ANUAL,
    };
    const Standard = {
        plan: "Stándar",
        costo: 39.0,
        tipo_pago: "Mensual",
        anual: 468.0,
        descuento: 84,
        price_id_mensual: process.env.REACT_APP_PLAN_ESTANDAR_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_ESTANDAR_ANUAL,
    };
    const StandardEUR = {
        plan: "Stándar",
        costo: 89.0,
        tipo_pago: "Mensual",
        anual: 869.0,
        descuento: 199,
    };
    const Premium = {
        plan: "Premium",
        costo: 79.0,
        tipo_pago: "Mensual",
        anual: 948.0,
        descuento: 180,
        price_id_mensual: process.env.REACT_APP_PLAN_PREMIUM_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_PREMIUM_ANUAL,
    };
    const PremiumEUR = {
        plan: "Premium",
        costo: 179.0,
        tipo_pago: "Anual",
        anual: 1749.0,
        descuento: 399,
    };

    const Dos_casas = {
        plan: "2 a 4 casas",
        costo: 169,
        tipo_pago: "Mensual",
        anual: 2028,
        descuento: 406,
        price_id_mensual: process.env.REACT_APP_PLAN_DOS_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DOS_ANUAL,
    };

    const Cinco_casas = {
        plan: "5 a 9 casas",
        costo: 299,
        tipo_pago: "Mensual",
        anual: 3588,
        descuento: 718,
        price_id_mensual: process.env.REACT_APP_PLAN_CINCO_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_CINCO_ANUAL,
    };

    const Diez_casas = {
        plan: "10 a 15 casas",
        costo: 449,
        tipo_pago: "Mensual",
        anual: 5388,
        descuento: 1078,
        price_id_mensual: process.env.REACT_APP_PLAN_DIEZ_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DIEZ_ANUAL,
    };

    const getPedidos = async ({ pagina, habilitado, query }: getComfort) => {
        console.log("tipo_usuario", tipo_usuario);
        console.log("id_casa", id_casa);
        try {
            setCargando(false);
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/${
                    tipo_usuario === 6 ? "getAllCorredores" : "getAll"
                }/${pagina}/${tipo_usuario === 6 ? id_usuario : id_casa}`,
                { headers }
            );

            setComforts(data);
            // setTotal(data.total)
            setCargando(true);
        } catch (error) {
            console.log(error);
        }
    };
    const getSuscripcionActual = async () => {
        try {
            // setCargando(false)
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/${
                    tipo_usuario === 6
                        ? "getCurrentSuscripcionCorredor"
                        : "getCurrentSuscripcion"
                }/${tipo_usuario === 6 ? id_usuario : id_casa}`,
                { headers }
            );

            setSuscripcionActual(data);
            // setTotal(data.total)
            // setCargando(true)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (tipo_usuario === 6 || id_casa > 0) {
            getSuscripcionActual();
            getPedidos({
                pagina: pageNumber,
                habilitado: enabled,
                query: "",
            });
        }
    }, [id_casa, tipo_usuario, enabled, create, mes, ano]);

    const getOneSuscripcion = async (pagoSelect: PagoSuscricion) => {
        setPagoSelect(pagoSelect);
        setVerMas(true);
    };

    const handlePlan = () => {
        setPlan(!Plan);
    };

    const handleSubmit = async (suscripcion: any, pagoUnico: boolean) => {
        let costo = get_costo(suscripcion);
        const amountx100 = Math.trunc(Number(costo) * 100);
        let priceId = getCodePrice(suscripcion);
        let plan =
            tipo_usuario === 6
                ? suscripcion === "1"
                    ? "2 a 4 casas"
                    : suscripcion === "2"
                    ? "5 a 9 casas"
                    : "10 a 15 casas"
                : suscripcion === "1"
                ? "Starter"
                : suscripcion === "2"
                ? "Stándar"
                : "Premium";

        let tipo_pago = pagoUnico ? "Mensual" : "Anual";

        let datos = {
            costo: amountx100, //Es en centavos osea x 100
            id_corredor: 0,
            plan: plan,
            tipo_moneda: currency,
            tipo_pago: tipo_pago,
            id_casa: id_casa,
            priceId,
            user: {
                subdomain: {
                    name: grupo_trabajo,
                },
                id: id_usuario,
            },
            cancel_url: `${
                tipo_usuario === 6
                    ? "/dashboard-corredor/suscripciones"
                    : "/dashboard-corredor/suscripciones"
            }`,
            success_url: `${
                tipo_usuario === 6
                    ? "/dashboard-corredor/suscripciones"
                    : "/dashboard-corredor/suscripciones"
            }`,
        };

        try {
            if (idCasa && Number(idCasa) > 0) {
                /*    await fetchAxiosToken({
                        url: `/casa/putPersonasMascotas/${idCasa}`,
                        method: "put",
                        body: input,
                    }); */
                /*   await fetchAxiosToken({
                    url: `/casa/putPagina/${idCasa}`,
                    method: "put",
                    body: {
                        pagina: "tema",
                    },
                }); */
                /* dispatchDominio(); */
                /* navigate(`/dashboard-corredor/onboarding/tema/${idCasa}`); */
            } else {
                try {
                    const response = await fetchAxiosToken({
                        url: `/suscripcion_pagos/create-stripe/`,
                        method: "post",
                        body: datos,
                    });

                    /*  console.log(response.createSession.sessionUrl); */
                    const urlStripe = response.createSession.sessionUrl;
                    window.location.href = urlStripe;
                } catch (error) {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "error",
                        title: "Hubo un error contactarse con soporte",

                        showConfirmButton: true,
                        // timer: 2000,
                    });
                }

                /* navigate(`/dashboard/onboarding/tema`); */
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getCodePrice = (suscripcion: any) => {
        if (tipo_usuario === 6) {
            if (suscripcion === "1") {
                return Plan
                    ? Dos_casas.price_id_mensual
                    : Dos_casas.price_id_anual;
            } else if (suscripcion === "2") {
                return Plan
                    ? Cinco_casas.price_id_mensual
                    : Cinco_casas.price_id_anual;
            } else {
                return Plan
                    ? Diez_casas.price_id_mensual
                    : Diez_casas.price_id_anual;
            }
        } else {
            if (suscripcion === "1") {
                return Plan ? Starter.price_id_mensual : Starter.price_id_anual;
            } else if (suscripcion === "2") {
                return Plan
                    ? Standard.price_id_mensual
                    : Standard.price_id_anual;
            } else {
                return Plan ? Premium.price_id_mensual : Premium.price_id_anual;
            }
        }
    };

    const get_costo = (suscripcion: any) => {
        if (tipo_usuario === 6) {
            if (suscripcion === "1") {
                return Plan
                    ? currency === "USD"
                        ? Dos_casas.costo
                        : Dos_casas.costo
                    : currency === "USD"
                    ? Number(Dos_casas.anual) - Number(Dos_casas.descuento)
                    : Number(Dos_casas.anual) - Number(Dos_casas.descuento);
            } else if (suscripcion === "2") {
                return Plan
                    ? currency === "USD"
                        ? Cinco_casas.costo
                        : Cinco_casas.costo
                    : currency === "USD"
                    ? Number(Cinco_casas.anual) - Number(Cinco_casas.descuento)
                    : Number(Cinco_casas.anual) - Number(Cinco_casas.descuento);
            } else {
                return Plan
                    ? currency === "USD"
                        ? Diez_casas.costo
                        : Diez_casas.costo
                    : currency === "USD"
                    ? Number(Diez_casas.anual) - Number(Diez_casas.descuento)
                    : Number(Diez_casas.anual) - Number(Diez_casas.descuento);
            }
        } else {
            if (suscripcion === "1") {
                return Plan
                    ? currency === "USD"
                        ? Starter.costo
                        : Starter.costo
                    : currency === "USD"
                    ? Number(Starter.anual) - Number(Starter.descuento)
                    : Number(Starter.anual) - Number(Starter.descuento);
            } else if (suscripcion === "2") {
                return Plan
                    ? currency === "USD"
                        ? Standard.costo
                        : Standard.costo
                    : currency === "USD"
                    ? Number(Standard.anual) - Number(Standard.descuento)
                    : Number(Standard.anual) - Number(Standard.descuento);
            } else {
                return Plan
                    ? currency === "USD"
                        ? Premium.costo
                        : PremiumEUR.costo
                    : currency === "USD"
                    ? Number(Premium.anual) - Number(Premium.descuento)
                    : Number(Premium.anual) - Number(Premium.descuento);
            }
        }
    };

    const render = () => {
        return (
            <div>
                <div className="absolute z-[999] flex items-center justify-center w-full text-center">
                    {verMas && pagoSelect !== undefined && (
                        <ModalOnePago
                            modalVisible={verMas}
                            setModalVisible={setVerMas}
                            pagoSelect={pagoSelect}
                        />
                    )}
                </div>

                <div className="w-full mt-7 border-[#CCCCCC] border-2 rounded-xl ">
                    <div className="flex justify-around w-full px-2 xs:px-6 mx-auto border-[#CCCCCC] border-b-2 2xl:py-8 py-3 text-[#333333]">
                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Plan actual:
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] bg-[#DAF900] bg-opacity-20 2xl:text-base text-sm">
                                {suscricionActual?.plan}
                            </p>
                        </div>

                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Vencimiento:
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] 2xl:text-base text-sm">
                                <ShowDateToUser
                                    date={suscricionActual?.fecha_vencimiento.slice(
                                        0,
                                        10
                                    )}
                                />
                            </p>
                        </div>

                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Ciclo de Pago:{" "}
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] 2xl:text-base text-sm">
                                {suscricionActual?.tipo_pago}
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col items-start justify-start w-5/6 mx-auto mt-5 2xl:mt-10 ">
                        <div className="flex flex-col justify-center h-auto mx-auto mb-5">
                            <div className="">
                                <div className="flex items-center justify-center px-1 py-1 mx-auto border-2 w-60 rounded-3xl">
                                    <div
                                        className={`${
                                            !Plan
                                                ? "bg-[#0E1928] text-[#C0D345]"
                                                : " text-[#0E1928]"
                                        }   text-center  rounded-3xl cursor-pointer w-1/2 h-full `}
                                        onClick={handlePlan}
                                    >
                                        <p className="text-lg ">Anual</p>
                                        <p
                                            className={`text-[8px] w-full  mb-1 ${
                                                !Plan
                                                    ? "text-white"
                                                    : " text-[#0E1928]"
                                            }`}
                                        >
                                            Ahorra un 20% de dscto
                                        </p>
                                    </div>
                                    <div
                                        className={` ${
                                            Plan
                                                ? "bg-[#0E1928] text-[#C0D345]"
                                                : " text-[#0E1928]"
                                        }   px-4 text-lg   rounded-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                        onClick={handlePlan}
                                    >
                                        Mensual
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-center w-full gap-6 mx-auto lg:flex-row ">
                            <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5 h-auto 2xl:w-[400px] w-[333px] mb-4">
                                <div
                                    className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                                >
                                    Starter
                                </div>

                                <div className="flex flex-col gap-8 md:gap-0">
                                    <div className="flex flex-col ">
                                        <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                            {Plan ? (
                                                <div className="flex">
                                                    <p className="text-base font-bold">
                                                        {moneda}
                                                    </p>
                                                    <p className="text-[#179BD8] text-4xl font-bold">
                                                        {" "}
                                                        {moneda === "$"
                                                            ? 19
                                                            : 19}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="flex">
                                                    <p className="text-base font-bold">
                                                        {moneda}
                                                    </p>
                                                    <p className="text-4xl text-[#179BD8] font-bold">
                                                        {" "}
                                                        {moneda === "$"
                                                            ? 16
                                                            : 16}
                                                    </p>
                                                </div>
                                            )}

                                            <div className="text-base font-semibold xl:text-lg">
                                                /mes
                                            </div>
                                        </div>
                                        <div className="text-[#0E1928]  text-xs  flex items-center gap-1 mt-2">
                                            {moneda}
                                            {!Plan
                                                ? moneda === "$"
                                                    ? "192"
                                                    : "192"
                                                : moneda === "$"
                                                ? "228"
                                                : "228"}{" "}
                                            al año.
                                            {!Plan && (
                                                <div className="text-[#179BD8] text-xs font-bold">
                                                    Ahorras {moneda}
                                                    {moneda === "$" ? 36 : 36}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col text-left">
                                        <div
                                            className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                        >
                                            <ul className="marker:text-[#0E1928] text-xs 2xl:text-base md:text-md list-outside list-disc ml-3">
                                                <li className="mb-1">
                                                    Landing personalizada
                                                </li>
                                                <li className="mb-1">
                                                    Dominio para tu página
                                                </li>
                                                <li className="mb-1">
                                                    Reseñas de clientes
                                                </li>
                                                <li className="mb-1">
                                                    Calendario de reservas
                                                </li>
                                                <li className="mb-1">
                                                    Pago por transferencia
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => handleSubmit("1", Plan)}
                                        className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer hover:scale-105 flex  w-full text-center mx-auto"
                                    >
                                        <p className="w-full text-center">
                                            SUSCRIBIRSE
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col  rounded-2xl  bg-[#0E1928] text-white border-solid px-5  h-auto 2xl:w-[400px] w-[333px] mb-4 ">
                                <div
                                    className={` text-lg md:text-xl xl:text-2xl flex items-center justify-between w-full  ${"mt-5"} `}
                                >
                                    <p>Estándar</p>{" "}
                                    <p className="text-[10px] text-[#C0D345] bg-[#C0D345]/10 rounded-3xl py-1 px-2 ">
                                        El más seleccionado
                                    </p>
                                </div>

                                <div className="flex flex-col gap-8 md:gap-0">
                                    <div className="flex flex-col ">
                                        <div className="flex items-end mt-2 text-2xl md:text-3xl xl:text-5xl">
                                            {Plan ? (
                                                <div className="flex">
                                                    <p className="text-base font-bold">
                                                        {moneda}
                                                    </p>
                                                    <p className="text-4xl font-bold text-[#C0D345]">
                                                        {" "}
                                                        {moneda === "$"
                                                            ? 39
                                                            : 39}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="flex">
                                                    <p className="text-base font-bold">
                                                        {moneda}
                                                    </p>
                                                    <p className="text-4xl text-[#C0D345] font-bold">
                                                        {" "}
                                                        {moneda === "$"
                                                            ? 32
                                                            : 32}
                                                    </p>
                                                </div>
                                            )}

                                            <div className="text-base font-semibold xl:text-lg">
                                                /mes
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-1 mt-2 text-xs ">
                                            {moneda}
                                            {!Plan
                                                ? moneda === "$"
                                                    ? "384"
                                                    : "384"
                                                : moneda === "$"
                                                ? "468"
                                                : "468"}{" "}
                                            al año.
                                            {!Plan && (
                                                <div className="text-[#C0D345] text-xs   font-bold">
                                                    Ahorras {moneda}
                                                    {moneda === "$" ? 84 : 84}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col text-left">
                                        <div className={` text-sm  mt-4 mb-0`}>
                                            <ul className="ml-3 text-xs list-disc list-outside marker:text-white 2xl:text-base md:text-md">
                                                <li className="mb-1">
                                                    CRM de clientes
                                                </li>
                                                <li className="mb-1">
                                                    Plataformas de pago
                                                </li>
                                                <li className="mb-1">
                                                    Creación de cupones
                                                </li>
                                                <li className="mb-1">
                                                    Chat y gestión de clientes
                                                </li>
                                                <li className="mb-1">
                                                    Resumen de estadísticas
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => handleSubmit("2", Plan)}
                                        className="bg-[#C0D345] py-1 px-8 my-8 text-base md:text-xl text-[#0E1928] font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer hover:scale-105 flex w-full text-center mx-auto"
                                    >
                                        <p className="w-full text-center">
                                            SUSCRIBIRSE
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto 2xl:w-[400px] w-[333px] mb-4">
                                <div
                                    className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                                >
                                    Premium
                                </div>

                                <div className="flex flex-col gap-8 md:gap-0">
                                    <div className="flex flex-col ">
                                        <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                            {Plan ? (
                                                <div className="flex">
                                                    <p className="text-base font-bold">
                                                        {moneda}
                                                    </p>
                                                    <p className="text-4xl text-[#179BD8] font-bold">
                                                        {" "}
                                                        {moneda === "$"
                                                            ? 79
                                                            : 79}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="flex">
                                                    <p className="text-base font-bold">
                                                        {moneda}
                                                    </p>
                                                    <p className="text-4xl text-[#179BD8] font-bold">
                                                        {" "}
                                                        {moneda === "$"
                                                            ? 64
                                                            : 64}
                                                    </p>
                                                </div>
                                            )}

                                            <div className="text-base font-semibold xl:text-lg">
                                                /mes
                                            </div>
                                        </div>
                                        <div className="text-[#0E1928]  text-xs  flex items-center gap-1 mt-2">
                                            {moneda}
                                            {!Plan
                                                ? moneda === "$"
                                                    ? "768"
                                                    : "768"
                                                : moneda === "$"
                                                ? "948"
                                                : "948"}{" "}
                                            al año.
                                            {!Plan && (
                                                <div className="text-[#179BD8] text-xs  font-bold">
                                                    Ahorras {moneda}
                                                    {moneda === "$" ? 180 : 180}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col text-left">
                                        <div
                                            className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                        >
                                            <ul className="marker:text-[#0E1928] text-xs 2xl:text-base md:text-md list-outside list-disc ml-3">
                                                <li className="mb-1">
                                                    Chatbot interno
                                                </li>
                                                <li className="mb-1">
                                                    Integrar google calendar
                                                </li>
                                                <li className="mb-1">
                                                    Plantillas para contratos
                                                </li>
                                                <li className="mb-1">
                                                    +2 Subusuarios
                                                </li>
                                                <li className="mb-1">
                                                    Integrar Airbnb y Booking
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => handleSubmit("3", Plan)}
                                        className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer hover:scale-105 flex w-full text-center mx-auto"
                                    >
                                        <p className="w-full text-center">
                                            SUSCRIBIRSE
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center w-full">
                        <p className="mx-auto text-[#1C4CBB] font-bold 2xl:text-3xl text-2xl">
                            Historial
                        </p>
                    </div>
                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="w-11/12 lg:mx-auto">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {cargando ? (
                                            <>
                                                <div className={` `}>
                                                    <table className="w-full mt-2 overflow-x-auto">
                                                        <thead className="text-sm text-left 2xl:text-base">
                                                            {comforts.length ? (
                                                                <tr className="border-y-2  border-[#CCCCCC]">
                                                                    <th className="w-full min-w-[6rem] md:w-4/12 py-4 text-center ">
                                                                        Monto
                                                                    </th>
                                                                    <th className="w-full min-w-[8rem] md:w-4/12 table-cell py-4 text-center">
                                                                        Fecha de
                                                                        pago
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden lg:table-cell py-4">
                                                                        Fecha
                                                                        inicio
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden lg:table-cell py-4">
                                                                        Fecha
                                                                        final
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden md723:table-cell py-4">
                                                                        Plan
                                                                    </th>

                                                                    <th className="w-2/12 text-center min-w-[6rem] md:table-cell   py-4">
                                                                        Estado
                                                                    </th>
                                                                </tr>
                                                            ) : (
                                                                <p className="text-center"></p>
                                                            )}
                                                        </thead>
                                                        <div className="2xl:py-2"></div>
                                                        <tbody className="text-sm 2xl:text-base">
                                                            {comforts?.map(
                                                                (
                                                                    comforts,
                                                                    i
                                                                ) => (
                                                                    <tr
                                                                        onClick={() =>
                                                                            getOneSuscripcion(
                                                                                comforts
                                                                            )
                                                                        }
                                                                        key={i}
                                                                        className={`w-full ${
                                                                            comforts.actual ===
                                                                            "Si"
                                                                                ? "bg-[#5aad00] bg-opacity-25"
                                                                                : "bg-white"
                                                                        } `}
                                                                    >
                                                                        <td className="border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className="mx-3 text-[#3C3C3C]">
                                                                                    {
                                                                                        comforts?.costo
                                                                                    }{" "}
                                                                                    {
                                                                                        comforts?.tipo_moneda
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_pago
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="lg:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_inicio
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="lg:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_vencimiento
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="md723:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.plan
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.estado
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Suscripciones">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Suscripciones">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : null;
};

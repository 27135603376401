import React, { useState, useEffect, useRef } from "react";
import {
    GoogleMap,
    LoadScript,
    Marker,
    StandaloneSearchBox,
} from "@react-google-maps/api";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { useParams } from "react-router-dom";

interface Address {
    pais: string;
    ciudad: string;
    departamento: string;
}

interface Coordinates {
    lat: number;
    lng: number;
    zoom: number;
}

interface Props {
    modalVisibleLocation: boolean;
    setModalVisibleLocation: (modalVisibleLocation: boolean) => void;
}

export const ModalEditLocation = ({
    setModalVisibleLocation,
    modalVisibleLocation,
}: Props) => {
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const {
        id: id_casa,
        latitud,
        longitud,
        pais,
        ciudad,
        departamento,
        tipo_moneda,
    } = useAppSelector((state) => state.landing_house.house);
    const [coordinates, setCoordinates] = useState<Coordinates>({
        lat: Number(latitud),
        lng: Number(longitud),
        zoom: 14,
    });
    const [address, setAddress] = useState<Address>({
        ciudad: ciudad,
        departamento: departamento,
        pais: pais,
    });
    const map = useRef<GoogleMap>(null);
    const [searchBox, setSearchBox] =
        useState<google.maps.places.SearchBox | null>(null);
    const [saving, setSaving] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (
            address.pais === "" ||
            address.ciudad === "" ||
            address.departamento === ""
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Para actualizar la dirección debe tener pais , departamento y ciudad ",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        setSaving(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putMapa/${id_casa}`,
                method: "put",
                body: {
                    latitud: coordinates.lat,
                    longitud: coordinates.lng,
                    pais: address.pais,
                    ciudad: address.ciudad,
                    departamento: address.departamento,
                    tipo_moneda,
                },
            });

            dispatchDominio();

            setCoordinates({
                lat: data.latitud || -12.04318,
                lng: data.longitud || -77.02824,
                zoom: 11,
            });
            setAddress({
                ciudad: data.ciudad || "",
                departamento: data.departamento || "",
                pais: data.pais || "",
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizada",
                text: "Direccion actualizada con exito",
                icon: "success",
                confirmButtonText: "Ok",
            });
            setSaving(false);
            setModalVisibleLocation(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const handlePlacesChanged = () => {
        if (searchBox) {
            const places: any = searchBox.getPlaces();
            if (places.length === 0) return;

            const place = places[0];
            console.log(place);
            const location = place.geometry?.location;
            if (location) {
                setCoordinates({
                    lat: location.lat(),
                    lng: location.lng(),
                    zoom: 14,
                });
            }
        }
    };

    const onSearchBoxMounted = (ref: google.maps.places.SearchBox) => {
        setSearchBox(ref);
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleLocation}
            setModalVisible={setModalVisibleLocation}>
            <div className="flex flex-col items-center max-w-lg gap-4 mx-auto ">
                <h2 className="text-base font-medium">Editar ubicacion</h2>
                <hr className="w-full bg-gray-300" />

                <div className="pt-4 pb-4">
                    <h2 className="text-sm font-medium 2xl:text-base">
                        Escribe tu ubicación para acercarte rápidamente a tu
                        localidad
                    </h2>
                    <div className="min-h-[calc(100vh-29.3vh)]  mx-auto mt-2">
                        <div className="h-[25rem] 2xl:h-[30rem]">
                            <StandaloneSearchBox
                                onLoad={onSearchBoxMounted}
                                onPlacesChanged={handlePlacesChanged}>
                                <input
                                    type="text"
                                    placeholder="Busca tu ubicación"
                                    className="z-50 input-box"
                                    style={{
                                        boxSizing: `border-box`,
                                        border: `1px solid transparent`,
                                        width: `280px`,
                                        height: `32px`,
                                        padding: `0 12px`,
                                        borderRadius: `3px`,
                                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                        fontSize: `14px`,
                                        outline: `none`,
                                        textOverflow: `ellipses`,
                                        position: "absolute",
                                        left: "50%",
                                        marginLeft: "-140px",
                                    }}
                                />
                            </StandaloneSearchBox>
                            <GoogleMap
                                options={{ disableDefaultUI: true }}
                                mapContainerStyle={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "8px",
                                }}
                                center={coordinates}
                                zoom={coordinates.zoom}
                                onClick={(e) =>
                                    setCoordinates({
                                        ...coordinates,
                                        lat: e.latLng?.lat() || coordinates.lat,
                                        lng: e.latLng?.lng() || coordinates.lng,
                                    })
                                }>
                                <Marker
                                    position={{
                                        lat: coordinates.lat,
                                        lng: coordinates.lng,
                                    }}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        setCoordinates({
                                            ...coordinates,
                                            lat:
                                                e.latLng?.lat() ||
                                                coordinates.lat,
                                            lng:
                                                e.latLng?.lng() ||
                                                coordinates.lng,
                                        });
                                    }}
                                />
                            </GoogleMap>
                        </div>
                        <div className="w-full mt-3 text-left">
                            <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                Pais:
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        pais: e.target.value,
                                    })
                                }
                                type="text"
                                value={address.pais}
                                placeholder="Ej: Peru"
                                className="w-full my-2 text-sm bg-transparent border-none outline-none 2xl:my-3 2xl:text-base focus:outline-none placeholder:text-gray-400"
                            />
                        </div>
                        <div className="w-full mt-3 text-left">
                            <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                Departamento:
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        departamento: e.target.value,
                                    })
                                }
                                type="text"
                                value={address.departamento}
                                placeholder="Ej: Arequipa"
                                className="w-full my-2 text-sm bg-transparent border-none outline-none 2xl:my-3 2xl:text-base focus:outline-none placeholder:text-gray-400"
                            />
                        </div>
                        <div className="w-full mt-3 text-left">
                            <header className="mb-3  text-[#3C3C3C]  2xl:text-base text-sm">
                                Ciudad:
                            </header>
                        </div>
                        <div className="w-full px-4 rounded-lg border border-[#E9E9E9]  ">
                            <input
                                onChange={(e) =>
                                    setAddress({
                                        ...address,
                                        ciudad: e.target.value,
                                    })
                                }
                                type="text"
                                value={address.ciudad}
                                placeholder="Ej: Mollendo"
                                className="w-full my-2 text-sm bg-transparent border-none outline-none 2xl:my-3 2xl:text-base focus:outline-none placeholder:text-gray-400"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-2 2xl:mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={handleSubmit}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105  2xl:text-base text-sm">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { ButtonSiguiente } from "./ButtonSiguiente";
import comodidadesImg from "../../img/onboarding/comodidades.jpg";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";

interface Comodidad {
    id: number;
    nombre: string;
    icono: string;
}

export const ComodidadesHotel = () => {
    const [comodidades, setComodidades] = useState<Comodidad[]>([]);
    const [comodidadesSelected, setComodidadesSelected] = useState<Comodidad[]>(
        []
    );
    const { id: id_casa_redux } = useAppSelector(
        (state) => state.landing_house.house
    );
    const id_casa = id_casa_redux;
    const navigate = useNavigate();
    const dispatchDominio = useDispatchDominio(undefined);

    useEffect(() => {
        fetchAxiosToken({
            url: `/casa/getOne/id/${id_casa}`,
        }).then((data) =>
            setComodidadesSelected(
                data.casa_comodidades.map((comodidad: any) => {
                    return {
                        id: comodidad.id_comodidad,
                        nombre: comodidad.comodidade.nombre,
                        icono: comodidad.comodidade.icono,
                    };
                })
            )
        );

        fetchAxiosToken({
            url: `/comodidad/getAll/0/Si?query=`,
        }).then((data) => setComodidades(data.rows));
    }, []);

    const isSelected = (comodidad: Comodidad) =>
        comodidadesSelected.some(
            (comodidades) => comodidades.id === comodidad.id
        );

    const handleComodidadClick = (comodidad: Comodidad) => {
        if (isSelected(comodidad)) {
            setComodidadesSelected((prev) =>
                prev.filter((comodidades) => comodidades.id !== comodidad.id)
            );
        } else {
            setComodidadesSelected([...comodidadesSelected, comodidad]);
        }
    };

    const handleSubmit = async () => {
        try {
            const data = await fetchAxiosToken({
                url: `/casa_comodidades/`,
                method: "post",
                body: {
                    id_casa: id_casa,
                    comodidades: comodidadesSelected.map(
                        (comodidad) => comodidad.id
                    ),
                },
            });

            await fetchAxiosToken({
                url: `/casa/putPagina/${id_casa}`,
                method: "put",
                body: {
                    pagina: "habitaciones",
                },
            });

            dispatchDominio();

            navigate(`/dashboard-hotel/onboarding/ubicacion`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={comodidadesImg}
                        alt="comodidades"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Escoge las comodidades que posea tu hotel
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás agregar o eliminarlas más
                            adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`${"/dashboard-hotel/onboarding/imagenes"}`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col pb-4 mt-20 2xl:mt-44 lg:mt-36">
                    <div className="grid grid-cols-2 sm:grid-cols-3 justify-center items-center gap-8 mt-8 lg:w-11/12 xl:w-3/4 2xl:w-2/3 mx-auto overflow-y-scroll h-[70vh]">
                        {comodidades.map((comodidad) => (
                            <div
                                key={comodidad.id}
                                onClick={() => handleComodidadClick(comodidad)}
                                className={`${
                                    isSelected(comodidad)
                                        ? "bg-gray-300"
                                        : "bg-white"
                                } border-2 rounded-lg font-medium gap-3 cursor-pointer p-2 flex flex-col justify-center text-black items-center 2xl:w-36 w-32 h-28 2xl:h-32`}>
                                <img
                                    className="object-contain w-7 h-7 md:w-10 md:h-10"
                                    src={`${process.env.REACT_APP_PROXY}/comodidad/getImage/${comodidad.icono}`}
                                    alt={comodidad.nombre}
                                />
                                <span className=" text-[10px] lg:text-xs 2xl:text-sm capitalize">
                                    {comodidad.nombre}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className="w-2/3 mx-auto">
                        <ButtonSiguiente onSubmit={() => handleSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

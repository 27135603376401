import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchAxiosNoToken } from "../helpers/fetchAxiosNoToken";
import { set_landing_house } from "../redux/reducers/landingReducer";
import Design1 from "../designs/design1New/Design1";
import Design2 from "../designs/design2New/Design2";
import Design3 from "../designs/design3New/Design3";
import Design4 from "../designs/design4New/Design4";

export const TemaEjemplo = (tipo_usuario: { tipo_usuario: number }) => {
    const { number } = useParams();

    const [selectedDesign, setSelectedDesign] = useState<string | undefined>(
        number
    );
    useEffect(() => {
        getHouseFromTenant();
    }, []);

    const dispatch = useDispatch();

    const getHouseFromTenant = async () => {
        console.log("cargando landing");
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/id/10`,
            });

            dispatch(set_landing_house(data));
        } catch (error) {
            console.log(error);
        }
    };
    switch (selectedDesign) {
        case "1":
            return <Design1 tipo_landing={tipo_usuario.tipo_usuario} />;

        case "2":
            return <Design2 tipo_landing={tipo_usuario.tipo_usuario} />;

        case "3":
            return <Design3 tipo_landing={tipo_usuario.tipo_usuario} />;
        case "4":
            return <Design4 tipo_landing={tipo_usuario.tipo_usuario} />;

        default:
            return (
                <div className="grid content-center w-full h-screen text-2xl font-medium text-center text-white bg-black/80">
                    ¡El diseño {number} no existe! Por favor escoge un diseño
                    del 1 al 4
                </div>
            );
    }
};

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";

import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { useParams } from "react-router-dom";

interface Props {
    modalVisible: boolean;
    setModalVisible: (s: boolean) => void;
    nombre: string;
    descripcion: string;
    id_habitacion: number;
}

export interface Nombre {
    nombre: string;
    descripcion: string;
}

export const ModalEditNameHabitacion = ({
    modalVisible,
    setModalVisible,
    nombre,
    descripcion,
    id_habitacion,
}: Props) => {
    const dispatchDominio = useDispatchDominio();
    const [saving, setSaving] = useState<boolean>(false);
    const [input, setInput] = useState<Nombre>({
        nombre: nombre,
        descripcion: descripcion,
    });

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        if (input.nombre === "")
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "El nombre de la habitación no puede estar vacio",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });

        if (input.descripcion === "")
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "La descripción de la habitación no puede estar vacio",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });

        try {
            setSaving(true);
            await fetchAxiosToken({
                url: `/hotel_habitaciones/updateNombre/${id_habitacion}`,
                method: "put",
                body: input,
            });

            dispatchDominio();
            setTimeout(() => {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Actualizado",
                    text: "Habitación actualizada",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                setSaving(false);

                setModalVisible(false);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center justify-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="2xl:text-4xl text-3xl text-[#1C4CBB] text-center font-medium">
                    Editar Nombre
                </h2>
                <hr className="w-full bg-gray-300" />

                <div className="flex flex-col justify-center w-11/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Nombre
                    </label>
                    <input
                        type={"text"}
                        min={0}
                        name="nombre"
                        value={input.nombre}
                        onChange={handleInputChange}
                        placeholder="Ingresa el nombre de la casa"
                        className="w-full max-w-md 2xl:p-3 p-2 2xl:text-base text-sm mx-auto mt-2 text-black border border-[#1C4CBB] rounded-lg placeholder:italic"
                    />
                </div>

                <div className="flex flex-col justify-center w-11/12 mt-4">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Descripción
                    </label>
                    <textarea
                        name="descripcion"
                        value={input.descripcion}
                        rows={8}
                        onChange={(e) => {
                            if (e.target.value.length <= 255) {
                                handleInputChange(e);
                            }
                        }}
                        placeholder="Ingresa una descripción"
                        className="w-full max-w-md 2xl:p-3 p-2 2xl:text-base text-sm mx-auto mt-2 text-black border border-[#1C4CBB] rounded-lg placeholder:italic"
                    />
                    <div className="mt-1 text-sm text-right text-gray-500">
                        {input.descripcion.length} / 255
                    </div>
                </div>

                <div className="w-10/12 2xl:mt-8 ">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="w-full p-3 px-5 text-white rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

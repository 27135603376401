import React, { useState, useEffect } from "react";
import imagen2 from "../../img/landing_comprimidas/imagen-landing-2.webp";
import { useNavigate, useParams } from "react-router-dom";
import useCurrency from "../../hooks/useCurrency";
import { useAppSelector } from "../../hooks/hooks";

const Planes = () => {
    const navigate = useNavigate();
    const { currency } = useAppSelector((state) => state.informacion);
    const [moneda, setMoneda] = useState("");
    const { view, tags } = useParams();
    const [Plan, setPlan] = useState(false);

    const handlePlan = () => {
        setPlan(!Plan);
    };
    useEffect(() => {
        console.log("currency", currency);
        setMoneda(currency === "USD" ? "$" : "€");
    }, [currency]);

    return (
        <div className="bg-white">
            <div className="flex flex-col justify-center ">
                <h2 className="bg-white py-5 text-[#179BD8]  text-center font-bold text-[25px] md:text-[30px] lg:text-[40px] ">
                    Conoce nuestros planes para propietarios
                </h2>
                <div className="text-center font-bold text-2xl">
                    ¡Tenemos muchos beneficios para ti y tu propiedad!
                </div>
            </div>

            <div className="bg-[#179BD8] p-5 w-1/2 mx-auto rounded-3xl mt-5 ">
                <div className="flex flex-col md:flex-row justify-center items-center p-3 gap-2 font-semibold w-full">
                    <div className="flex flex-col justify-start items-center text-white text-3xl sm:text-lg gap-2">
                        <div>- 4 plantillas para tu pagina Web</div>
                        <div>- Podrás editar tu web de una manera sencilla</div>
                        <div>- Dashboard con varias configuraciones</div>
                        <div>- CRM</div>
                        <div>- Calendario de disponibilidad</div>
                    </div>
                    {/*   <div className="flex flex-col justify-start items-start text-white text-lg sm:text-sm gap-2">
                        <div>- CRM de clientes</div>
                        <div>- Plataforma de pago</div>
                        <div>- Creación de cupones</div>
                        <div>- Chat y gestión de cientes</div>
                        <div>- Resumen de estadísticas</div>
                    </div>
                    <div className="flex flex-col justify-start items-start text-white text-lg sm:text-sm gap-2">
                        <div>- Chatbot interno</div>
                        <div>- Integración google calendar</div>
                        <div>- Plantillas para contratos por alquiler</div>
                        <div>- +2 Subusuarios</div>
                        <div>- Integración con Airbnb y Booking</div>
                    </div> */}
                </div>
            </div>

            <div className="flex items-center justify-center mt-5 ">
                <button
                    onClick={() =>
                        navigate(`/home/${tags ? tags : "6"}/planes`)
                    }
                    className=" mb-4  bg-[#C0D345] rounded-3xl py-3 md:py-3 px-6 md:px-8 text-[#0E1928] gap-2 text-lg flex items-center"
                >
                    <p>Conocer más</p>
                    <div className="px-1 ">
                        <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_1_38)">
                                <path
                                    d="M5.81305 4.99428L0.170438 0.765716C0.0751903 0.694486 0.0139897 0.590648 0.000291429 0.477054C-0.0134069 0.363461 0.0215221 0.249419 0.097397 0.160003C0.173272 0.0705869 0.283873 0.0131247 0.404875 0.000265013C0.525877 -0.0125946 0.647366 0.0202045 0.742613 0.0914341L6.82957 4.66286C6.88203 4.70416 6.92421 4.75574 6.95316 4.81399C6.9821 4.87223 6.9971 4.93573 6.9971 5C6.9971 5.06428 6.9821 5.12777 6.95316 5.18602C6.92421 5.24427 6.88203 5.29585 6.82957 5.33714L0.742613 9.90857C0.647366 9.9798 0.525877 10.0126 0.404875 9.99974C0.283873 9.98688 0.173272 9.92942 0.097397 9.84C0.0215221 9.75059 -0.0134069 9.63655 0.000291429 9.52295C0.0139897 9.40936 0.0751903 9.30552 0.170438 9.23429L5.81305 4.99428Z"
                                    fill="#0E1928"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_38">
                                    <rect width="7" height="10" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </button>
            </div>
        </div>
    );
};
export default Planes;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { logIn } from "../redux/reducers/authReducer";
import Swal from "sweetalert2";
import { ModalRecuperarContrasena } from "./dashboardPropietarios/contrasena/ModalRecuperarContrasena";

interface LoginForm {
    correo?: string;
    password?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE = {
    correo: "",
    password: "",
};

interface Props {
    loginAdmin?: boolean;
}

export const ClientLogin = ({ loginAdmin = false }: Props) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [input, setInput] = useState<LoginForm>(INITIAL_STATE);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [errors, setErrors] = useState<LoginForm>({});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { house } = useAppSelector((state) => state.landing_house);
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: LoginForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        let errores: errorObject = {};

        if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        }

        return errores;
    };

    useEffect(() => {
        switch (tipo_usuario) {
            case 1:
                navigate("/dashboard-admin/casas");
                break;
            case 2:
                navigate("/dashboard-admin/casas");
                break;
            case 3:
                navigate("/dashboard");
                break;
            case 4:
                navigate("/dashboard");
                break;
            case 5:
                navigate("/");
                break;
            case 6:
                navigate("/dashboard-corredor/casas");
                break;
            case 7:
                navigate("/");
                break;
            case 8:
                navigate("/dashboard-hotel/habitaciones");
                break;
            case 9:
                navigate("/");
                break;
            default:
                break;
        }
    }, [tipo_usuario]);

    const handleLogin = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        console.log({ loginAdmin });
        if (loginAdmin) {
            if (Object.entries(errors).length === 0) {
                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_PROXY}/administrador/signIn/`,
                        input
                    );

                    localStorage.setItem("token", data.token);
                    dispatch(logIn(data.currentUser));
                } catch (error: any) {
                    console.log(error.response);
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        text: error.response.data.error,
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        } else {
            if (Object.entries(errors).length === 0) {
                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_PROXY}/usuarios/signIn`,
                        input
                    );
                    console.log(data);
                    localStorage.setItem("token", data.token);
                    dispatch(logIn({ ...data.currentUser }));
                } catch (error: any) {
                    console.log(error.response);
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        text: error.response.data.error,
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
            }
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center h-screen p-4">
                {
                    <ModalRecuperarContrasena
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        loginAdmin={loginAdmin}
                    />
                }
                <div
                    className={
                        "flex items-center text-lg cursor-pointer pt-4 px-4 absolute top-3 left-3 "
                    }
                    onClick={() => {
                        navigate("/");
                    }}>
                    <svg
                        className="w-6 h-6 -ml-2 text-black"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        {" "}
                        <polyline points="15 18 9 12 15 6" />
                    </svg>
                    <span className="mx-1">Regresar</span>
                </div>
                <div className="flex flex-col gap-2 text-center">
                    <h1 className="text-xl font-bold lg:text-3xl text-[#1C4CBB]">
                        Iniciar sesión
                    </h1>
                    <div
                        className={
                            loginAdmin ? "hidden" : "font-medium text-[#1C4CBB]"
                        }>
                        Aun no tienes una cuenta?{" "}
                        <span
                            onClick={() => navigate("/registro")}
                            className="text-blue-600 cursor-pointer hover:underline">
                            Regístrate aquí.
                        </span>
                    </div>
                    <span className="text-sm text-gray-500">
                        Utiliza tu correo y contraseña para acceder
                    </span>
                </div>
                <div className="mt-8 w-full md:w-1/2 md:max-w-[468px]">
                    <label className="text-sm font-medium text-gray-600">
                        Correo electrónico
                    </label>
                    <input
                        value={input.correo}
                        onChange={handleInputChange}
                        name="correo"
                        type={"email"}
                        className="w-full p-3 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                        placeholder="ejemplo@gmail.com"
                    />
                    {errors.correo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.correo}
                        </p>
                    )}
                    <label className="flex mt-4 text-sm font-medium text-gray-600">
                        Contraseña
                    </label>
                    <div className="relative flex items-center">
                        <input
                            value={input.password}
                            onChange={handleInputChange}
                            name="password"
                            type={showPass ? "text" : "password"}
                            maxLength={20}
                            className="w-full p-3 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                            placeholder="contraseña"
                        />
                        <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                            {showPass ? (
                                <>
                                    <svg
                                        className={`w-6 h-6 mx-2 cursor-pointer`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => {
                                            setShowPass(!showPass);
                                        }}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                        />
                                    </svg>
                                </>
                            ) : (
                                <>
                                    <svg
                                        className={`w-6 h-6 mx-2 cursor-pointer`}
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => {
                                            setShowPass(!showPass);
                                        }}>
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                        />
                                    </svg>
                                </>
                            )}
                        </span>
                    </div>
                    <p
                        onClick={() => setModalVisible(true)}
                        className="mt-3 text-center text-blue-500 cursor-pointer">
                        ¿Olvidaste la contraseña?
                    </p>
                    {errors.password && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.password}
                        </p>
                    )}
                </div>
                <button
                    onClick={() => handleLogin()}
                    className=" p-3 px-5 mx-auto text-white border  rounded-lg bg-[#029BFC] w-full md:w-1/2 md:max-w-[468px] mt-6 hover:scale-105">
                    Acceder
                </button>
            </div>
        </>
    );
};

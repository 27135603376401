import React, { useState } from "react";

import { OwnerRegisterPlan } from "./OwnerRegisterPlan";

interface Input {
    grupo_trabajo: string;
    token: string;
    idUsuario: number | null;
}

export const RegistroConPlanCorredor = () => {
    const [inputPlan, setInputPlan] = useState<Input>({
        grupo_trabajo: "",
        idUsuario: null,
        token: "",
    });

    return (
        <div>
            <OwnerRegisterPlan setInputPlan={setInputPlan} />
            {/* {inputPlan.idCasa === null ? (
                
            ) : (
                <CheckoutSuscripcion input={inputPlan} />
            )} */}
        </div>
    );
};

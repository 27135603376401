import { useEffect, useState } from "react";
import ToggleButton from "../../components/helpers/ToggleButton";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import Actividades from "./Actividades";
import Carrousel from "./Carrousel";
import Habitaciones from "./Habitaciones";
import Mapa from "./Mapa";
import Navbar from "./Navbar/Navbar";

import Servicios from "./Servicios";
import { NombreCasa } from "../../pages/onboardingPropietario/NombreCasa";
import { PrecioBase } from "./PrecioBase";
import { useAppSelector } from "../../hooks/hooks";
import { ModalChat } from "../modalEdit/ModalChat";
import Imagenes from "./Imagenes";
import Reviews from "./Reviews";
import Eventos from "./Eventos";
import { FooterLanding } from "../modalEdit/FooterLanding";
import HabitacionesHotel from "./HabitacionesHotel";

export interface Capacidad {
    personas: number;
    mascotas: number;
    banos: string;
}

const Design1 = (tipo_landing: { tipo_landing: number }) => {
    const [toggleButton, setToggleButton] = useState(false);
    const casa = useAppSelector((state) => state.landing_house.house);
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    return (
        <div className="bg-[#f4f4f4] 2xl:px-[8%] overflow-clip">
            <Navbar />
            <div id="fotos">
                <Carrousel toggleButton={toggleButton} />
            </div>
            <div className="">
                <div className="flex flex-col gap-8 p-4 bg-white border shadow-lg lg:py-10 lg:px-16">
                    {(casa.id_propietario === id || tipo_usuario === 4) && (
                        <div className="flex justify-end w-10/12 mt-3">
                            <p>
                                <span className="text-[#053465] font-medium mr-2">
                                    Activar edicion rapida
                                </span>
                            </p>
                            <ToggleButton
                                isToggled={false}
                                onChangeToggle={(e) => setToggleButton(e)}
                            />
                        </div>
                    )}
                    {/* <hr /> */}

                    <PrecioBase
                        toggleButton={toggleButton}
                        tipo_landing={tipo_landing.tipo_landing}
                    />

                    <div id="imagenes">
                        <Imagenes toggleButton={toggleButton} />
                    </div>
                    {tipo_landing.tipo_landing !== 8 && (
                        <div id="habitaciones">
                            <Habitaciones toggleButton={toggleButton} />
                        </div>
                    )}

                    {tipo_landing.tipo_landing === 8 && (
                        <div id="habitaciones">
                            <HabitacionesHotel toggleButton={toggleButton} />
                        </div>
                    )}

                    <div className="md:-mx-4 lg:-mx-16" id="servicios">
                        <Servicios toggleButton={toggleButton} />
                    </div>
                    {casa.plan > 1 &&
                        (casa.casa_actividades.length || toggleButton) && (
                            <div id="actividades">
                                <Eventos toggleButton={toggleButton} />
                            </div>
                        )}
                    <div id="resenas">
                        <Reviews color={"[#baad85]"} />
                    </div>
                    <div id="ubicacion">
                        <Mapa toggleButton={toggleButton} />
                    </div>
                    <div>
                        <FooterLanding toggleButton={toggleButton} />
                    </div>
                </div>
            </div>
            <ModalChat bg={"bg-[#baad85]"} />
        </div>
    );
};

export default Design1;

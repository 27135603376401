import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import bienvenida from "../../img/onboarding/bienvenida.jpg";
import { verifyToken } from "../../redux/reducers/authReducer";
import { useAppDispatch } from "../../hooks/hooks";
import { getHouse } from "../../redux/reducers/onboardingReducer";

import logo from "../../img/BluStay logo mini.png";

export const Bienvenida = (corredor: { corredor: string }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { token } = useParams();
    console.log(token);
    useEffect(() => {
        if (token) {
            localStorage.setItem("token", token);
            dispatch(verifyToken()).then((data) => {
                dispatch(getHouse(data.payload.id));
            });
        }
    }, [token]);

    const handleSubmit = () => {
        navigate(
            corredor.corredor === "corredor"
                ? "/dashboard-corredor/onboarding/datos"
                : corredor.corredor === "hotel"
                ? "/dashboard-hotel/onboarding/alojamiento"
                : "/dashboard/onboarding/alojamiento"
        );
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 xl:grid">
            <div className="block h-56 xl:h-auto bg-black/80">
                <div className="flex flex-col h-full text-center xl:h-screen">
                    <img
                        src={bienvenida}
                        alt="bienvenida"
                        className="object-cover w-full h-full"
                    />
                </div>
            </div>
            <div className="relative flex flex-col items-center justify-center h-screen my-auto text-center text-white bg-white">
                <div>
                    <div className="text-4xl font-medium text-[#029BFC]">
                        ¡Bienvenido a BluStay!
                    </div>
                    <div className="mt-3 text-5xl font-bold text-[#029BFC]">
                        <img
                            src={logo}
                            alt="logo"
                            className="object-cover mx-auto w-36 h-36 xl:w-44 xl:h-44"
                        />
                    </div>
                </div>

                <h2 className="mt-20 text-xl text-gray-500 2xl:mx-10 lg:mx-5">
                    Te ayudaremos durante el proceso de onboarding, solo debes
                    seguir los siguientes pasos y tendrás lista tu web en solo 5
                    minutos.
                </h2>
                <button
                    onClick={() => handleSubmit()}
                    className="mt-20 border py-3 px-20 font-bold flex w-max mx-auto rounded-lg bg-[#029BFC] active:translate-y-1">
                    Comenzar
                </button>
            </div>
        </div>
    );
};

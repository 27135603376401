import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";

import Swal from "sweetalert2";
import { useAppSelector } from "../../../hooks/hooks";

import { useNavigate, useParams } from "react-router-dom";
import landing_house from "../../../redux/reducers/landingReducer";
import { ModalEditName } from "../../../designs/modalEdit/ModalEditName";
import { ModalEditPrecioBase } from "../../../designs/modalEdit/ModalEditPrecioBase";
import { ModalEditCapacidades } from "../../../designs/modalEdit/ModalEditCapacidades";
import { ModalEditImageCarrousel } from "../../../designs/modalEdit/ModalEditImageCarrousel";
import { ModalEditDescripcion1 } from "../../../designs/modalEdit/ModalEditDescripcion1";
import { ModalEditDescripcion2 } from "../../../designs/modalEdit/ModalEditDescripcion2";
import { ModalEditRooms } from "../../../designs/modalEdit/ModalEditRooms";
import { ModalEditComforts } from "../../../designs/modalEdit/ModalEditComforts";
import { ModalEditEvents } from "../../../designs/modalEdit/ModalEditEvents";
import { ModalEditLocation } from "../../../designs/modalEdit/ModalEditLocation";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../NavbarHotel";
import { NavbarMobileHotel } from "../NavbarMobileHotel";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import Spinner from "../../../components/helpers/Spinner";
import { ModalEditNameHabitacion } from "../../../designs/modalEdit/ModalEditNameHabitacion";
import { ModalEditPrecioBaseHabitacion } from "../../../designs/modalEdit/ModalEditPrecioBaseHabitacion";
import { ModalEditCapacidadesHotel } from "../../../designs/modalEdit/ModalEditCapacidadesHotel";
import { ModalEditImageHabitacion } from "../../../designs/modalEdit/ModalEditImageHabitacion";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export interface Casa {
    id: number;
    id_hotel: number;
    nombre: string;
    banner: string;
    precio_base: string;
    descripcion: string;
    personas: string;
    banos: string;
    camas: string;
    mascotas: string;
    check_in: string;
    check_out: string;
    orden: number;
    publicado: string;
    terminado: string;
    pagina: string;
    landing: number;
}

export const ConfiguracionesHabitacionHotel = () => {
    const navigate = useNavigate();
    const { plan, tema } = useAppSelector((state) => state.landing_house.house);
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const [casas, setCasas] = useState<Casa | null>(null);

    const { idCasa } = useParams();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalName, setMondalName] = useState("");

    const ocultarlanding = (titulo: string) => {
        const nombres = ["descripcion 2"];

        if (titulo === "descripcion 2" && tema !== "2") {
            return "hidden";
        } else if (titulo === "novedades" && plan < 2) {
            return "hidden";
        }
    };
    const opcionesPremiumLanding = (titulo: string) => {
        const nombres = ["descripcion 2", "novedades"];

        if (titulo === "descripcion 2" && tema !== "2") {
            return true;
        } else if (titulo === "novedades" && plan < 2) {
            return true;
        } else {
            return false;
        }
    };

    const opcionesPremium = (titulo: string) => {
        const nombres = [
            "descuento-estadia",
            "Disponibilidad máxima",
            "Conexiones externas",
        ];

        if (nombres.includes(titulo) && plan < 2 && tipo_usuario !== 6) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        !modalVisible && getCasa();
    }, [idCasa, modalVisible]);

    const getCasa = async () => {
        if (idCasa) {
            const data = await fetchAxiosToken({
                url: `/hotel_habitaciones/detalle/${idCasa}`,
            });
            console.log(data, "data");
            setCasas(data);
        }
    };

    const OPCIONES = [
        {
            nombre: "Garantía",
            url: "garantia",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.74 100"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m2.04,100C.57,99.28,0,98.12,0,96.46c.04-11.21.02-22.42.02-33.64,0-2.18.53-2.92,2.6-3.68,1.44-.52,2.86-1.06,4.31-1.56,1.96-.67,3.51.06,4.21,1.98,4.13,11.32,8.26,22.64,12.37,33.97.89,2.46.2,3.87-2.27,4.76-1.53.55-3.04,1.14-4.56,1.71H2.04Z"
                            />
                            <path
                                fill="#333333"
                                d="m92.12,46.46c.78-.86,1.46-1.67,2.21-2.42,1.39-1.4,3.27-1.48,4.51-.23,1.23,1.24,1.15,3.11-.25,4.51-2.43,2.45-4.86,4.89-7.31,7.31-1.55,1.54-3.23,1.52-4.79-.03-2.42-2.41-4.84-4.82-7.24-7.24-1.44-1.45-1.54-3.33-.26-4.58,1.25-1.22,3.1-1.11,4.51.28.74.73,1.45,1.48,2.18,2.23.36-.3.22-.61.23-.88,0-7.54.03-15.09,0-22.63-.03-8.29-5.55-14.93-13.68-16.49-1.08-.21-2.18-.32-3.28-.29-.08.35.19.43.34.57,10.84,9.4,12.06,26.18,2.64,37-5.89,6.76-13.39,9.8-22.3,9.21-11.74-.78-21.91-10.13-23.83-21.76C23.33,16.27,32.98,2.72,47.68.28,48.76.1,49.87,0,50.98,0c6.31.01,12.62-.02,18.93.01,10.84.05,20.48,8.66,21.81,19.42.14,1.1.18,2.2.18,3.31,0,7.48,0,14.96,0,22.44,0,.34-.1.7.23,1.29Zm-39.67-6.47c.92-.11,2.08-.2,3.21-.57,4.29-1.41,6.81-5.69,5.62-9.69-.81-2.74-2.82-4.37-5.35-5.45-2-.85-4.04-1.62-6.05-2.47-1.24-.52-1.38-1.77-.32-2.61.55-.44,1.22-.59,1.91-.65,1.82-.14,3.36.54,4.83,1.53,1.49,1.01,3.12.75,4.05-.59.95-1.36.59-3.01-.9-4.07-1.17-.84-2.44-1.51-3.81-1.94-3.08-.96-6.12-.93-8.92.84-2.56,1.61-3.77,4.02-3.44,7.05.3,2.76,1.84,4.65,4.44,5.66,1.79.7,3.56,1.44,5.33,2.15.73.29,1.44.6,2.05,1.12,1.02.86,1.08,1.92.16,2.87-.69.7-1.55,1.06-2.53,1.11-2.19.12-4.1-.53-5.68-2.08-.82-.81-1.75-1.25-2.91-.95-2.15.56-2.83,3.13-1.22,4.81,2.52,2.63,5.68,3.8,9.51,3.93Z"
                            />
                            <path
                                fill="#333333"
                                d="m31.6,54.09c4.77.09,9.37,1.1,13.33,3.78,3.98,2.7,8.35,3.96,13,4.61,1.9.27,3.79.59,5.66,1.01,4.07.91,6.6,4.75,5.79,8.71-.82,4.01-4.56,6.53-8.68,5.84-2.5-.42-4.99-.88-7.49-1.31-3.97-.69-7.93-1.4-11.9-2.07-1.75-.3-3.18.63-3.47,2.18-.29,1.59.74,3.02,2.52,3.34,3.77.69,7.55,1.34,11.32,2,2.78.49,5.57.97,8.35,1.46,7.08,1.26,14.1-3.92,14.95-11.09.14-1.17.59-1.81,1.68-2.29,4.19-1.86,8.31-3.87,12.49-5.75,2.65-1.2,5.24-.89,7.56.84,4.41,3.29,3.94,9.95-.9,12.58-3.79,2.07-7.65,4.03-11.48,6.04-6.85,3.59-13.73,7.14-20.55,10.79-3.48,1.87-7.09,2.28-10.95,1.71-5.91-.89-11.83-1.65-17.75-2.47-1.58-.22-3.15-.44-4.73-.63-.69-.08-1.05-.42-1.3-1.09-4.01-11.06-8.04-22.11-12.06-33.17-.43-1.19-.43-1.21.79-1.64,3.34-1.17,6.58-2.65,10.14-3.09,1.22-.15,2.45-.32,3.69-.29Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },

        {
            nombre: "Check-in y check-out",
            url: "check",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 81.92 100"
                >
                    <g id="Testimonios">
                        <path
                            fill="#333333"
                            d="m40.98.01c10.82-.23,20.27,3.28,28.22,10.63,6.64,6.14,10.79,13.72,12.16,22.66,2.03,13.22-1.5,24.92-10.02,35.2-7.52,9.08-14.94,18.25-22.4,27.38-3.69,4.52-9.45,5.44-13.79,2.18-1.53-1.15-2.61-2.73-3.81-4.2-7.46-9.13-14.99-18.21-22.34-27.42C4,60.16,1.12,52.92.27,44.92c-1.18-11.11,1.43-21.21,8.47-29.97C15.59,6.41,24.54,1.51,35.43.2c.54-.06,1.07-.17,1.61-.18,1.31-.03,2.63,0,3.94,0Zm-2.28,53.11c.89.01,1.77-.49,2.54-1.26,5.6-5.6,11.2-11.2,16.8-16.8.22-.22.44-.44.62-.69.89-1.26.99-2.6.24-3.92-.76-1.34-1.98-1.9-3.51-1.76-1.02.09-1.78.68-2.47,1.38-4.5,4.51-9.02,9.01-13.51,13.54-.66.67-1.08.73-1.76.02-2.06-2.14-4.17-4.24-6.31-6.31-1.67-1.61-3.81-1.64-5.27-.16-1.43,1.45-1.42,3.57.15,5.18,3.16,3.23,6.38,6.41,9.58,9.6.74.73,1.62,1.18,2.91,1.19Z"
                        />
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Tarifas adicionales",
            url: "tarifas-adicionales",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 99.96"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m76.81,0c6.08,0,12.17,0,18.25,0,1.17,0,2.3.14,3.28.87,1.35,1.01,1.63,2.46,1.63,4.01,0,3.85,0,7.69,0,11.54,0,8.22-.02,16.44.02,24.65,0,1.97-.59,3.53-2.02,4.95-16.55,16.49-33.06,33.01-49.57,49.54-2.89,2.89-6.29,4.49-10.41,4.38-3.71-.1-6.82-1.61-9.43-4.22-8.16-8.16-16.36-16.28-24.47-24.49-5.55-5.63-5.44-13.85.12-19.45,1.45-1.46,2.94-2.9,4.37-4.39.58-.6.92-.59,1.47.08,4.84,5.95,11.12,9.26,18.73,9.93,11.81,1.04,23.1-6.25,27.14-17.39,3.93-10.84.67-22.71-8.29-30-.76-.62-.74-.97-.07-1.61,2.26-2.19,4.5-4.4,6.67-6.68C55.42.51,56.79,0,58.45,0c6.12.02,12.24,0,18.36,0Zm3.96,11.75c-4.24,0-7.55,3.25-7.58,7.44-.03,4.16,3.34,7.59,7.46,7.61,4.25.02,7.57-3.29,7.59-7.55.01-4.26-3.21-7.5-7.47-7.5Z"
                            />
                            <path
                                fill="#333333"
                                d="m50.11,30.83c0,10.68-8.57,19.2-19.33,19.2-10.6,0-19.23-8.63-19.22-19.22,0-10.7,8.6-19.32,19.24-19.32,10.75,0,19.3,8.57,19.3,19.35Zm-7.89-.05c0-2-1.43-3.56-3.42-3.66-.98-.05-1.96-.07-2.93-.01-.98.05-1.42-.29-1.36-1.32.05-.98.03-1.96-.01-2.93-.09-1.96-1.68-3.46-3.63-3.47-1.98-.01-3.59,1.53-3.67,3.54-.04,1.01-.04,2.03-.01,3.04.02.79-.29,1.16-1.11,1.13-1.05-.03-2.1-.03-3.15.02-1.95.09-3.46,1.7-3.47,3.64,0,1.94,1.49,3.56,3.44,3.66,1.05.05,2.1.05,3.15.03.79-.02,1.16.29,1.13,1.11-.03,1.05-.03,2.1.02,3.15.09,1.99,1.65,3.44,3.64,3.45,1.99,0,3.56-1.44,3.66-3.43.05-1.05.05-2.1.02-3.15-.02-.79.29-1.16,1.11-1.13,1.05.03,2.1.03,3.15-.02,2-.1,3.45-1.65,3.46-3.63Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Personas adicionales",
            url: "personas-adicionales",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 94.81 100"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m35.89,98.76c-6.15,0-12.31.02-18.46,0-2.73-.01-5.4-.48-7.94-1.5-4.81-1.94-7.8-5.55-8.77-10.56-1.91-9.78-.06-18.79,6.57-26.44,4.12-4.76,9.64-7.1,15.79-8.02,3.5-.53,7.02-.44,10.54-.45,5.75,0,11.5-.07,17.24,0,7.73.08,14.35,2.88,19.79,8.39,1.7,1.72,1.42,2.9-.84,3.74-9.64,3.6-15.78,13.05-15.07,23.29.2,2.89.92,5.66,2.17,8.29,1.08,2.28.43,3.28-2.08,3.28-6.31,0-12.63,0-18.94,0h0Z"
                            />
                            <path
                                fill="#333333"
                                d="m38.97,41.69c-11.43,0-20.84-9.4-20.82-20.77C18.18,9.43,27.58,0,39.01,0c11.5,0,21,9.34,20.92,20.83-.09,12.83-10.59,21.11-20.95,20.86Z"
                            />
                            <path
                                fill="#333333"
                                d="m72.82,81.64c1.05-.16,2.55.53,3.36-.31.75-.78.2-2.24.25-3.4.04-.93-.02-1.86.03-2.79.11-2.09,1.73-3.71,3.83-3.86,1.88-.14,3.69,1.28,4.1,3.26.13.63.11,1.28.17,1.93.15,1.67-.74,3.84.4,4.89,1.06.97,3.18.2,4.83.28.24.01.49,0,.73,0,2.53.04,4.28,1.66,4.3,3.97.02,2.34-1.65,4.01-4.17,4.12-1.29.05-2.59.01-3.89.02q-2.2,0-2.2,2.14c0,1.3.03,2.59-.01,3.89-.09,2.59-1.78,4.28-4.18,4.23-2.29-.05-3.88-1.82-3.91-4.37-.01-1.42,0-2.83,0-4.25q0-1.63-1.59-1.64c-1.54,0-3.08.04-4.61-.02-1.71-.07-3.05-.84-3.78-2.42-.67-1.45-.44-2.85.51-4.11.76-1,1.8-1.49,3.04-1.54.89-.04,1.78,0,2.82,0Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Descuento por estadía",
            url: "descuento-por-estadia",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96.08 100"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m46.28,0h3.51c2.42.63,4.26,2.15,5.96,3.89,1.37,1.39,2.79,2.73,4.19,4.09.9.87,1.9,1.22,3.19,1.01,2.69-.44,5.4-.78,8.11-1.13,4.25-.55,8.33,2.16,9.45,6.3.74,2.73,1.09,5.55,1.54,8.33.26,1.58.97,2.67,2.42,3.39,2.13,1.05,4.21,2.19,6.3,3.32,4.84,2.63,6.38,7.39,3.99,12.34-1.06,2.2-2.1,4.4-3.22,6.57-.65,1.27-.63,2.45.01,3.72,1.11,2.17,2.14,4.38,3.22,6.57.67,1.36,1.15,2.78,1.1,4.3-.1,3.6-1.83,6.23-4.97,7.93-2.17,1.18-4.35,2.36-6.55,3.47-1.31.66-2.04,1.62-2.26,3.06-.36,2.34-.85,4.67-1.2,7.01-.82,5.61-5.65,8.6-10.26,7.84-2.47-.41-4.97-.61-7.43-1.05-1.6-.29-2.74.25-3.84,1.33-2.07,2.02-4.03,4.16-6.31,5.96-1.42,1.12-3.06,1.67-4.88,1.74-2.71.11-4.93-.96-6.84-2.8-1.8-1.74-3.6-3.49-5.39-5.23-.75-.73-1.61-1.24-2.7-1.09-2,.27-3.98.6-5.98.84-1.45.18-2.88.57-4.36.39-4.44-.54-7.38-3.39-8.11-7.83-.39-2.37-.85-4.73-1.22-7.11-.22-1.44-.95-2.41-2.26-3.07-2.18-1.09-4.33-2.25-6.47-3.42-4.77-2.6-6.31-7.38-3.95-12.28,1.03-2.14,2.03-4.29,3.14-6.39.73-1.39.75-2.67.02-4.07-1.12-2.13-2.13-4.32-3.18-6.48C.41,40.1-.05,38.67,0,37.15c.12-3.6,1.87-6.21,5.02-7.9,2.18-1.17,4.35-2.35,6.55-3.47,1.27-.64,1.98-1.6,2.2-3,.37-2.37.85-4.73,1.21-7.11.86-5.64,5.75-8.52,10.22-7.78,2.56.42,5.16.66,7.72,1.09,1.29.21,2.3-.13,3.19-1.01,1.4-1.36,2.83-2.69,4.19-4.09,1.71-1.74,3.54-3.26,5.96-3.89Zm23.54,30.86c-.12-1.08-.62-1.94-1.67-2.42-1.22-.56-2.26-.25-3.55,1.04-12.34,12.34-24.69,24.68-37.03,37.03-.27.28-.56.55-.78.87-1.21,1.74-.15,4.11,1.96,4.34,1.19.13,2-.56,2.79-1.34,12.29-12.3,24.59-24.6,36.88-36.89.25-.25.51-.51.73-.78.42-.52.63-1.13.66-1.83Zm-33.29-6.66c-5.76-.02-10.47,4.65-10.48,10.37-.01,5.68,4.68,10.42,10.33,10.45,5.79.03,10.56-4.66,10.6-10.4.04-5.68-4.71-10.41-10.45-10.42Zm23.05,51.52c5.76-.01,10.44-4.69,10.43-10.42,0-5.69-4.75-10.4-10.48-10.4-5.7,0-10.48,4.79-10.45,10.45.04,5.73,4.75,10.38,10.5,10.37Z"
                            />
                            <path
                                fill="#333333"
                                d="m36.45,39.49c-2.68-.02-4.88-2.27-4.85-4.95.03-2.66,2.33-4.9,4.97-4.84,2.69.05,4.9,2.31,4.86,4.96-.04,2.67-2.29,4.86-4.98,4.83Z"
                            />
                            <path
                                fill="#333333"
                                d="m59.62,60.44c2.69.03,4.89,2.27,4.85,4.95-.03,2.66-2.32,4.89-4.97,4.84-2.69-.05-4.9-2.31-4.86-4.96.04-2.68,2.29-4.86,4.98-4.83Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },

        {
            nombre: "Disponibilidad mínima",
            url: "minima-disponibilidad",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 99.94"
                >
                    <g id="Testimonios">
                        <path
                            fill="#333333"
                            d="m100,49.86c-.19,27.81-22.16,50.06-49.97,50.09C22.29,99.97.07,77.89,0,50.09-.07,22.31,22.05.05,49.92,0c27.76-.05,49.76,22.07,50.08,49.86Zm-54.87-6.52c0,4.4,0,8.8,0,13.2,0,.37.19.91-.19,1.08-.48.22-.71-.36-.99-.63-3.4-3.38-6.77-6.78-10.17-10.15-2.21-2.2-5.16-2.28-7.17-.24-1.96,2-1.85,4.91.29,7.07,1.64,1.66,3.3,3.31,4.95,4.96,4.85,4.85,9.69,9.7,14.54,14.54,2.11,2.1,4.94,2.2,6.98.17,6.69-6.64,13.35-13.31,19.99-19.99,1.45-1.45,1.77-3.53.98-5.3-1.45-3.21-5.39-3.92-7.98-1.38-3.17,3.11-6.29,6.27-9.43,9.41-.44.44-.88.88-1.33,1.32-.16.15-.32.39-.59.26-.25-.12-.19-.4-.2-.62-.01-.31,0-.62,0-.93,0-8.57,0-17.14,0-25.7,0-3.05-1.99-5.15-4.83-5.15-2.85,0-4.84,2.09-4.85,5.13-.01,4.32,0,8.65,0,12.97Z"
                        />
                    </g>
                </svg>
            ),
        },
    ];

    const OPCIONESLANDING = [
        {
            nombre: "Precio base",
            url: "precio",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Capacidades",
            url: "capacidades",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Nombre",
            url: "nombre",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Fotos",
            url: "foto",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100"
                >
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
    ];
    const render = () => {
        return (
            <div>
                <div className="w-full">
                    {modalName === "nombre" && modalVisible && casas && (
                        <ModalEditNameHabitacion
                            modalVisible
                            setModalVisible={setModalVisible}
                            descripcion={casas?.descripcion}
                            id_habitacion={casas?.id}
                            nombre={casas?.nombre}
                        />
                    )}
                    {modalName === "precio" && modalVisible && casas && (
                        <ModalEditPrecioBaseHabitacion
                            modalVisible
                            setModalVisible={setModalVisible}
                            check_in={casas?.check_in}
                            check_out={casas?.check_out}
                            id_habitacion={casas?.id}
                            precio_base={casas?.precio_base}
                        />
                    )}
                    {modalName === "capacidades" && modalVisible && casas && (
                        <ModalEditCapacidadesHotel
                            modalVisible
                            setModalVisible={setModalVisible}
                            banos={casas?.banos}
                            camas={casas?.camas}
                            mascotas={casas?.mascotas}
                            personas={casas?.personas}
                            id_habitacion={casas?.id}
                        />
                    )}
                    {modalName === "foto" && modalVisible && casas && (
                        <ModalEditImageHabitacion
                            modalVisible
                            setModalVisible={setModalVisible}
                            banner={casas?.banner}
                            id_habitacion={casas?.id}
                            imagenes_habitacion={[]}
                        />
                    )}

                    <div className="grid max-w-4xl grid-cols-2 gap-8 px-2 mx-auto mt-12 mb-10 text-sm xs:grid-cols-3 lg:grid-cols-4 2xl:text-base">
                        {OPCIONES.map((opcion, index) => {
                            return (
                                <div
                                    onClick={() =>
                                        !opcionesPremium(opcion.nombre) &&
                                        navigate(
                                            `/${
                                                idCasa && Number(idCasa) > 0
                                                    ? "dashboard-hotel"
                                                    : "dashboard"
                                            }/configuraciones-habitacion/${
                                                opcion.url
                                            }/${
                                                idCasa && Number(idCasa) > 0
                                                    ? `${idCasa}`
                                                    : ""
                                            }`
                                        )
                                    }
                                    className={`  ${
                                        opcionesPremium(opcion.nombre)
                                            ? "cursor-not-allowed bg-slate-50"
                                            : "bg-[#FAFAFA] hover:scale-105 cursor-pointer"
                                    } border  pb-12 pt-4 px-6 rounded-lg shadow-md relative `}
                                    key={index}
                                >
                                    <p>{opcion.nombre}</p>
                                    {opcionesPremium(opcion.nombre) && (
                                        <div className="absolute bottom-4 left-4">
                                            <span className="text-[10px] border border-orange-500 text-orange-500  p-[2px] rounded-md">
                                                Estándar
                                            </span>
                                        </div>
                                    )}
                                    <div className="absolute bottom-4 right-4">
                                        <p className="">{opcion.icono}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="max-w-4xl gap-8 px-2 pt-4 mx-auto mt-10 border-t-2 xs:grid-cols-3 lg:grid-cols-4">
                        <p className="text-[#029BFC] text-lg font-bold">
                            Editar mi landing
                        </p>
                    </div>

                    <div className="grid max-w-4xl grid-cols-2 gap-8 px-2 pt-4 mx-auto my-10 text-sm xs:grid-cols-3 lg:grid-cols-4 2xl:text-base">
                        {OPCIONESLANDING.map((opcion, index) => {
                            return (
                                <div
                                    onClick={() => (
                                        !opcionesPremiumLanding(opcion.url) &&
                                            setMondalName(opcion.url),
                                        setModalVisible(true)
                                    )}
                                    className={`cursor-pointer border  pb-12 pt-4 px-6 rounded-lg shadow-md relative `}
                                    key={index}
                                >
                                    <p>{opcion.nombre}</p>

                                    <div className="absolute bottom-4 right-4">
                                        <p className="">{opcion.icono}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <NavbarHotel titulo={`Configuraciones de ${casas?.nombre}`}>
                {idCasa && casas ? (
                    render()
                ) : (
                    <div className="flex-col items-center justify-center">
                        <div className="flex items-center justify-center mx-auto w-28 h-28">
                            <Spinner width={28} />
                        </div>
                        <div className="mt-5 text-xl text-center">
                            Por favor, aguarde mientras cargamos los datos.
                        </div>
                    </div>
                )}
                <NavbarMobileHotel />
            </NavbarHotel>
        </>
    );
};

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Lazy } from "swiper";
import "swiper/css/lazy";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Lightbox from "react-18-image-lightbox";
import { CapacidadesHotel } from "../capacidades/CapacidadesHotel";
import { formatearNumero } from "../../components/helpers/formatearNumero";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import { ModalEditNameHabitacion } from "../modalEdit/ModalEditNameHabitacion";
import { ModalEditPrecioBaseHabitacion } from "../modalEdit/ModalEditPrecioBaseHabitacion";
import { ModalEditImageHabitacion } from "../modalEdit/ModalEditImageHabitacion";
import { ModalSelectHabitaciones } from "../modalEdit/ModalSelectHabitaciones";

interface Props {
    toggleButton: boolean;
}

interface Habitacion {
    id: number;
    nombre: string;
    descripcion: string;
    banner: string;
    precio_base: string;
    personas: string;
    mascotas: string;
    banos: string;
    camas: string;
    landing: number;
    check_in: string;
    check_out: string;
    id_hotel: number;
    hotel_habitaciones_imagenes: HabitacionImagen[];
}

interface HabitacionImagen {
    id: number;
    id_habitacion_hotel: number;
    imagen: string;
    orden: number;
}

const HabitacionesHotel = ({ toggleButton }: Props) => {
    const {
        id_propietario,
        hotel_habitaciones,
        tipo_moneda,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const navigate = useNavigate();
    const totalHabitaciones = 4; // Máximo de habitaciones

    const habitacionesOrdenadas = Array.from({
        length: totalHabitaciones,
    }).map((_, index) => {
        const habitacion = hotel_habitaciones.find(
            (hab) => hab.landing === index + 1
        );
        return habitacion || null; // Si no hay habitación, devuelve null
    });

    const [modalNameEdit, setModalNameEdit] = useState<boolean>(false);
    const [modalPriceEdit, setModalpriceEdit] = useState<boolean>(false);
    const [landingSelected, setLandingSelected] = useState<number>(0);
    const [modalImagenesEdit, setModalImagenesEdit] = useState<boolean>(false);
    const [modalHabitacionSelectedEdit, setModalHabitacionSelectedEdit] =
        useState<boolean>(false);

    const [habitacionSelected, sethabitacionSelected] = useState<Habitacion>();

    const [photos, setPhotos] = useState([
        {
            src: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${hotel_habitaciones[0]?.banner}`,
            original: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${hotel_habitaciones[0]?.banner}`,
            width: 400,
            height: 200,
        },
    ]);

    const { price_to_currency } = usePriceToSelectedCurrency();

    const [index, setIndex] = useState(-1);

    const currentImage = photos?.[index];
    const nextIndex = (index + 1) % photos?.length;
    const nextImage = photos?.[nextIndex] || currentImage;
    const prevIndex = (index + photos?.length - 1) % photos?.length;
    const prevImage = photos?.[prevIndex] || currentImage;

    const handleClick = (index: any) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    /*    useEffect(() => {
        let imagenesTotal: any = photos.length ? [...photos] : [];
        hotel_habitaciones?.map((imagen) => {
            imagenesTotal.push({
                src: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,
                original: `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa_habitaciones/getImage/${imagen.imagen}`,

                height: 200,
            });
        });

        setPhotos(imagenesTotal);
    }, [hotel_habitaciones]); */

    const handleHabitacionName = (habitacion: any) => {
        setModalNameEdit(true);
        sethabitacionSelected(habitacion);
    };

    const handleHabitacionPrice = (habitacion: any) => {
        setModalpriceEdit(true);
        sethabitacionSelected(habitacion);
    };

    const handleHabitacionImagenes = (habitacion: any) => {
        setModalImagenesEdit(true);
        sethabitacionSelected(habitacion);
    };

    const handleHabitacionSelected = (habitacion: any, landing: number) => {
        setModalHabitacionSelectedEdit(true);
        sethabitacionSelected(habitacion);
        setLandingSelected(landing);
    };

    return (
        <>
            <div className="w-full flex flex-col gap-4 mt-5 2xl:w-5/6 2xl:mx-auto 2xl:mt-10">
                {modalNameEdit && (
                    <ModalEditNameHabitacion
                        modalVisible={modalNameEdit}
                        setModalVisible={setModalNameEdit}
                        nombre={habitacionSelected?.nombre ?? ""}
                        descripcion={habitacionSelected?.descripcion ?? ""}
                        id_habitacion={habitacionSelected?.id ?? 0}
                    />
                )}

                {modalPriceEdit && (
                    <ModalEditPrecioBaseHabitacion
                        modalVisible={modalPriceEdit}
                        setModalVisible={setModalpriceEdit}
                        precio_base={(
                            habitacionSelected?.precio_base ?? 0
                        ).toString()}
                        check_in={habitacionSelected?.check_in ?? ""}
                        check_out={habitacionSelected?.check_out ?? ""}
                        id_habitacion={habitacionSelected?.id ?? 0}
                    />
                )}

                {modalHabitacionSelectedEdit && (
                    <ModalSelectHabitaciones
                        modalVisible={modalHabitacionSelectedEdit}
                        setModalVisible={setModalHabitacionSelectedEdit}
                        habitacionSelected={habitacionSelected as Habitacion}
                        id_hotel={casa_id ?? 0}
                        landing={landingSelected}
                    />
                )}

                {modalImagenesEdit && (
                    <ModalEditImageHabitacion
                        modalVisible={modalImagenesEdit}
                        setModalVisible={setModalImagenesEdit}
                        banner={habitacionSelected?.banner ?? ""}
                        id_habitacion={habitacionSelected?.id ?? 0}
                        imagenes_habitacion={
                            habitacionSelected?.hotel_habitaciones_imagenes ||
                            []
                        }
                    />
                )}

                {habitacionesOrdenadas.map((habitacion, index) => (
                    <>
                        {habitacion ? (
                            <>
                                <div
                                    key={`habitacion-${index}`}
                                    className={`flex flex-col md:flex-row ${
                                        index % 2 === 0
                                            ? "md:flex-row"
                                            : "md:flex-row-reverse"
                                    } ${
                                        toggleButton ? "pt-24" : ""
                                    } items-start border p-4 rounded-lg shadow-lg relative`}
                                >
                                    {toggleButton && (
                                        <div
                                            onClick={() =>
                                                handleHabitacionSelected(
                                                    habitacion,
                                                    habitacion.landing
                                                )
                                            }
                                            title="editar habitación"
                                            className="absolute top-5 left-1/2 transform -translate-x-1/2 w-1/2 flex flex-col items-center px-2 py-1 my-2 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100 z-50"
                                        >
                                            <svg
                                                className="w-8 h-8 text-[#1C4CBB]"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                <line
                                                    x1="13.5"
                                                    y1="6.5"
                                                    x2="17.5"
                                                    y2="10.5"
                                                />
                                            </svg>
                                            <div className="text-xs text-[#1C4CBB] text-center">
                                                Editar habitación seleccionada
                                            </div>
                                        </div>
                                    )}
                                    <div className="w-full md:w-1/2 relative">
                                        <Swiper
                                            key={habitacion.banner}
                                            modules={[Navigation, Lazy]}
                                            slidesPerView={1}
                                            watchSlidesProgress
                                            lazy={true}
                                            navigation
                                            rewind={true}
                                            loop={true}
                                        >
                                            <SwiperSlide
                                                key={habitacion.banner}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    loading="lazy"
                                                    className="mx-auto h-80 rounded-md swiper-lazy"
                                                    alt="Imagen del producto"
                                                    data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${habitacion.banner}`}
                                                    data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${habitacion.banner}`}
                                                />
                                                <div className="swiper-lazy-preloader">
                                                    <div className="flex flex-col w-full h-80 gap-2 p-3 bg-gray-200">
                                                        <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                                        <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                        <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            {habitacion.hotel_habitaciones_imagenes.map(
                                                (imagen) => (
                                                    <SwiperSlide
                                                        key={imagen.id}
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            className="  mx-auto h-80 rounded-md swiper-lazy"
                                                            alt="Imagen del producto"
                                                            data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${imagen.imagen}`}
                                                            data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${imagen.imagen}`}
                                                        />
                                                        <div className="swiper-lazy-preloader">
                                                            <div className="flex flex-col w-full h-80 gap-2 p-3 bg-gray-200">
                                                                <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            )}
                                        </Swiper>

                                        {toggleButton && (
                                            <div
                                                onClick={() =>
                                                    handleHabitacionImagenes(
                                                        habitacion
                                                    )
                                                }
                                                title="editar imágenes"
                                                className=" absolute top-5 flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100 z-50"
                                            >
                                                <svg
                                                    className="w-8 h-8 text-[#1C4CBB]"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                    <line
                                                        x1="13.5"
                                                        y1="6.5"
                                                        x2="17.5"
                                                        y2="10.5"
                                                    />
                                                </svg>
                                                <div className="text-xs text-[#1C4CBB] text-center">
                                                    Editar Imágenes
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Descripción */}
                                    <div
                                        className={`  w-full md:w-1/2 flex flex-col justify-center items-start px-5`}
                                    >
                                        <div className="flex items-center justify-between w-full">
                                            <h2 className="text-2xl font-bold text-[#1C4CBB]">
                                                {habitacion.nombre}{" "}
                                            </h2>
                                            {toggleButton && (
                                                <div
                                                    onClick={() =>
                                                        handleHabitacionName(
                                                            habitacion
                                                        )
                                                    }
                                                    title="editar Precio base"
                                                    className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                                                >
                                                    <svg
                                                        className="w-8 h-8 text-[#1C4CBB]"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                        <line
                                                            x1="13.5"
                                                            y1="6.5"
                                                            x2="17.5"
                                                            y2="10.5"
                                                        />
                                                    </svg>
                                                    <div className="text-xs text-[#1C4CBB] text-center">
                                                        Editar Nombre y
                                                        descripción
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <p className="mt-2 text-sm text-gray-600">
                                            {habitacion.descripcion}
                                        </p>

                                        <div className="flex flex-col items-center justify-center w-full mt-5 ">
                                            <CapacidadesHotel
                                                personas={habitacion.personas}
                                                mascotas={habitacion.mascotas}
                                                banos={habitacion.banos}
                                                camas={habitacion.camas}
                                                id_habitacion={habitacion.id}
                                                toggleButton={toggleButton}
                                            />
                                        </div>

                                        <div className="flex flex-col items-center justify-center w-full ">
                                            <div className="flex">
                                                <div className="p-3 md:p-4 sm:px-8 text-2xl text-center text-[#1C4CBB] align-middle rounded-lg ">
                                                    <p className="font-light select-none">
                                                        Desde{" "}
                                                        <span className="font-semibold">{`${tipo_moneda} ${formatearNumero(
                                                            price_to_currency(
                                                                Number(
                                                                    habitacion.precio_base
                                                                )
                                                            )
                                                        )} `}</span>{" "}
                                                        /por noche
                                                    </p>
                                                </div>
                                                {toggleButton && (
                                                    <div
                                                        onClick={() =>
                                                            handleHabitacionPrice(
                                                                habitacion
                                                            )
                                                        }
                                                        title="editar Precio base"
                                                        className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                                                    >
                                                        <svg
                                                            className="w-8 h-8 text-[#1C4CBB]"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                            <line
                                                                x1="13.5"
                                                                y1="6.5"
                                                                x2="17.5"
                                                                y2="10.5"
                                                            />
                                                        </svg>
                                                        <div className="text-xs text-[#1C4CBB] text-center">
                                                            Editar Precio
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex flex-col items-center justify-center w-full cursor-pointer hover:scale-105">
                                            <div className="p-2 sm:px-8 text-xl text-center text-white bg-[#029BFC] align-middle rounded-lg w-1/2 ">
                                                <p className="font-bold select-none">
                                                    Reservar Ahora
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : toggleButton ? (
                            <div className="w-full flex flex-col justify-center items-center border-2 border-dashed border-gray-300 p-4 rounded-lg">
                                <p className="text-gray-500 text-center">
                                    Añadir habitación para esta posición
                                </p>
                                <button
                                    className="mt-2 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                                    onClick={() =>
                                        handleHabitacionSelected(
                                            habitacion,
                                            index + 1
                                        )
                                    }
                                >
                                    + Añadir
                                </button>
                            </div>
                        ) : null}
                        {/* Imagen */}
                    </>
                ))}

                <div
                    onClick={() => navigate("/landing-habitaciones")}
                    className="flex items-center justify-center w-1/2 mx-auto cursor-pointer hover:scale-105 rounded-2xl bg-[#029BFC] text-lg text-white p-2"
                >
                    Ver mas habitaciones
                </div>
            </div>
        </>
    );
};

export default HabitacionesHotel;

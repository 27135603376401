import { useAppSelector } from "../../../hooks/hooks";
import pdfIcon from "../../../assets/typeFilesImages/pdf.png";
import excelIcon from "../../../assets/typeFilesImages/excel.png";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { useNavigate } from "react-router-dom";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useRef, useState, useEffect } from "react";
import { ModalLogin } from "../../ModalLogin";
import { Chat, UsersChat } from "./ChatClienteHotel";
import { chatInvitado } from "./ChatBody";
export interface opcion {
    id_submenu_bots: number;
    opcion: string;
    orden: number;
}

interface result {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    mensaje: string;
    imagen_bot: string;
    opciones: opcion[];
    fecha_registro: string;
    leido: string;
    enviado: string;
    time_last_message: string;
    pedido: Pedido;
    response_bot: string;
}

export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    adultos: string;
    ninos: string;
    oferta: string;
    expiracion: string;
}

interface Props {
    mensajes: result[] | null;
    avatar: string;
    chat?: Chat | chatInvitado;
    handleResponseBot?: (
        mensaje: string,
        id_opcion: number,
        id: number
    ) => Promise<void>;
}

const messageIsImage = (extension: string) => {
    if (
        extension === "jpeg" ||
        extension === "jpg" ||
        extension === "png" ||
        extension === "tif"
    ) {
        return true;
    }

    return false;
};

const typeOfMessage = (extension: string) => {
    switch (extension) {
        case "pdf":
            return "pdf";
        case "doc":
            return "word";
        case "docx":
            return "word";
        case "docm":
            return "word";
        case "dot":
            return "word";
        case "xla":
            return "excel";
        case "xlam":
            return "excel";
        case "xll":
            return "excel";
        case "xlm":
            return "excel";
        case "xls":
            return "excel";
        case "xlsm":
            return "excel";
        case "xlsx":
            return "excel";
        case "xlt":
            return "excel";
        default:
            return "archivo";
    }
};

const deletePromo = (id: number) => {
    fetchAxiosToken({
        url: `/chat_mensajes/deleteMessagePedido/${id}`,
        method: "put",
    })
        .then((res) => {
            console.log(res);
        })
        .catch((err) => console.log(err));
};

export const Mensajes = ({
    mensajes,
    avatar,
    chat,
    handleResponseBot,
}: Props) => {
    const navigate = useNavigate();
    const [modalVisibleLogin, setModalVisibleLogin] = useState(false);
    const { id: id_usuario, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const pagarPromo = (id: number) => {
        navigate(`/checkout/${id}`);
    };
    function findChatObjectByType(
        array: UsersChat[],
        type: number
    ): UsersChat | undefined {
        let chat = array.find((item) => item.id_usuario === type);

        return chat;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [mensajes]);

    return (
        <div className="p-3 overflow-auto h-[75%]">
            {modalVisibleLogin && (
                <ModalLogin
                    modalVisibleLogin={modalVisibleLogin}
                    setModalVisibleLogin={setModalVisibleLogin}
                />
            )}
            {mensajes && mensajes?.length > 0 ? (
                <div className="flex flex-col gap-3.5">
                    {mensajes.map((mensaje, index) => {
                        if (
                            mensaje.id_usuario === id_usuario ||
                            (id_usuario === "" && mensaje.id_usuario === 0)
                        ) {
                            return (
                                <div
                                    ref={
                                        index === mensajes.length - 1
                                            ? scrollRef
                                            : null
                                    }
                                    key={mensaje.id}
                                    className="text-right">
                                    {messageIsImage(mensaje.tipo) ? (
                                        <div className="flex flex-col items-end justify-end">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block justify-end w-[25rem] p-3 text-white bg-[#029BFC] rounded-md   hover:cursor-zoom-in">
                                                <a
                                                    className="cursor-zoom-in"
                                                    href={`${process.env.REACT_APP_PROXY}/chat_mensajes/getImage/${mensaje.mensaje}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        className="w-[25rem] h-auto object-contain rounded-md hover:scale-105"
                                                        src={`${process.env.REACT_APP_PROXY}/chat_mensajes/getImage/${mensaje.mensaje}`}
                                                        alt={mensaje.mensaje}
                                                    />
                                                </a>
                                                <span className="mt-2 italic text-right text-[10px]">
                                                    {mensaje.time_last_message}
                                                </span>
                                            </div>
                                        </div>
                                    ) : mensaje.tipo === "texto" ? (
                                        <div className="flex flex-col items-end justify-end">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block justify-end  max-w-[75%] md:max-w-[50%] bg-[#029BFC] rounded-lg p-2 text-white ">
                                                <p className="flex flex-col text-sm 2xl:text-base">
                                                    <div className="text-sm text-left 2xl:text-base">
                                                        {mensaje.mensaje}
                                                        {""}
                                                    </div>

                                                    <span className="2xl:mt-2 mt-1 italic text-right text-[10px]">
                                                        {
                                                            mensaje.time_last_message
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    ) : mensaje.tipo === "Pedido" ? (
                                        <div className="flex flex-col items-end justify-end">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div
                                                className={`inline-block justify-end  max-w-[75%] md:max-w-[50%]  rounded-lg p-2 text-white  hover:opacity-80 ${
                                                    mensaje.mensaje ===
                                                    "No vigente"
                                                        ? " bg-[#029BFC] opacity-60 cursor-not-allowed"
                                                        : "bg-[#029BFC] hover:opacity-80 "
                                                }`}>
                                                <div className="flex items-center justify-between">
                                                    <p className="text-sm text-left 2xl:text-base">
                                                        Propuesta de reserva
                                                    </p>

                                                    <svg
                                                        onClick={
                                                            mensaje.mensaje ===
                                                            "No vigente"
                                                                ? () => {}
                                                                : () =>
                                                                      pagarPromo(
                                                                          mensaje
                                                                              .pedido
                                                                              .id
                                                                      )
                                                        }
                                                        className={`2xl:w-6 2xl:h-6 w-6 h-6 text-white  ${
                                                            mensaje.mensaje ===
                                                            "No vigente"
                                                                ? " cursor-not-allowed"
                                                                : "cursor-pointer hover:scale-105"
                                                        }`}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />{" "}
                                                        <polyline points="15 3 21 3 21 9" />{" "}
                                                        <line
                                                            x1="10"
                                                            y1="14"
                                                            x2="21"
                                                            y2="3"
                                                        />
                                                    </svg>
                                                </div>
                                                <hr className="2xl:my-2 my-1.5" />
                                                <div className="p-2 text-sm border rounded-md 2xl:text-base">
                                                    <p className="text-left">
                                                        Check-in:{" "}
                                                        <ShowDateToUser
                                                            date={
                                                                mensaje.pedido
                                                                    .fecha_inicio
                                                            }
                                                        />
                                                    </p>
                                                    <p className="text-left">
                                                        Check-out:{" "}
                                                        <ShowDateToUser
                                                            date={
                                                                mensaje.pedido
                                                                    .fecha_final
                                                            }
                                                        />
                                                    </p>
                                                    <p className="text-left">
                                                        Costo total:{" "}
                                                        {
                                                            mensaje.pedido
                                                                .costo_total
                                                        }{" "}
                                                        {
                                                            mensaje.pedido
                                                                .tipo_moneda
                                                        }
                                                    </p>
                                                    <div className="flex items-center justify-between">
                                                        <p
                                                            onClick={
                                                                mensaje.mensaje ===
                                                                "No vigente"
                                                                    ? () => {}
                                                                    : () =>
                                                                          deletePromo(
                                                                              mensaje.id
                                                                          )
                                                            }
                                                            className={`px-1 py-1 mt-1 text-xs bg-red-500 rounded-lg  ${
                                                                mensaje.mensaje ===
                                                                "No vigente"
                                                                    ? " cursor-not-allowed"
                                                                    : "cursor-pointer hover:scale-105"
                                                            }`}>
                                                            Eliminar
                                                        </p>
                                                        <p className="mt-1 text-xs ">
                                                            {" "}
                                                            {mensaje.mensaje}
                                                        </p>
                                                    </div>
                                                </div>
                                                <span className="mt-2 italic text-right text-[10px]">
                                                    {mensaje.time_last_message}
                                                </span>
                                            </div>
                                        </div>
                                    ) : mensaje.tipo === "chatbot" ? (
                                        <div className="flex flex-col items-end justify-end">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block justify-end  max-w-[75%] md:max-w-[50%] bg-[#029BFC] rounded-lg p-2 text-white ">
                                                {mensaje.imagen_bot !==
                                                    "sinImagen.png" && (
                                                    <div className="inline-block justify-end w-full p-3 text-white bg-[#029BFC] rounded-md   hover:cursor-zoom-in">
                                                        <a
                                                            className="cursor-zoom-in"
                                                            href={`${process.env.REACT_APP_PROXY}/respuestas_bot/getImage/${mensaje.imagen_bot}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            <img
                                                                className="w-full h-64 object-contain rounded-md hover:scale-105"
                                                                src={`${process.env.REACT_APP_PROXY}/respuestas_bot/getImage/${mensaje.imagen_bot}`}
                                                                alt={
                                                                    mensaje.imagen_bot
                                                                }
                                                            />
                                                        </a>
                                                    </div>
                                                )}{" "}
                                                <p className="flex flex-col text-sm 2xl:text-base px-3">
                                                    <div className="text-sm text-left 2xl:text-base">
                                                        {mensaje.mensaje}
                                                        {""}
                                                    </div>

                                                    <span className="2xl:mt-2 mt-1 italic text-right text-[10px]">
                                                        {
                                                            mensaje.time_last_message
                                                        }
                                                    </span>
                                                </p>
                                                <div className="w-full flex flex-col gap-2 justify-center items-center pointer-events-none">
                                                    {mensaje?.opciones.map(
                                                        (opcion) => (
                                                            <div
                                                                key={
                                                                    opcion.id_submenu_bots
                                                                }
                                                                className="w-full p-1 border-2 rounded-md shadow-sm text-sm  2xl:text-base">
                                                                {opcion.opcion}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-end justify-end">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block text-left  max-w-[75%] md:max-w-[50%] bg-[#029BFC] rounded-lg p-2 text-white break-all">
                                                <a
                                                    className="flex items-center justify-center gap-2 hover:underline"
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                    href={`${process.env.REACT_APP_PROXY}/chat_mensajes/getImage/${mensaje.mensaje}`}>
                                                    {typeOfMessage(
                                                        mensaje.tipo
                                                    ) === "word" ? (
                                                        <svg
                                                            className="w-10 h-10"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            width="48"
                                                            height="48"
                                                            viewBox="0 0 48 48">
                                                            <path
                                                                fill="#2196F3"
                                                                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"></path>
                                                            <path
                                                                fill="#FFF"
                                                                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"></path>
                                                            <path
                                                                fill="#0D47A1"
                                                                d="M27 42L6 38 6 10 27 6z"></path>
                                                            <path
                                                                fill="#FFF"
                                                                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"></path>
                                                        </svg>
                                                    ) : typeOfMessage(
                                                          mensaje.tipo
                                                      ) === "pdf" ? (
                                                        <img
                                                            className="w-10 h-10 p-0.5"
                                                            src={pdfIcon}
                                                            alt="Archivo pdf"
                                                        />
                                                    ) : typeOfMessage(
                                                          mensaje.tipo
                                                      ) === "excel" ? (
                                                        <img
                                                            className="w-10 h-10 p-1"
                                                            src={excelIcon}
                                                            alt="Archivo excel"
                                                        />
                                                    ) : null}
                                                    <span>
                                                        {mensaje.tipo
                                                            .split("_")
                                                            .slice(1)
                                                            .join("_")}
                                                    </span>

                                                    <span className="text-sm 2xl:text-base">
                                                        {mensaje.mensaje}
                                                    </span>
                                                </a>
                                                <span className="mt-2 italic text-right text-[10px]">
                                                    {mensaje.time_last_message}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    ref={
                                        index === mensajes.length - 1
                                            ? scrollRef
                                            : null
                                    }
                                    key={mensaje.id}
                                    className="w-full flex text-left gap-3.5">
                                    <img
                                        className="border rounded-full h-11 w-11"
                                        src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`}
                                        alt="avatar del usuario"
                                    />

                                    {messageIsImage(mensaje.tipo) ? (
                                        <div className="flex flex-col items-start justify-start w-full">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block w-[25rem] p-2 text-white bg-[#999999] rounded-md  hover:cursor-zoom-in">
                                                <a
                                                    className="cursor-zoom-in"
                                                    href={`${process.env.REACT_APP_PROXY}/chat_mensajes/getImage/${mensaje.mensaje}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img
                                                        className="w-full rounded-md hover:scale-105"
                                                        src={`${process.env.REACT_APP_PROXY}/chat_mensajes/getImage/${mensaje.mensaje}`}
                                                        alt={mensaje.mensaje}
                                                    />
                                                </a>
                                                <span className="text-xs">
                                                    {mensaje.time_last_message}
                                                </span>
                                            </div>
                                        </div>
                                    ) : mensaje.tipo === "texto" ? (
                                        <div className="flex flex-col items-start justify-start w-full">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block justify-end mt-1  max-w-[75%] md:max-w-[50%] bg-[#999999] rounded-lg p-1.5 text-white ">
                                                <p className="flex flex-col text-sm 2xl:text-base">
                                                    <div className="text-sm text-left 2xl:text-base">
                                                        {mensaje.mensaje}
                                                        {""}
                                                    </div>

                                                    <span className="2xl:mt-2 mt-1 italic text-right text-[10px]">
                                                        {
                                                            mensaje.time_last_message
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    ) : mensaje.tipo === "Pedido" ? (
                                        <div className="flex flex-col items-start justify-start w-full">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div
                                                className={`inline-block justify-end  max-w-[75%] md:max-w-[50%]  rounded-lg p-2 text-white  hover:opacity-80 ${
                                                    mensaje.mensaje ===
                                                    "No vigente"
                                                        ? " bg-red-400 opacity-60 cursor-not-allowed"
                                                        : "bg-gray-600 hover:opacity-80 "
                                                }`}>
                                                <div className="flex items-center justify-between">
                                                    <p className="text-sm text-left 2xl:text-base">
                                                        Propuesta de reserva
                                                    </p>

                                                    <svg
                                                        onClick={
                                                            mensaje.mensaje ===
                                                            "No vigente"
                                                                ? () => {}
                                                                : () =>
                                                                      pagarPromo(
                                                                          mensaje
                                                                              .pedido
                                                                              .id
                                                                      )
                                                        }
                                                        className={`2xl:w-6 2xl:h-6 w-6 h-6 text-white  ${
                                                            mensaje.mensaje ===
                                                            "No vigente"
                                                                ? " cursor-not-allowed"
                                                                : "cursor-pointer hover:scale-105"
                                                        }`}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round">
                                                        {" "}
                                                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />{" "}
                                                        <polyline points="15 3 21 3 21 9" />{" "}
                                                        <line
                                                            x1="10"
                                                            y1="14"
                                                            x2="21"
                                                            y2="3"
                                                        />
                                                    </svg>
                                                </div>
                                                <hr className="2xl:my-2 my-1.5" />
                                                <div className="p-2 text-sm border rounded-md 2xl:text-base">
                                                    <p className="text-left ">
                                                        Check-in:{" "}
                                                        <ShowDateToUser
                                                            date={
                                                                mensaje.pedido
                                                                    .fecha_inicio
                                                            }
                                                        />
                                                    </p>
                                                    <p className="text-left">
                                                        Check-out:{" "}
                                                        <ShowDateToUser
                                                            date={
                                                                mensaje.pedido
                                                                    .fecha_final
                                                            }
                                                        />
                                                    </p>
                                                    <p className="text-left">
                                                        Costo total:{" "}
                                                        {
                                                            mensaje.pedido
                                                                .costo_total
                                                        }{" "}
                                                        {
                                                            mensaje.pedido
                                                                .tipo_moneda
                                                        }
                                                    </p>
                                                    <p className="mt-1 text-xs text-right">
                                                        {" "}
                                                        {mensaje.mensaje}
                                                    </p>
                                                </div>
                                                <span className="mt-2 italic text-right text-[10px]">
                                                    {mensaje.time_last_message}
                                                </span>
                                            </div>
                                        </div>
                                    ) : mensaje.tipo === "chatbot" ? (
                                        <div className="flex flex-col items-start justify-start w-full">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block justify-end mt-1  max-w-[75%]  bg-[#999999] rounded-lg p-1.5 text-white ">
                                                {mensaje.imagen_bot !==
                                                    "sinImagen.png" && (
                                                    <div className="inline-block justify-end w-full p-3 text-white  rounded-md   hover:cursor-zoom-in">
                                                        <a
                                                            className="cursor-zoom-in"
                                                            href={`${process.env.REACT_APP_PROXY}/respuestas_bot/getImage/${mensaje.imagen_bot}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            <img
                                                                className="w-auto h-64 object-contain rounded-md hover:scale-105"
                                                                src={`${process.env.REACT_APP_PROXY}/respuestas_bot/getImage/${mensaje.imagen_bot}`}
                                                                alt={
                                                                    mensaje.imagen_bot
                                                                }
                                                            />
                                                        </a>
                                                    </div>
                                                )}{" "}
                                                <p className="flex flex-col text-sm 2xl:text-base px-3">
                                                    <div className="text-sm text-left 2xl:text-base">
                                                        {mensaje.mensaje}
                                                        {""}
                                                    </div>

                                                    <span className="2xl:mt-2 mt-1 italic text-right text-[10px]">
                                                        {
                                                            mensaje.time_last_message
                                                        }
                                                    </span>
                                                </p>
                                                <div className="w-full flex flex-col gap-2 justify-center items-center">
                                                    {mensaje.opciones.map(
                                                        (opcion, index) => (
                                                            <div
                                                                key={
                                                                    opcion.id_submenu_bots
                                                                }
                                                                onClick={() =>
                                                                    handleResponseBot &&
                                                                    Number(
                                                                        mensaje.response_bot
                                                                    ) === 0 &&
                                                                    handleResponseBot(
                                                                        opcion.opcion,
                                                                        opcion.id_submenu_bots,
                                                                        mensaje.id
                                                                    )
                                                                }
                                                                className={`w-full text-sm 2xl:text-base p-1 ${
                                                                    Number(
                                                                        mensaje.response_bot
                                                                    ) === 0
                                                                        ? "cursor-pointer hover:bg-[#b7b5b5]"
                                                                        : " "
                                                                }  border-2 rounded-md `}>
                                                                {index + 1}.{" "}
                                                                {opcion.opcion}{" "}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-start justify-start w-full">
                                            <p className="font-bold text-left text-gray-400">
                                                <span className="text-xs">
                                                    {chat &&
                                                        `${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.nombre ||
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.cliente
                                                        } ${
                                                            findChatObjectByType(
                                                                chat?.usersChat,
                                                                mensaje.id_usuario
                                                            )?.usuario
                                                                ?.apellido || ""
                                                        }`}
                                                </span>
                                            </p>

                                            <div className="inline-block text-left  max-w-[75%] md:max-w-[50%] bg-[#999999] rounded-lg p-2 text-white break-all">
                                                <a
                                                    className="flex items-center justify-center gap-2 hover:underline"
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                    href={`${process.env.REACT_APP_PROXY}/chat_mensajes/getImage/${mensaje.mensaje}`}>
                                                    {typeOfMessage(
                                                        mensaje.tipo
                                                    ) === "word" ? (
                                                        <svg
                                                            className="w-10 h-10"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            width="48"
                                                            height="48"
                                                            viewBox="0 0 48 48">
                                                            <path
                                                                fill="#2196F3"
                                                                d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"></path>
                                                            <path
                                                                fill="#FFF"
                                                                d="M25 15.001H39V17H25zM25 19H39V21H25zM25 23.001H39V25.001H25zM25 27.001H39V29H25zM25 31H39V33.001H25z"></path>
                                                            <path
                                                                fill="#0D47A1"
                                                                d="M27 42L6 38 6 10 27 6z"></path>
                                                            <path
                                                                fill="#FFF"
                                                                d="M21.167,31.012H18.45l-1.802-8.988c-0.098-0.477-0.155-0.996-0.174-1.576h-0.032c-0.043,0.637-0.11,1.162-0.197,1.576l-1.85,8.988h-2.827l-2.86-14.014h2.675l1.536,9.328c0.062,0.404,0.111,0.938,0.143,1.607h0.042c0.019-0.498,0.098-1.051,0.223-1.645l1.97-9.291h2.622l1.785,9.404c0.062,0.348,0.119,0.846,0.17,1.511h0.031c0.02-0.515,0.073-1.035,0.16-1.563l1.503-9.352h2.468L21.167,31.012z"></path>
                                                        </svg>
                                                    ) : typeOfMessage(
                                                          mensaje.tipo
                                                      ) === "pdf" ? (
                                                        <img
                                                            className="w-10 h-10 p-0.5"
                                                            src={pdfIcon}
                                                            alt="Archivo pdf"
                                                        />
                                                    ) : typeOfMessage(
                                                          mensaje.tipo
                                                      ) === "excel" ? (
                                                        <img
                                                            className="w-10 h-10 p-1"
                                                            src={excelIcon}
                                                            alt="Archivo excel"
                                                        />
                                                    ) : null}
                                                    <span>
                                                        {mensaje.tipo
                                                            .split("_")
                                                            .slice(1)
                                                            .join("_")}
                                                    </span>

                                                    <span className="text-sm 2xl:text-base">
                                                        {mensaje.mensaje}
                                                    </span>
                                                </a>
                                                <span className="mt-2 italic text-right text-[10px]">
                                                    {mensaje.time_last_message}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        }
                    })}
                </div>
            ) : (
                <div className="mt-10 text-sm font-medium text-center">
                    Aún no has iniciado esta conversación
                </div>
            )}
        </div>
    );
};

import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    apellido: "",
    avatar: "",
    banner: "",
    celular: "",
    correo: "",
    descripcion: "",
    id: 0,
    nombre: "",
    tiktok: "",
    instagram: "",
    facebook: "",
    terminado: "",
    casas: [],
    plan: 0,
};

export const corredor = createSlice({
    name: "corredor",
    initialState: INITIAL_STATE,
    reducers: {
        set_corredor: (state, action) => {
            console.log(action.payload);
            return {
                ...action.payload,
            };
        },
    },
});

export const { set_corredor } = corredor.actions;

export default corredor.reducer;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OwnerRegisterPlan } from "./OwnerRegisterPlan";
import { CheckoutSuscripcion } from "./CheckoutSuscripcion";

interface Input {
    grupo_trabajo: string;
    token: string;
    idCasa: number | null;
}

export const RegistroConPlan = () => {
    const [inputPlan, setInputPlan] = useState<Input>({
        grupo_trabajo: "",
        idCasa: null,
        token: "",
    });

    const [paso, setPago] = useState(2);
    return (
        <div>
            <OwnerRegisterPlan setInputPlan={setInputPlan} />
            {/* {inputPlan.idCasa === null ? (
                
            ) : (
                <CheckoutSuscripcion input={inputPlan} />
            )} */}
        </div>
    );
};

import React, { useState, useEffect } from "react";

import Spinner from "../../components/helpers/Spinner";

import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import { CapacidadesCasa } from "../capacidades/CapacidadesCasa";
import { ModalEditPrecioBase } from "../modalEdit/ModalEditPrecioBase";
import { ModalEditName } from "../modalEdit/ModalEditName";
import { formatearNumero } from "../../components/helpers/formatearNumero";

interface Props {
    toggleButton: boolean;
    tipo_landing: number;
}

interface Input {
    nombre: string;
    precio_base: string;
    tipo_moneda: string;
}
export const PrecioBase = ({ toggleButton, tipo_landing }: Props) => {
    const {
        nombre,
        precio_base,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatchDominio = useDispatchDominio();
    const [editName, setEditName] = useState<boolean>(false);
    const [editPrice, setEditPrice] = useState<boolean>(false);
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const [input, setInput] = useState<Input>({
        nombre: nombre,
        precio_base: precio_base,
        tipo_moneda: SELECTED_CURRENCY,
    });
    const { price_to_currency } = usePriceToSelectedCurrency();

    useEffect(() => {
        setInput({
            nombre: nombre,
            precio_base: precio_base,
            tipo_moneda: SELECTED_CURRENCY,
        });
    }, [precio_base, nombre]);

    return (
        <div>
            <div className="justify-between mx-auto sm:flex">
                {editPrice && (
                    <ModalEditPrecioBase
                        modalVisible={editPrice}
                        setModalVisible={setEditPrice}
                    />
                )}

                {editName && (
                    <ModalEditName
                        modalVisible={editName}
                        setModalVisible={setEditName}
                    />
                )}
                <div className="flex flex-col items-center justify-center w-full mt-0 md:mt-4 lg:flex-row md:justify-start lg:w-3/5 lg:mx-5 ">
                    <div className="flex">
                        <p className="mb-4 text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl text-center md:text-left font-bold text-[#1C4CBB] sm:mb-0  select-none uppercase">
                            {input.nombre}
                        </p>
                        {toggleButton && (
                            <div
                                onClick={() => setEditName(!editName)}
                                title="editar nombre"
                                className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100">
                                <svg
                                    className="w-8 h-8 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Nombre
                                </div>
                            </div>
                        )}
                    </div>
                    {tipo_landing !== 8 && (
                        <div className="flex items-center justify-center w-full select-none lg:ml-10">
                            <CapacidadesCasa
                                toggleButton={toggleButton}
                                colorIcono={"#1C4CBB"}
                            />
                        </div>
                    )}
                </div>
                {tipo_landing !== 8 && (
                    <div className="flex flex-col items-center justify-center w-full lg:w-2/5">
                        <div className="flex">
                            <div className="p-3 md:p-4 sm:px-8 text-lg md:text-xl 2xl:text-2xl text-center text-white align-middle bg-[#1C4CBB] rounded-lg ">
                                <p className="font-light select-none">
                                    Desde{" "}
                                    <span className="font-semibold">{`   ${
                                        input.tipo_moneda
                                    } ${formatearNumero(
                                        price_to_currency(
                                            Number(input.precio_base)
                                        )
                                    )} `}</span>{" "}
                                    /por noche
                                </p>
                            </div>
                            {toggleButton && (
                                <div
                                    onClick={() => setEditPrice(!editPrice)}
                                    title="editar Precio base"
                                    className="flex flex-col items-center px-2 py-1 my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100">
                                    <svg
                                        className="w-8 h-8 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB] text-center">
                                        Editar Precio
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

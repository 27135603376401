import React, { useState } from "react";

import axios from "axios";
import { readFile, utils } from "xlsx";
import excel_contactos from "../../../assets/excels/plantilla_carga_contactos.xlsx";

import Swal from "sweetalert2";
import ModalEmpleadosFallidos from "./ModalEmpleadosFallidos";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";

interface Props {
    setModalVisibleUsuario: (modalVisibleUsuario: boolean) => void;
    modalVisibleUsuario: boolean;
}

export interface ClienteCargado {
    message: string;
    cliente: Cliente;
}

export interface Cliente {
    nombre: string;
    apellido: string;
    correo: string;
    password: string;
    celular: number;
    id_casa: number;
    tipo_usuario: number;
    avatar: string;
    habilitado: string;
    fecha_registro: string;
    grupo_trabajo: string;
}

const ModalCargaMasiva = ({
    modalVisibleUsuario,
    setModalVisibleUsuario,
}: Props) => {
    const [modalFallidos, setModalFallidos] = useState(false);
    const [empleadosCargados, setEmpleadosCargados] = useState<
        ClienteCargado[]
    >([]);
    const [empleadosFallidos, setEmpleadosFallidos] = useState<
        ClienteCargado[]
    >([]);

    const getEmpleadosFromExcel = async (excelEmpleados: any) => {
        const totalColumns = excelEmpleados[0].length;
        const arrayEmpleados: Cliente[] = [];

        if (
            totalColumns !== 4 ||
            !excelEmpleados[0].includes("nombre") ||
            !excelEmpleados[0].includes("apellido") ||
            !excelEmpleados[0].includes("correo") ||
            !excelEmpleados[0].includes("celular")
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "El excel ingresado no coincide con la plantilla especificada",
                icon: "error",
                confirmButtonText: "Aceptar",
            });
        } else {
            excelEmpleados.forEach((empleado: any, index: any) => {
                if (index > 0) {
                    const newEmpleado: any = {
                        fila: index + 1,
                    };
                    for (let i = 0; i < totalColumns; i++) {
                        newEmpleado[excelEmpleados[0][i]] = empleado[i] || null;
                    }
                    arrayEmpleados.push(newEmpleado);
                }
            });
        }
        const datosFiltrados = arrayEmpleados.filter(
            (objeto) => objeto.nombre !== null
        );
        return datosFiltrados;
    };

    const handleInputChange = async (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            console.log("No se cargo un archivo");
        } else {
            const file = e.target.files[0];
            const fileBuffer = await file.arrayBuffer();
            const EXCEL = readFile(fileBuffer);
            const empleadosExcel = utils.sheet_to_json(EXCEL.Sheets["Hoja1"], {
                header: 1,
            });

            if (empleadosExcel.length === 0) {
                return Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Por favor utilice el mismo excel con la plantilla especificada",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
            const arrayEmpleados = await getEmpleadosFromExcel(empleadosExcel);

            const data = await fetchAxiosToken({
                url: `/clientes/postContactMasive`,
                method: "post",
                body: { usuarios: arrayEmpleados, tipo_usuario: 9 },
            });

            const { clientesCargados, clientesFallidos } = data;

            if (data) {
                setEmpleadosFallidos(clientesFallidos);
                setEmpleadosCargados(clientesCargados);
                setModalFallidos(!modalFallidos);
            }
        }
    };

    const handleClose = () => {
        setModalVisibleUsuario(!modalVisibleUsuario);
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleUsuario}
            setModalVisible={setModalVisibleUsuario}>
            <div className="flex flex-col items-center justify-center max-w-lg gap-4 p-4 mx-auto ">
                <header className="2xl:mt-20 mt-5 2xl:text-lg text-base font-medium">
                    Carga masiva de contactos
                </header>
                <div className="flex flex-col justify-center mx-auto mt-4 2xl:text-base text-sm text-center">
                    <span className="max-w-xs">
                        Antes de importar un excel, asegurate que cumpla
                        nuestros requisitos de plantilla
                    </span>
                    <button className="mt-4 h-12 relative md:h-auto justify-center max-w-[180px] mx-auto items-center flex p-3 text-gray-600 border border-gray-400 rounded-lg hover:scale-105">
                        <a href={excel_contactos}>Descargar plantilla</a>
                    </button>
                    <span className="max-w-xs mt-4">
                        Si ya descargaste y completaste la plantilla, importala
                        aqui debajo.
                    </span>
                </div>
                <div className="mx-auto 2xl:mt-4 mt-2 hover:scale-105">
                    <button className="relative flex items-center justify-center h-12 p-3 text-blue-500 border border-blue-500 rounded-lg md:h-auto 2xl:text-base text-sm">
                        <span>Importar desde excel</span>
                        <input
                            onChange={handleInputChange}
                            type="file"
                            name="excel"
                            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer bg-red-50 file:cursor-pointer"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                    </button>
                </div>
                {modalFallidos && (
                    <ModalEmpleadosFallidos
                        empleadosCargados={empleadosCargados}
                        empleadosFallidos={empleadosFallidos}
                        modalVisible={modalFallidos}
                        setModalVisible={setModalVisibleUsuario}
                    />
                )}
            </div>
        </ResponsiveModalAbsolute>
    );
};

export default ModalCargaMasiva;

import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import useHeaders from "../../hooks/useHeaders";
import { NavbarHotel } from "./NavbarHotel";
import { NavbarMobileHotel } from "./NavbarMobileHotel";
import { verifyToken } from "../../redux/reducers/authReducer";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const MiCuentaHotel = () => {
    const { avatar, nombre, apellido, correo, celular, id } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const dispatch = useAppDispatch();

    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        nombre: nombre,
        apellido: apellido,
        correo: correo,
        celular: celular,
    });
    const [selectedFile, setSelectedFile] = useState<File>(avatar);
    const [preview, setPreview] = useState("");
    const { headers } = useHeaders();
    const INPUTS = [
        {
            input_id: 0,
            label: "Nombre",
            nombre: "nombre",
            type: "text",
        },
        {
            input_id: 1,
            label: "Apellido",
            nombre: "apellido",
            type: "text",
        },
        {
            input_id: 2,
            label: "Correo",
            nombre: "correo",
            type: "email",
        },
        {
            input_id: 3,
            label: "Celular",
            nombre: "celular",
            type: "text",
        },
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(selectedFile);
            return;
        }
        setSelectedFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
    };

    const handleSave = async () => {
        const form = new FormData();

        form.append("nombre", userInfo.nombre.trim());
        form.append("apellido", userInfo.apellido.trim());
        form.append("correo", userInfo.correo.trim());
        form.append("celular", userInfo.celular.trim());
        form.append("avatar_usuario", selectedFile);

        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/usuarios/update/${id}`,
                form,
                { headers }
            );
            dispatch(verifyToken());
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                position: "top-end",
                icon: "success",
                title: "Tus datos han sido guardados correctamente!",
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex">
            <NavbarHotel titulo="Mi cuenta">
                <div className="flex flex-col-reverse items-center justify-end w-full sm:mb-0">
                    <div className="flex flex-col items-center w-full p-6">
                        {INPUTS.map(({ nombre, input_id, type, label }) => (
                            <div key={input_id} className="w-full max-w-xl">
                                <label className="font-medium">{label}</label>
                                <input
                                    className="w-full p-2 mt-1 border rounded-xl"
                                    placeholder={label}
                                    name={nombre}
                                    value={userInfo[nombre]}
                                    onChange={handleInputChange}
                                    type={type}
                                />
                            </div>
                        ))}
                        <button
                            onClick={() => handleSave()}
                            className="p-3 mt-4 font-medium text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 active:translate-y-1">
                            Guardar cambios
                        </button>
                    </div>
                    <div className="flex flex-col items-center w-full mt-4 select-none">
                        <div className="flex overflow-hidden transition-transform duration-300 ease-in-out rounded-full w-60 h-60">
                            <div className="relative transition-transform duration-300 ease-in-out hover:scale-125">
                                <input
                                    onChange={handleChangeImage}
                                    type="file"
                                    accept="image/*"
                                    className="absolute left-0 z-50 w-full h-full bg-red-100 rounded-full opacity-0 cursor-pointer select-none file:cursor-pointer"
                                />
                                {preview && avatar ? (
                                    <img
                                        src={preview}
                                        className="rounded-full flex h-60 w-60 p-[0.050rem] border-[3px] object-cover cursor-pointer border-blue-blueFrom"
                                        alt="foto de perfil"
                                    />
                                ) : (
                                    <img
                                        src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`}
                                        className="rounded-full p-[0.050rem] w-60 h-60 border-[3px] object-cover cursor-pointer border-blue-blueFrom"
                                        alt="foto   "
                                    />
                                )}
                            </div>
                        </div>
                        <span className="relative mt-2 text-center cursor-pointer w-max text-blue-blueFrom hover:scale-105">
                            <span>Cambiar foto de perfil</span>{" "}
                            <span className="text-xl text-red-600">*</span>
                            <input
                                onChange={handleChangeImage}
                                type="file"
                                className="absolute left-0 z-50 w-full h-full bg-red-200 opacity-0 cursor-pointer select-none file:cursor-pointer"
                            />
                        </span>
                    </div>
                </div>
            </NavbarHotel>
            <NavbarMobileHotel />
        </div>
    );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import capacidad from "../../img/onboarding/capacidad.jpg";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import "../../assets/css/styles_input_number.css";

import { loadStripe } from "@stripe/stripe-js";

interface Input {
    grupo_trabajo: string;
    token: string;

    idCasa: number | null;
}

export const PagarSuscripcion = () => {
    const { idCasa } = useParams();
    const { id: id_casa, plan } = useAppSelector(
        (state) => state.landing_house.house
    );

    const { id: id_usuario, grupo_trabajo } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
    );

    const Starter = {
        plan: "Starter",
        costo: 19,
        tipo_pago: "Mensual",
        anual: 228,
        descuento: 36,
        price_id_mensual: process.env.REACT_APP_PLAN_STARTER_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_STARTER_ANUAL,
    };
    const Standard = {
        plan: "Stándar",
        costo: 39.0,
        tipo_pago: "Mensual",
        anual: 468.0,
        descuento: 84,
        price_id_mensual: process.env.REACT_APP_PLAN_ESTANDAR_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_ESTANDAR_ANUAL,
    };
    const StandardEUR = {
        plan: "Stándar",
        costo: 89.0,
        tipo_pago: "Mensual",
        anual: 869.0,
        descuento: 199,
    };
    const Premium = {
        plan: "Premium",
        costo: 79.0,
        tipo_pago: "Mensual",
        anual: 948.0,
        descuento: 180,
        price_id_mensual: process.env.REACT_APP_PLAN_PREMIUM_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_PREMIUM_ANUAL,
    };
    const PremiumEUR = {
        plan: "Premium",
        costo: 179.0,
        tipo_pago: "Anual",
        anual: 1749.0,
        descuento: 399,
    };

    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const [currency, setCurrency] = useState("USD");
    const [moneda, setMoneda] = useState("$");

    const [Plan, setPlan] = useState(false);

    const handlePlan = () => {
        setPlan(!Plan);
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    const handleSubmit = async (suscripcion: any, pagoUnico: boolean) => {
        let costo = get_costo(suscripcion);
        const amountx100 = Math.trunc(Number(costo) * 100);
        let priceId = getCodePrice(suscripcion);
        let plan =
            Number(0) > 0
                ? suscripcion === "1"
                    ? "De 2 a 4 casas"
                    : suscripcion === "2"
                    ? "De 5 a 9 casas"
                    : "De 10 a 15 casas"
                : suscripcion === "1"
                ? "Starter"
                : suscripcion === "2"
                ? "Stándar"
                : "Premium";

        let tipo_pago = pagoUnico ? "Mensual" : "Anual";

        let datos = {
            costo: amountx100, //Es en centavos osea x 100
            id_corredor: 0,
            plan: plan,
            tipo_moneda: currency,
            tipo_pago: tipo_pago,
            id_casa: id_casa,
            priceId,
            user: {
                subdomain: {
                    name: grupo_trabajo,
                },
                id: id_usuario,
            },
            cancel_url: "/dashboard/onboarding/suscripcion",
            success_url: "/dashboard/onboarding/capacidad",
        };

        try {
            if (idCasa && Number(idCasa) > 0) {
                /*    await fetchAxiosToken({
                        url: `/casa/putPersonasMascotas/${idCasa}`,
                        method: "put",
                        body: input,
                    }); */
                /*   await fetchAxiosToken({
                    url: `/casa/putPagina/${idCasa}`,
                    method: "put",
                    body: {
                        pagina: "tema",
                    },
                }); */
                /* dispatchDominio(); */
                /* navigate(`/dashboard-corredor/onboarding/tema/${idCasa}`); */
            } else {
                try {
                    const response = await fetchAxiosToken({
                        url: `/suscripcion_pagos/create-stripe/`,
                        method: "post",
                        body: datos,
                    });

                    /*  console.log(response.createSession.sessionUrl); */
                    const urlStripe = response.createSession.sessionUrl;
                    window.location.href = urlStripe;
                } catch (error) {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "error",
                        title: "Hubo un error contactarse con soporte",

                        showConfirmButton: true,
                        // timer: 2000,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getCodePrice = (suscripcion: any) => {
        if (suscripcion === "1") {
            return Plan ? Starter.price_id_mensual : Starter.price_id_anual;
        } else if (suscripcion === "2") {
            return Plan ? Standard.price_id_mensual : Standard.price_id_anual;
        } else {
            return Plan ? Premium.price_id_mensual : Premium.price_id_anual;
        }
    };

    const get_costo = (suscripcion: any) => {
        if (suscripcion === "1") {
            return Plan
                ? currency === "USD"
                    ? Starter.costo
                    : Starter.costo
                : currency === "USD"
                ? Number(Starter.anual) - Number(Starter.descuento)
                : Number(Starter.anual) - Number(Starter.descuento);
        } else if (suscripcion === "2") {
            return Plan
                ? currency === "USD"
                    ? Standard.costo
                    : Standard.costo
                : currency === "USD"
                ? Number(Standard.anual) - Number(Standard.descuento)
                : Number(Standard.anual) - Number(Standard.descuento);
        } else {
            return Plan
                ? currency === "USD"
                    ? Premium.costo
                    : PremiumEUR.costo
                : currency === "USD"
                ? Number(Premium.anual) - Number(Premium.descuento)
                : Number(Premium.anual) - Number(Premium.descuento);
        }
    };

    const handlePrueba = async () => {
        // Mostrar alerta de confirmación
        console.log("dsadsa");
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "¿Deseas habilitar tu prueba gratuita de 14 días?",
            icon: "warning",
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            showCancelButton: true,
            confirmButtonText: "Sí, habilitar",
            cancelButtonText: "Cancelar",
        });

        // Si el usuario confirma
        if (result.isConfirmed) {
            try {
                const response = await fetchAxiosToken({
                    url: `/suscripcion_pagos/postPrueba`,
                    method: "post",
                    body: {
                        id_casa: id_casa,
                        id_corredor: 0,
                    },
                });

                // Mostrar alerta de éxito
                await Swal.fire({
                    title: "¡Habilitado!",
                    text: "Tu prueba de 14 días ha sido habilitada.",
                    icon: "success",
                });
                dispatchDominio();
                navigate(`/dashboard/onboarding/capacidad`);
            } catch (error) {
                // Mostrar alerta de error
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un error al habilitar la prueba.",
                    icon: "error",
                });
            }
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/3 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={capacidad}
                        alt="capacidad"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Elige tu plan
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center h-screen gap-2 p-3 text-center text-black bg-white">
                <ButtonRegresar
                    url={`${
                        idCasa
                            ? `/dashboard-corredor/onboarding/alojamiento/${idCasa}`
                            : "/dashboard/onboarding/alojamiento"
                    }`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />

                <div className="flex flex-col items-start justify-start mx-auto mt-10 w-5/6 2xl:mt-10 ">
                    <div className="flex flex-col justify-center h-auto mb-5 mx-auto">
                        <div className="text-[#179BD8] text-center text-[15px] md:text-[20px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] font-semibold ">
                            Nuestros Planes
                        </div>

                        <div className="">
                            <div className="flex items-center justify-center px-1 py-1 mx-auto border-2 w-60 rounded-3xl">
                                <div
                                    className={`${
                                        !Plan
                                            ? "bg-[#0E1928] text-[#C0D345]"
                                            : " text-[#0E1928]"
                                    }   text-center  rounded-3xl cursor-pointer w-1/2 h-full `}
                                    onClick={handlePlan}
                                >
                                    <p className="text-lg ">Anual</p>
                                    <p
                                        className={`text-[8px] w-full  mb-1 ${
                                            !Plan
                                                ? "text-white"
                                                : " text-[#0E1928]"
                                        }`}
                                    >
                                        Ahorra un 20% de dscto
                                    </p>
                                </div>
                                <div
                                    className={` ${
                                        Plan
                                            ? "bg-[#0E1928] text-[#C0D345]"
                                            : " text-[#0E1928]"
                                    }   px-4 text-lg   rounded-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                    onClick={handlePlan}
                                >
                                    Mensual
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center w-full gap-6  mx-auto lg:flex-row ">
                        <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5 h-auto 2xl:w-[400px] w-[333px] mb-4">
                            <div
                                className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                            >
                                Starter
                            </div>

                            <div className="flex flex-col gap-8 md:gap-0">
                                <div className="flex flex-col ">
                                    <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                        {Plan ? (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-[#179BD8] text-4xl font-bold">
                                                    {" "}
                                                    {moneda === "$" ? 19 : 19}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl text-[#179BD8] font-bold">
                                                    {" "}
                                                    {moneda === "$" ? 16 : 16}
                                                </p>
                                            </div>
                                        )}

                                        <div className="text-base font-semibold xl:text-lg">
                                            /mes
                                        </div>
                                    </div>
                                    <div className="text-[#0E1928]  text-xs  flex items-center gap-1 mt-2">
                                        {moneda}
                                        {!Plan
                                            ? moneda === "$"
                                                ? "192"
                                                : "192"
                                            : moneda === "$"
                                            ? "228"
                                            : "228"}{" "}
                                        al año.
                                        {!Plan && (
                                            <div className="text-[#179BD8] text-xs font-bold">
                                                Ahorras {moneda}
                                                {moneda === "$" ? 36 : 36}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col text-left">
                                    <div
                                        className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                    >
                                        <ul className="marker:text-[#0E1928] text-xs 2xl:text-base md:text-md list-outside list-disc ml-3">
                                            <li className="mb-1">
                                                Landing personalizada
                                            </li>
                                            <li className="mb-1">
                                                Dominio para tu página
                                            </li>
                                            <li className="mb-1">
                                                Reseñas de clientes
                                            </li>
                                            <li className="mb-1">
                                                Calendario de reservas
                                            </li>
                                            <li className="mb-1">
                                                Pago por transferencia
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleSubmit("1", Plan)}
                                    className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer hover:scale-105 flex  w-full text-center mx-auto"
                                >
                                    <p className="w-full text-center">
                                        COMPRAR
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col  rounded-2xl  bg-[#0E1928] text-white border-solid px-5  h-auto 2xl:w-[400px] w-[333px] mb-4 ">
                            <div
                                className={` text-lg md:text-xl xl:text-2xl flex items-center justify-between w-full  ${"mt-5"} `}
                            >
                                <p>Estándar</p>{" "}
                                <p className="text-[10px] text-[#C0D345] bg-[#C0D345]/10 rounded-3xl py-1 px-2 ">
                                    El más seleccionado
                                </p>
                            </div>

                            <div className="flex flex-col gap-8 md:gap-0">
                                <div className="flex flex-col ">
                                    <div className="flex items-end mt-2 text-2xl md:text-3xl xl:text-5xl">
                                        {Plan ? (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl font-bold text-[#C0D345]">
                                                    {" "}
                                                    {moneda === "$" ? 39 : 39}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl text-[#C0D345] font-bold">
                                                    {" "}
                                                    {moneda === "$" ? 32 : 32}
                                                </p>
                                            </div>
                                        )}

                                        <div className="text-base font-semibold xl:text-lg">
                                            /mes
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-1 mt-2 text-xs ">
                                        {moneda}
                                        {!Plan
                                            ? moneda === "$"
                                                ? "384"
                                                : "384"
                                            : moneda === "$"
                                            ? "468"
                                            : "468"}{" "}
                                        al año.
                                        {!Plan && (
                                            <div className="text-[#C0D345] text-xs   font-bold">
                                                Ahorras {moneda}
                                                {moneda === "$" ? 84 : 84}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col  text-left">
                                    <div className={` text-sm  mt-4 mb-0`}>
                                        <ul className="ml-3  list-disc list-outside marker:text-white text-xs 2xl:text-base md:text-md">
                                            <li className="mb-1">
                                                CRM de clientes
                                            </li>
                                            <li className="mb-1">
                                                Plataformas de pago
                                            </li>
                                            <li className="mb-1">
                                                Creación de cupones
                                            </li>
                                            <li className="mb-1">
                                                Chat y gestión de clientes
                                            </li>
                                            <li className="mb-1">
                                                Resumen de estadísticas
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleSubmit("2", Plan)}
                                    className="bg-[#C0D345] py-1 px-8 my-8 text-base md:text-xl text-[#0E1928] font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer hover:scale-105 flex w-full text-center mx-auto"
                                >
                                    <p className="w-full text-center">
                                        COMPRAR
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto 2xl:w-[400px] w-[333px] mb-4">
                            <div
                                className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                            >
                                Premium
                            </div>

                            <div className="flex flex-col gap-8 md:gap-0">
                                <div className="flex flex-col ">
                                    <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                        {Plan ? (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl text-[#179BD8] font-bold">
                                                    {" "}
                                                    {moneda === "$" ? 79 : 79}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl text-[#179BD8] font-bold">
                                                    {" "}
                                                    {moneda === "$" ? 64 : 64}
                                                </p>
                                            </div>
                                        )}

                                        <div className="text-base font-semibold xl:text-lg">
                                            /mes
                                        </div>
                                    </div>
                                    <div className="text-[#0E1928]  text-xs  flex items-center gap-1 mt-2">
                                        {moneda}
                                        {!Plan
                                            ? moneda === "$"
                                                ? "768"
                                                : "768"
                                            : moneda === "$"
                                            ? "948"
                                            : "948"}{" "}
                                        al año.
                                        {!Plan && (
                                            <div className="text-[#179BD8] text-xs  font-bold">
                                                Ahorras {moneda}
                                                {moneda === "$" ? 180 : 180}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col text-left">
                                    <div
                                        className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                    >
                                        <ul className="marker:text-[#0E1928] text-xs 2xl:text-base md:text-md list-outside list-disc ml-3">
                                            <li className="mb-1">
                                                Chatbot interno
                                            </li>
                                            <li className="mb-1">
                                                Integrar google calendar
                                            </li>
                                            <li className="mb-1">
                                                Plantillas para contratos
                                            </li>
                                            <li className="mb-1">
                                                +2 Subusuarios
                                            </li>
                                            <li className="mb-1">
                                                Integrar Airbnb y Booking
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleSubmit("3", Plan)}
                                    className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer hover:scale-105 flex w-full text-center mx-auto"
                                >
                                    <p className="w-full text-center">
                                        COMPRAR
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={handlePrueba}
                        className="rounded-2xl  bg-[#0E1928]/5 p-5 w-full cursor-pointer hover:scale-105"
                    >
                        <div className="flex items-center justify-between">
                            <div className="font-semibold">
                                Prueba gratuita de 14 días
                                <div className="text-xs">
                                    En tu prueba gratuita tendrás todas las
                                    características del plan Estándar
                                </div>
                            </div>
                            <div className="px-5  py-3 bg-[#0E1928] text-white rounded-2xl">
                                Estándar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import MainNavbar from "../../components/MainNavbar/MainNavbar";

import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import logo from "../../img/logoPNGalerta.png";
interface RegisterForm {
    nombre?: string;
    apellido?: string;
    celular?: string;
    correo?: string;
    password?: string;
    password2?: string;
    dominio?: string;
    grupo_trabajo?: string;
}

type errorObject = { [key: string]: string };

const INITIAL_STATE = {
    correo: "",
    nombre: "",
    apellido: "",
    celular: "",
    password: "",
    password2: "",
    grupo_trabajo: "",
    tipo_logueo: "Correo",
    tipo_usuario: 6,
};

interface Input {
    grupo_trabajo: string;
    token: string;

    idUsuario: number | null;
}

export const OwnerRegisterPlan = ({
    setInputPlan,
}: {
    setInputPlan: (s: Input) => void;
}) => {
    const Dos_casas = {
        plan: "2 a 4 casas",
        costo: 169,
        tipo_pago: "Mensual",
        anual: 2028,
        descuento: 406,
        price_id_mensual: process.env.REACT_APP_PLAN_DOS_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DOS_ANUAL,
    };

    const Cinco_casas = {
        plan: "5 a 9 casas",
        costo: 299,
        tipo_pago: "Mensual",
        anual: 3588,
        descuento: 718,
        price_id_mensual: process.env.REACT_APP_PLAN_CINCO_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_CINCO_ANUAL,
    };

    const Diez_casas = {
        plan: "10 a 15 casas",
        costo: 449,
        tipo_pago: "Mensual",
        anual: 5388,
        descuento: 1078,
        price_id_mensual: process.env.REACT_APP_PLAN_DIEZ_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DIEZ_ANUAL,
    };

    const PremiumEUR = {
        plan: "Premium",
        costo: 179.0,
        tipo_pago: "Anual",
        anual: 1749.0,
        descuento: 399,
    };

    const [input, setInput] = useState<RegisterForm>(INITIAL_STATE);
    const [showPass, setShowPass] = useState<boolean>(false);
    const [errors, setErrors] = useState<RegisterForm>({});
    const [disponible, setDisponible] = useState("");

    const [currency, setCurrency] = useState("USD");

    const { tipo_pago, plan: id_suscripcion } = useParams();

    const [pagoUnico, setPagoUnico] = useState(
        tipo_pago === "1" ? false : true
    );

    const navigate = useNavigate();

    const debounceInput = useRef<number | null>(null);

    const getCodePrice = (suscripcion: any) => {
        if (suscripcion === "1") {
            return pagoUnico
                ? Dos_casas.price_id_mensual
                : Dos_casas.price_id_anual;
        } else if (suscripcion === "2") {
            return pagoUnico
                ? Cinco_casas.price_id_mensual
                : Cinco_casas.price_id_anual;
        } else {
            return pagoUnico
                ? Diez_casas.price_id_mensual
                : Diez_casas.price_id_anual;
        }
    };

    const get_costo = (suscripcion: any) => {
        if (suscripcion === "1") {
            return pagoUnico
                ? currency === "USD"
                    ? Dos_casas.costo
                    : Dos_casas.costo
                : currency === "USD"
                ? Number(Dos_casas.anual) - Number(Dos_casas.descuento)
                : Number(Dos_casas.anual) - Number(Dos_casas.descuento);
        } else if (suscripcion === "2") {
            return pagoUnico
                ? currency === "USD"
                    ? Cinco_casas.costo
                    : Cinco_casas.costo
                : currency === "USD"
                ? Number(Cinco_casas.anual) - Number(Cinco_casas.descuento)
                : Number(Cinco_casas.anual) - Number(Cinco_casas.descuento);
        } else {
            return pagoUnico
                ? currency === "USD"
                    ? Diez_casas.costo
                    : Diez_casas.costo
                : currency === "USD"
                ? Number(Diez_casas.anual) - Number(Diez_casas.descuento)
                : Number(Diez_casas.anual) - Number(Diez_casas.descuento);
        }
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "grupo_trabajo") {
            if (debounceInput.current !== null)
                clearTimeout(debounceInput.current);
            if (e.target.value === "") {
                setDisponible("");
            }
            debounceInput.current = window.setTimeout(async () => {
                try {
                    const { data } = await axios.get(
                        `${process.env.REACT_APP_PROXY}/casa/isExistDominio/${e.target.value}`
                    );

                    setDisponible(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }, 500);
        }
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const validateInput = (form: RegisterForm) => {
        const regExpEmail = new RegExp(
            /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
        );
        const regDominio = new RegExp("^[a-z0-9-]+$");
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.apellido) {
            errores.apellido = "Debe ingresar un apellido válido";
        } else if (!form.celular || form.celular?.length < 6) {
            errores.celular = "Debe ingresar un celular válido";
        } else if (!regExpEmail.test(form.correo || "")) {
            errores.correo = "Debe ingresar un dirección de email válida";
        } else if (!form.password) {
            errores.password = "Debe ingresar una contraseña";
        } else if (form.password !== form.password2) {
            errores.password2 = "Las contraseñas deben coincidir";
        } else if (!form.grupo_trabajo || form.grupo_trabajo.length < 5) {
            errores.grupo_trabajo =
                "El dominio debe tener al menos 5 caracteres";
        } else if (!regDominio.test(form.grupo_trabajo || "")) {
            errores.grupo_trabajo =
                "El dominio no puede tener espacio, aceptos, mayusculas ni caracteres especiales";
        } else if (disponible !== "No") {
            errores.grupo_trabajo =
                "El dominio no esta disponible , intente con otro";
        }

        return errores;
    };

    const handleRegister = async () => {
        const errors = validateInput(input);
        setErrors(errors);
        console.log(input);
        if (Object.entries(errors).length === 0) {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_PROXY}/usuarios/signUp`,
                    input
                );

                setInputPlan({
                    grupo_trabajo: data.usuario.grupo_trabajo,
                    idUsuario: data.usuario.id,
                    token: data.token,
                });

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: `Muchas gracias por registrarte`,
                    /*   text: "Te hemos enviado un correo para que guardes el link de tu nueva página web", */
                    text: "Ahora realiza completa tu suscripción para poder acceder a tu casa",
                    imageUrl: `${logo}`,
                    imageWidth: 300,
                    imageHeight: 300,
                }).then(async (res) => {
                    let costo = get_costo(id_suscripcion);
                    const amountx100 = Math.trunc(Number(costo) * 100);
                    let priceId = getCodePrice(id_suscripcion);
                    let plan =
                        Number(1) > 0
                            ? id_suscripcion === "1"
                                ? "2 a 4 casas"
                                : id_suscripcion === "2"
                                ? "5 a 9 casas"
                                : "10 a 15 casas"
                            : id_suscripcion === "1"
                            ? "Starter"
                            : id_suscripcion === "2"
                            ? "Stándar"
                            : "Premium";

                    let tipo_pago_str = pagoUnico ? "Mensual" : "Anual";

                    let datos = {
                        costo: amountx100, //Es en centavos osea x 100
                        id_corredor: data.usuario.id,
                        plan: plan,
                        tipo_moneda: currency,
                        tipo_pago: tipo_pago_str,
                        id_casa: 0,
                        priceId,
                        user: {
                            subdomain: {
                                name: data.usuario.grupo_trabajo,
                            },
                            id: data.usuario.id,
                        },
                        cancel_url: `/registro-corredor/${tipo_pago}/${id_suscripcion}`,
                        success_url: `/dashboard-corredor/onboarding/bienvenidalogin/${data.token}/`,
                        domain: true,
                    };

                    try {
                        const response = await fetchAxiosToken({
                            url: `/suscripcion_pagos/create-stripe/`,
                            method: "post",
                            body: datos,
                        });

                        /*  console.log(response.createSession.sessionUrl); */
                        const urlStripe = response.createSession.sessionUrl;
                        window.location.href = urlStripe;
                    } catch (error) {
                        console.log(error);
                        Swal.fire({
                            confirmButtonColor: "#029BFC",
                            iconColor: "#029BFC",
                            icon: "error",
                            title: "Hubo un error contactarse con soporte",

                            showConfirmButton: true,
                            // timer: 2000,
                        });
                    }
                });
            } catch (error: any) {
                console.log(error);

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: error.response.data.error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                });
            }
        }
    };

    return (
        <>
            <MainNavbar />
            <div className="flex flex-col items-center justify-center h-screen p-4 mt-48">
                <div className="flex flex-col gap-2 mt-44 text-center">
                    <h1 className="text-xl font-bold lg:text-3xl text-[#1C4CBB]">
                        Crear cuenta
                    </h1>
                    <div className={"font-medium text-[#1C4CBB]"}>
                        ¿Ya tienes una cuenta?{" "}
                        <span
                            onClick={() => navigate("/home/login")}
                            className="text-blue-600 cursor-pointer hover:underline"
                        >
                            Ingresa aquí.
                        </span>
                    </div>
                    <p className="font-medium text-[#1C4CBB]">
                        Regístrate con tu correo electrónico y escoge el nombre
                        de tu subdominio
                    </p>
                    <span className="text-sm text-gray-600">
                        No te preocupes podrás cambiarlo más adelante
                    </span>
                </div>
                <div className="mt-5 w-full md:w-1/2 md:max-w-[468px]">
                    <label className="text-sm font-medium text-gray-600">
                        Nombre
                    </label>
                    <input
                        value={input.nombre}
                        onChange={handleInputChange}
                        name="nombre"
                        type={"text"}
                        className="w-full p-2 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                        placeholder="nombre"
                    />
                    {errors.nombre && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.nombre}
                        </p>
                    )}
                    <label className="pt-2 text-sm font-medium text-gray-600">
                        Apellido
                    </label>
                    <input
                        value={input.apellido}
                        onChange={handleInputChange}
                        name="apellido"
                        type={"text"}
                        className="w-full p-2 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                        placeholder="apellido"
                    />
                    {errors.apellido && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.apellido}
                        </p>
                    )}

                    <p className="pt-2 text-sm font-medium text-gray-600">
                        Celular
                    </p>

                    <PhoneInput
                        country={"pe"}
                        value={input.celular}
                        onChange={(celular) =>
                            setInput({
                                ...input,
                                celular,
                            })
                        }
                        inputStyle={{
                            width: "100%",
                            padding: "1.3rem 3rem",
                            border: "1px solid #1C4CBB",
                            borderRadius: "10px",

                            fontStyle: "italic",
                        }}
                        buttonStyle={{
                            border: "1px solid #1C4CBB",
                        }}
                    />
                    {errors.celular && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.celular}
                        </p>
                    )}
                    <label className="pt-2 text-sm font-medium text-gray-600">
                        Correo electrónico
                    </label>
                    <input
                        value={input.correo}
                        onChange={handleInputChange}
                        name="correo"
                        type={"email"}
                        className="w-full p-2 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                        placeholder="micuenta@gmail.com"
                    />
                    {errors.correo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.correo}
                        </p>
                    )}
                    <>
                        <label className="flex pt-2 text-sm font-medium text-gray-600">
                            Contraseña
                        </label>
                        <div className="relative flex flex-col justify-center">
                            <input
                                value={input.password}
                                onChange={handleInputChange}
                                name="password"
                                type={showPass ? "text" : "password"}
                                maxLength={20}
                                className="w-full p-2 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                                placeholder="contraseña"
                            />
                            <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </span>
                            {errors.password && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password}
                                </p>
                            )}
                        </div>
                    </>

                    <>
                        <label className="flex pt-2 text-sm font-medium text-gray-600">
                            Repetir contraseña
                        </label>
                        <div className="relative flex flex-col justify-center">
                            <input
                                value={input.password2}
                                onChange={handleInputChange}
                                name="password2"
                                type={showPass ? "text" : "password"}
                                maxLength={20}
                                className="w-full p-2 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                                placeholder="repetir contraseña"
                            />
                            <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                {showPass ? (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                            />
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                            />
                                        </svg>
                                    </>
                                ) : (
                                    <>
                                        <svg
                                            className={`w-6 h-6 mx-2 cursor-pointer`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() => {
                                                setShowPass(!showPass);
                                            }}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                            />
                                        </svg>
                                    </>
                                )}
                            </span>
                            {errors.password2 && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.password2}
                                </p>
                            )}
                        </div>
                    </>

                    {disponible !== "" && (
                        <p
                            className={`${
                                disponible === "Si"
                                    ? "text-red-600"
                                    : "text-green-600"
                            } text-xs  font-semibold pt-4`}
                        >
                            {disponible === "No"
                                ? "URL disponible"
                                : "URL no disponible"}
                        </p>
                    )}
                    <label className="flex pt-2 text-sm font-medium text-gray-600">
                        Url de casa o empresa
                    </label>
                    <div className="relative flex items-center">
                        <input
                            value={input.grupo_trabajo}
                            onChange={handleInputChange}
                            name="grupo_trabajo"
                            type={"text"}
                            maxLength={20}
                            className="w-full p-2 mt-1 border border-[#1C4CBB] rounded-lg placeholder:italic"
                            placeholder="dominio"
                        />
                        <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                            .blustay.io
                        </span>
                    </div>
                    {errors.grupo_trabajo && (
                        <p className="my-1 text-sm font-bold text-red-500 text-md">
                            {errors.grupo_trabajo}
                        </p>
                    )}
                    <span
                        className={`${
                            !input.grupo_trabajo ? "opacity-0" : "opacity-100"
                        } transition-opacity ease-in-out duration-1000 pt-2 flex text-sm ml-1 text-gray-600`}
                    >
                        Tu dominio final será:{" "}
                        {`https://${input.grupo_trabajo}.blustay.io`}
                    </span>
                    <span
                        className={`${
                            !input.grupo_trabajo ? "opacity-0" : "opacity-100"
                        } transition-opacity ease-in-out duration-1000  flex text-sm ml-1 text-[#1C4CBB]`}
                    >
                        Puedes usar ("a-z","0-9" y "-")
                    </span>
                </div>
                <div className="flex flex-col gap-4 pb-6 mt-3">
                    <button
                        onClick={() => handleRegister()}
                        className="w-full p-3 px-5 mx-auto text-white border  rounded-lg bg-[#029BFC] md:w-3/5  hover:scale-105"
                    >
                        Comenzar
                    </button>
                    <span className="max-w-md text-xs text-center text-[#4D4D4D]">
                        Si ya te has registrado, ingresa a la url de tu
                        alojamiento para continuar con tu login (Podrás
                        encontrarla revisando tu bandeja de email){" "}
                    </span>
                </div>
            </div>
        </>
    );
};

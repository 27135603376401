import { SetStateAction, Dispatch, useState, useEffect } from "react";
import { Chat, UsersChat } from "./ChatPropietario";
import { Mensajes } from "../../dashboardClientes/chat/Mensajes";
import { io, Socket } from "socket.io-client";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useParams } from "react-router-dom";
import ModalReserva from "./ModalReserva/ModalReserva";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { set_landing_house } from "../../../redux/reducers/landingReducer";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { ModalTiempoRespuesta } from "./ModalTiempoRespuesta";

interface Props {
    chat: Chat | null;
    setChat: Dispatch<SetStateAction<Chat | null>>;
    getChats: () => void;
}

export interface opcion {
    id_submenu_bots: number;
    opcion: string;
    orden: number;
}

export interface Mensaje {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    mensaje: string;
    imagen_bot: string;
    opciones: opcion[];
    fecha_registro: string;
    leido: string;
    enviado: string;
    time_last_message: string;
    pedido: Pedido;
    id_cliente: number;
    response_bot: string;
}

export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    adultos: string;
    ninos: string;
    oferta: string;
    expiracion: string;
}

export const ChatBody = ({ chat, setChat, getChats }: Props) => {
    const [conversation, setConversation] = useState<Mensaje[] | null>([]);
    const [modalReserva, setModalReserva] = useState(false);
    const [modalVisibleTiempoRespuesta, setModalVisibleTiempoRespuesta] =
        useState(false);
    const [message, setMessage] = useState("");
    const [socket, setSocket] = useState<Socket | null>(null);
    const usuario_logueado = useAppSelector((state) => state.usuario_logeado);
    const socketServer = process.env.REACT_APP_PROXY_ARCHIVOS || "";
    const dispatch = useAppDispatch();
    const [tituloChat, setTituloChat] = useState(chat?.title ?? "");
    const [editarTitulo, setEditarTitulo] = useState(false);
    const casa = useAppSelector((state) => state.landing_house);

    function findChatObjectByType(
        array: UsersChat[],
        type: string
    ): UsersChat | undefined {
        return array.find((item) => item.tipo === type);
    }

    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });
            console.log(data);
            dispatch(set_landing_house(data));
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    useEffect(() => {
        if (
            usuario_logueado.tipo_usuario === 3 ||
            usuario_logueado.tipo_usuario === 4
        ) {
            getHouseFromTenant();
        }
    }, [usuario_logueado]);

    const getConversation = async () => {
        if (chat) {
            if (!socket) {
                setSocket(
                    io(socketServer, {
                        auth: {
                            token: JSON.stringify({
                                room_id: chat.id,
                                user_id: usuario_logueado.id,
                            }),
                        },
                    })
                );
            }

            const response = await fetchAxiosToken({
                url: `/chat_mensajes/getAll/${chat.id}`,
            });

            setConversation(response.result);
        }
    };

    const actualizarVisto = async () => {
        if (chat) {
            try {
                const response = await fetchAxiosToken({
                    url: `/chat_mensajes/updateSeen/${chat.id}`,
                    method: "put",
                    body: {
                        id_usuario: usuario_logueado.id,
                    },
                });
                getChats();

                socket?.emit("send-notificacion-leida", {
                    id_cliente: chat.id_cliente,
                    id_casa: casa.house.id,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };
    const actualizarTitulo = async () => {
        if (chat) {
            try {
                const response = await fetchAxiosToken({
                    url: `/chat/updateTittle/${chat.id}`,
                    method: "put",
                    body: {
                        titulo: tituloChat,
                    },
                });
                console.log(response);
                getChats();
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        setTituloChat(chat?.title ?? "");
        getConversation();
        actualizarVisto();
        return () => {
            socket?.emit("leaveRoom", {
                chatroomId: chat?.id,
            });
            setConversation([]);
        };
    }, [chat, socket]);

    useEffect(() => {
        if (socket && chat) {
            socket.emit("joinRoom", {
                chatroomId: chat.id,
            });
        }
    }, [socket, chat]);

    useEffect(() => {
        if (socket) {
            socket.on(
                "msg-recieve",
                ({
                    mensaje,
                    imagen_bot,
                    opciones,
                    chatroomId,
                    id_mensaje,
                    id_usuario,
                    usuario,
                    tipo_mensaje,
                    leido,
                    fecha_registro,
                    time_last_message,
                    pedido,
                    id_cliente,
                    response_bot,
                }) => {
                    setConversation((prev) => [
                        ...prev!,
                        {
                            fecha_registro,

                            id_chat: chatroomId,
                            id: id_mensaje,
                            id_usuario,
                            leido,
                            mensaje,
                            imagen_bot,
                            opciones,
                            tipo: tipo_mensaje,
                            time_last_message: time_last_message,
                            pedido,
                            enviado: "Si",
                            id_cliente,
                            response_bot,
                        },
                    ]);
                }
            );

            socket.on("chat-recieve", ({}) => {
                console.log("chat");
                getChats();
            });
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            socket.on("users-online", (text) => {
                console.log(text);
            });
            socket.on("users-offline", (text) => {
                console.log(text);
            });
        }
    }, [socket]);

    const handleGoBack = () => {
        setChat(null);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleNewMessage();
            setMessage("");
        }
    };

    const handleNewMessage = async (file?: File) => {
        let new_message;
        if (file) {
            const tipoParts = file.name.split(".");
            const tipo = tipoParts[tipoParts.length - 1];
            new_message = new FormData();
            new_message.append("id_chat", `${chat?.id}`);
            new_message.append("id_usuario", usuario_logueado.id);
            new_message.append("tipo", tipo);
            new_message.append("archivo_mensaje", file);
        } else {
            if (message.trim() === "") {
                return;
            }

            new_message = {
                id_chat: chat?.id,
                id_usuario: usuario_logueado.id,
                tipo: "texto",
                mensaje: message,
            };
        }
        const res = await fetchAxiosToken({
            url: `/chat_mensajes/`,
            method: "post",
            body: new_message,
        });
        const { data } = res;

        if (res.message === "registro exitoso") {
            actualizarVisto();
            socket?.emit("send-msg", {
                id_usuario: Number(data.id_usuario),
                fecha_registro: data.fecha_registro,
                id_mensaje: Number(data.id),
                leido: data.leido,
                chatroomId: Number(data.id_chat),
                mensaje: data.mensaje,
                tipo_mensaje: data.tipo,
                time_last_message: data.time_last_message,
            });
            setMessage("");
        }
    };

    if (!chat || !conversation) {
        return (
            <div className="border-r-2 border-[#CCCCCC] rounded-r-lg border-y-2 w-full h-[85vh] hidden lg:block text-center font-medium pt-4">
                Selecciona un chat para empezar la conversación
            </div>
        );
    }

    return (
        <div
            className={`${
                !chat ? "hidden" : ""
            } flex flex-col border-r-2 border-[#CCCCCC] rounded-r-lg border-y-2 w-full h-[85vh]`}>
            {modalReserva && socket && (
                <ModalReserva
                    modalVisible={modalReserva}
                    setModalVisible={setModalReserva}
                    toggleButton={false}
                    socket={socket}
                    datosPromo={{
                        id_chat: chat?.id,
                        id_usuario: usuario_logueado.id,
                        id_cliente:
                            findChatObjectByType(chat?.usersChat, "Cliente")
                                ?.cliente !== ""
                                ? 0
                                : findChatObjectByType(
                                      chat?.usersChat,
                                      "Cliente"
                                  )?.usuario.id || 0,
                    }}
                />
            )}

            {modalVisibleTiempoRespuesta && (
                <ModalTiempoRespuesta
                    modalVisibleTiempoRespuesta={modalVisibleTiempoRespuesta}
                    setModalVisibleTiempoRespuesta={
                        setModalVisibleTiempoRespuesta
                    }
                />
            )}
            <div className="text-center flex flex-col shadow-sm border-b p-[0.3rem] w-full">
                <div className="flex justify-between px-2 pt-2">
                    <button
                        className="flex items-center hover:underline"
                        onClick={() => handleGoBack()}>
                        <svg
                            className="h-4 w-4 mt-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                        Regresar
                    </button>
                    <span
                        className={`${
                            chat.estado === "Abierto"
                                ? "text-green-500"
                                : "text-red-500"
                        }`}>
                        Chat {chat.estado}
                    </span>
                </div>
                <div className="flex flex-col ">
                    <div className="flex items-center justify-between gap-2 px-2 pb-2 mt-2">
                        {/* <div><p></p></div> */}
                        <div className="flex gap-1">
                            <div className="flex items-center">
                                <p className="mr-2 font-bold">
                                    {editarTitulo ? (
                                        <input
                                            onChange={(e) =>
                                                setTituloChat(e.target.value)
                                            }
                                            value={tituloChat}
                                            type="string"
                                            className="w-full pl-1 border rounded-md"
                                        />
                                    ) : (
                                        <h2 className="mx-1 mt-1 font-medium lg:mt-0">
                                            {tituloChat}
                                        </h2>
                                    )}{" "}
                                </p>
                                {editarTitulo ? (
                                    <button
                                        onClick={() => (
                                            actualizarTitulo(),
                                            setEditarTitulo((prev) => !prev)
                                        )}
                                        title="Editar precio"
                                        className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-[#E6E6E6] hover:opacity-100 mx-1">
                                        <svg
                                            className="w-3 h-3 text-blue-700"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M5 13l4 4L19 7"
                                            />
                                        </svg>
                                    </button>
                                ) : (
                                    <button
                                        onClick={() =>
                                            setEditarTitulo((prev) => !prev)
                                        }
                                        title="Editar precio"
                                        className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-[#E6E6E6] hover:opacity-100 mr-2">
                                        <svg
                                            className="w-3 h-3"
                                            id="Capa_2"
                                            data-name="Capa 2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 100 99.97">
                                            <g id="Testimonios">
                                                <g>
                                                    <path d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z" />
                                                    <path d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z" />
                                                    <path d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className="relative flex items-center justify-center">
                            <p
                                onClick={() => setModalReserva(true)}
                                className="px-2 py-1 text-white bg-[#1C4CBB] rounded-md cursor-pointer hover:scale-105 2xl:text-base text-sm">
                                Crear propuesta{" "}
                            </p>
                            <BotonInformacion text="Envia una oferta a tu cliente, solicitando que realize la reserva!" />
                        </div>
                    </div>
                    <div className="flex gap-2 px-2 pb-2 text-left text-sm">
                        <div>
                            <b>Tiempo de respuesta:</b>{" "}
                            {casa.house.tiempo_respuesta}{" "}
                        </div>

                        <button
                            onClick={() =>
                                setModalVisibleTiempoRespuesta((prev) => !prev)
                            }
                            title="Editar Tiempo de respuesta"
                            className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-[#E6E6E6] hover:opacity-100 mr-2">
                            <svg
                                className="w-3 h-3"
                                id="Capa_2"
                                data-name="Capa 2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 99.97">
                                <g id="Testimonios">
                                    <g>
                                        <path d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z" />
                                        <path d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z" />
                                        <path d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-between h-full overflow-hidden">
                <Mensajes
                    mensajes={conversation}
                    avatar={
                        findChatObjectByType(chat?.usersChat, "Cliente")
                            ?.cliente === ""
                            ? findChatObjectByType(chat?.usersChat, "Cliente")
                                  ?.usuario.avatar || ""
                            : "sinImagen.png"
                    }
                    chat={chat}
                />
                <form
                    className="absolute left-0 items-end right-0 bottom-0 bg-white flex rounded-lg border-[#CCCCCC] w-[95%] px-3 mx-auto mb-3 border-2"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleNewMessage();
                    }}>
                    <div className="flex items-center w-full">
                        <textarea
                            onKeyDown={handleKeyDown}
                            maxLength={256}
                            name="message"
                            className="block w-full px-1 py-4 bg-transparent outline-none resize-none h-14 max-h-20 placeholder:text-[#808080]"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            autoComplete="off"
                            autoFocus
                            placeholder="Escribe un mensaje..."
                        />
                    </div>
                    <div className="flex items-center content-center p-2">
                        <div className="relative">
                            <input
                                className="absolute w-4 h-4 opacity-0 cursor-pointer right-3 file:cursor-pointer"
                                type="file"
                                accept=".xlsx, .xls, .csv, .doc, .docx, application/msword, text/plain, application/pdf, image/*"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        handleNewMessage(e.target.files[0]);
                                    }
                                }}
                            />

                            <svg
                                className="h-6 w-6 mx-3  hover:scale-105 active:translate-y-0.5 cursor-pointer"
                                id="Capa_2"
                                data-name="Capa 2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 90.69">
                                <g id="Testimonios">
                                    <path
                                        fill="#808080"
                                        d="m26.7,90.69c-13.27.01-24.68-9.88-26.43-23-1.16-8.73,1.36-16.35,7.53-22.59,12.35-12.48,24.81-24.85,37.22-37.27,1.38-1.38,2.96-2.05,4.89-1.49,1.71.49,2.77,1.69,3.15,3.41.39,1.74-.23,3.19-1.47,4.42-4.24,4.23-8.48,8.47-12.71,12.71-8.11,8.1-16.2,16.22-24.33,24.31-3.77,3.75-5.58,8.22-5.46,13.54.18,7.82,6.37,15.15,14.07,16.5,6.27,1.1,11.71-.53,16.24-5.07,15.99-15.99,31.99-31.98,47.97-47.98,4.75-4.75,4.72-11.73-.02-16.17-4.28-4.02-10.97-3.81-15.37.54-4.53,4.48-9.02,9.01-13.52,13.51-8.33,8.33-16.65,16.66-24.98,24.97-1.19,1.19-1.97,2.53-1.65,4.26.31,1.66,1.29,2.84,2.92,3.34,1.61.5,3.1.21,4.32-1,2.98-2.94,5.95-5.9,8.92-8.86,3.55-3.54,7.11-7.09,10.65-10.65,1.76-1.76,3.51-3.55,5.27-5.31,1.22-1.22,2.64-1.92,4.4-1.56,1.74.35,2.92,1.39,3.48,3.08.58,1.74.13,3.31-1.12,4.6-2.34,2.41-4.74,4.76-7.11,7.14-5.98,5.98-11.91,11.99-17.94,17.91-3.78,3.71-8.38,4.84-13.42,3.3-4.93-1.5-8.04-4.95-9.14-9.99-1.03-4.71.26-8.87,3.66-12.29,8.89-8.94,17.82-17.84,26.73-26.75,4.09-4.09,8.17-8.19,12.27-12.26C69.82,1.9,74.78-.08,80.53,0c10.13.14,18.82,8.5,19.42,18.61.37,6.17-1.62,11.43-5.97,15.79-16.11,16.12-32.22,32.24-48.34,48.34-5.22,5.21-11.8,7.94-18.94,7.95Z"
                                    />
                                </g>
                            </svg>
                        </div>
                        <div className="flex-1 ml-3">
                            <button type="submit">
                                <span className="flex items-center justify-center align-text-bottom">
                                    <svg
                                        className="h-6 w-6 mt-1   hover:scale-105 active:translate-y-0.5"
                                        id="Capa_2"
                                        data-name="Capa 2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 100 99.96">
                                        <g id="Testimonios">
                                            <path
                                                fill="#808080"
                                                d="m.02,93.62c.05-1.41.66-3.2,1.11-5.01,2.44-9.83,4.93-19.64,7.35-29.47.4-1.62,1.18-2.53,2.9-2.7,4.15-.41,8.3-.92,12.45-1.38,5.52-.62,11.04-1.24,16.56-1.85,2.29-.25,4.57-.49,6.86-.73,2.02-.21,3.09-1.64,2.48-3.32-.39-1.08-1.27-1.49-2.31-1.6-5.98-.66-11.96-1.3-17.93-1.96-5.41-.6-10.83-1.22-16.24-1.83-.67-.08-1.33-.18-2-.22-1.59-.09-2.36-1-2.72-2.49-1.48-6.05-3.01-12.09-4.51-18.14C2.71,17.65,1.32,12.41.15,7.13-.71,3.23,2.17-.16,5.94,0c1.01.05,1.93.42,2.82.86,10.28,5.02,20.56,10.04,30.84,15.06,17.79,8.68,35.58,17.36,53.38,26.04.86.42,1.73.82,2.59,1.24,2.83,1.39,4.44,3.65,4.44,6.81,0,3.16-1.63,5.41-4.46,6.79-8.37,4.08-16.75,8.16-25.12,12.25-15.31,7.47-30.62,14.95-45.93,22.42-5.35,2.61-10.69,5.24-16.05,7.81C4.27,101.29,0,98.63.02,93.62Z"
                                            />
                                        </g>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

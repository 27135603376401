import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { Capacidad } from "../design1/Design1";
import { Habitacion } from "../../pages/onboardingPropietario/habitaciones/Habitaciones";
import { useParams } from "react-router-dom";

export interface CapacidadHotel {
    personas: string;
    mascotas: string;
    camas: string;
    banos: string;
}

interface Props {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    banos: string;
    personas: string;
    mascotas: string;
    camas: string;
    id_habitacion: number;
}

export const ModalEditCapacidadesHotel = ({
    modalVisible,
    setModalVisible,
    banos,
    personas,
    mascotas,
    camas,
    id_habitacion,
}: Props) => {
    const dispatchDominio = useDispatchDominio();
    const [saving, setSaving] = useState<boolean>(false);
    const [input, setInput] = useState<CapacidadHotel>({
        banos: banos,
        mascotas: mascotas,
        personas: personas,
        camas: camas,
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        setSaving(true);

        if (Number(input.personas) < 1)
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes escoger un numero de personas válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        else if (Number(input.mascotas) < 0)
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes escoger un numero de mascotas válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        else if (Number(input.camas) < 1)
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes escoger un numero de camas válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        else if (Number(input.banos) < 1)
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Debes escoger un numero de baños válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        else
            try {
                await fetchAxiosToken({
                    url: `/hotel_habitaciones/updateCapacidad/${id_habitacion}`,
                    method: "put",
                    body: input,
                });

                dispatchDominio();
                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizado",
                        text: "capacidades actualizadas",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                    setSaving(false);

                    setModalVisible(false);
                }, 2000);
            } catch (error) {
                console.log(error);
            }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
        >
            <div className="flex flex-col items-center justify-center max-w-lg gap-4 p-4 mx-auto text-gray-600 ">
                <h2 className="2xl:text-4xl text-3xl text-[#1C4CBB] text-center font-medium">
                    Editar capacidades
                </h2>
                <hr className="w-full bg-gray-300" />

                <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Numero de personas
                    </label>
                    <input
                        type={"number"}
                        min={0}
                        name="personas"
                        value={input.personas}
                        onChange={handleInputChange}
                        className="w-full max-w-sm p-2 mx-auto mt-2 text-sm text-black border border-gray-500 rounded-lg 2xl:p-3 2xl:text-base placeholder:italic"
                    />
                </div>
                <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Numero de mascotas
                    </label>
                    <input
                        type={"number"}
                        min={0}
                        name="mascotas"
                        value={input.mascotas}
                        onChange={handleInputChange}
                        className="w-full max-w-sm p-2 mx-auto mt-2 text-sm text-black border border-gray-500 rounded-lg 2xl:p-3 2xl:text-base placeholder:italic"
                    />
                </div>
                <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Numero de camas
                    </label>
                    <input
                        type={"number"}
                        min={0}
                        step={1}
                        name="camas"
                        value={input.camas}
                        onChange={handleInputChange}
                        className="w-full max-w-sm p-2 mx-auto mt-2 text-sm text-black border border-gray-500 rounded-lg 2xl:p-3 2xl:text-base placeholder:italic"
                    />
                </div>
                <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Numero de baños
                    </label>
                    <input
                        type={"number"}
                        min={0}
                        step={0.5}
                        name="banos"
                        value={input.banos}
                        onChange={handleInputChange}
                        className="w-full max-w-sm p-2 mx-auto mt-2 text-sm text-black border border-gray-500 rounded-lg 2xl:p-3 2xl:text-base placeholder:italic"
                    />
                </div>

                <div className="w-10/12 mt-4 2xl:mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="w-full p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm"
                        >
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

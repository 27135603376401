import { createSlice } from "@reduxjs/toolkit";
import { infoCasa } from "../../designs/calendario-reserva/typeCasa";

interface initial_state {
    house: infoCasa;
    checking: boolean;
}

const INITIAL_STATE: initial_state = {
    house: {
        banner: "",
        casa_actividades: [],
        casa_comodidades: [],
        casa_habitaciones: [],
        casa_imagenes: [],
        casa_estadias_minimas: [],
        ciudad: "",
        departamento: "",
        fecha_registro: "",
        habilitado: "",
        id: 0,
        id_propietario: 0,
        latitud: "",
        longitud: "",
        nombre: "",
        pagina: "bienvenida",
        disponibilidades: {
            arrayDias: [],
            arrayFechas: [],
        },
        pedidos: {
            arrayFechasPedido: [],
        },
        descuentos_tiempos: [],
        personas_adicionales: [],
        precios_variables: [],
        servicios_adicionales: [],
        hotel_habitaciones: [],
        resenas: [],
        pais: "",
        precio_base: "0",
        tema: "",
        terminado: "No",
        tipo_moneda: "",
        whatsapp: "",
        personas: 0,
        banos: "0",
        habitaciones: "0",
        mascotas: 0,
        checking: true,
        estadia_maxima: 2,
        estadia_minima: 3,
        clave: "",
        check_in: "",
        check_out: "",
        garantia: "0",
        is_mercado_pago: "No",
        mensaje_bienvenida: "",
        tipo_garantia: "",
        plan: 0,
        precios_variables_semanas: [],
        descripcion_1: "",
        descripcion_2: "",
        titulo_descripcion_1: "",
        titulo_descripcion_2: "",
        tipo_descripcion: "",
        imagen_descripcion: "",
        video_descripcion: "",
        tiempo_respuesta: "",
        dominio: "",
        dias_restantes: 0,
        prueba: "No",
        direccion: "",
        tiktok: "",
        facebook: "",
        instagram: "",
        id_airbnb: "",
        id_booking: "",
        id_tripadvisor: "",
        token_google_calendar: "",
        isChatbot: "",
        tipo_usuario: 0,
    },
    checking: true,
};

export const landing_house = createSlice({
    name: "landing_house",
    initialState: INITIAL_STATE,
    reducers: {
        set_landing_house: (state, action) => {
            console.log(action.payload);
            return {
                house: action.payload,
                checking: false,
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { set_landing_house } = landing_house.actions;

export default landing_house.reducer;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import { ButtonSiguiente } from "./ButtonSiguiente";
import imagenes from "../../img/onboarding/imagenes.jpg";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { ReactSortable } from "react-sortablejs";
import Swal from "sweetalert2";
import ModalNewEspacio from "../../designs/modalEdit/ModalNewEspacio";
import ModalEditEspacio from "../../designs/modalEdit/ModalEditEspacio";
interface customFile extends File {
    imagen?: string;
    id?: number;
    isPreview?: boolean;
    urlImage?: string | null;
}

interface selectedFile {
    banner: File | null;
    imagenes_secundarias: any[];
}

export interface Espacios {
    id: number;
    id_casa: number;
    nombre: string;
    orden: number;
    imagenes: EspaciosImagenes[];
}

export interface EspaciosImagenes {
    id: number;
    id_casa: number;
    imagen: string;
    id_espacio_imagen: number;
    orden: number;
}

export const ImagenesHotel = () => {
    const [selectedFiles, setSelectedFiles] = useState<selectedFile>({
        banner: null,
        imagenes_secundarias: [],
    });

    const [showBannerPreview, setShowBannerPreview] = useState<boolean>(false);
    const [showSecundariasPreview, setShowSecundariasPreview] =
        useState<boolean>(false);
    const [bannerPreview, setBannerPreview] = useState<string | undefined>(
        undefined
    );
    const [secundariasPreview, setSecundariasPreview] = useState<
        (
            | {
                  id: string;
                  imagen: string;
                  isPreview: boolean;
                  urlImage: string | null;
              }
            | customFile
        )[]
    >([]);
    const { id: id_casa_redux, banner } = useAppSelector(
        (state) => state.landing_house.house
    );
    const id_casa = id_casa_redux;
    const [espacios, setEspacios] = useState<Espacios[]>([]);
    const [espaciosId, setEspaciosId] = useState<Espacios | null>(null);
    const [espacioNew, setEspacioNew] = useState<boolean>(false);
    const dispatchDominio = useDispatchDominio(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        cargarEtiquetas();
    }, [espacioNew, espaciosId, id_casa]);

    console.log(espacioNew);
    const cargarEtiquetas = async () => {
        const data = await fetchAxiosToken({
            url: `/espacios_imagenes/getAll/${id_casa}`,
            method: "get",
        });

        console.log("cargarEtiquetas", data);
        setEspacios(data);
    };

    const getImages = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${id_casa}`,
            });
            setSelectedFiles({
                banner: data.banner,
                imagenes_secundarias: data.casa_imagenes,
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id_casa > 0) getImages();
    }, [banner, id_casa]);

    const cambioDeEspacios = async () => {
        let array_orden: number[] = [];
        espacios.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);

        const data = await fetchAxiosToken({
            url: `/espacios_imagenes/updateOrden`,
            method: "put",
            body: { array_espacios: array_orden },
        });
    };

    useEffect(() => {
        espacios.length && cambioDeEspacios();
    }, [espacios]);

    console.log(secundariasPreview);

    const handleDeleteEspacio = async (id: number) => {
        try {
            const json = await fetchAxiosToken({
                url: `/espacios_imagenes/remove/${id}`,
                method: "delete",
            });
            console.log(json);

            cargarEtiquetas();
        } catch (error: any) {
            console.log(error);
            if (
                error.response?.data?.error ===
                "No se puede eliminar el espacio porque contiene imagenes"
            ) {
                Swal.fire({
                    title: "No se puede eliminar el espacio porque contiene imagenes",
                    icon: "warning",

                    confirmButtonText: "Ok",
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                });
                return;
            }
        }
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.name === "banner") {
            const imagePreview = URL.createObjectURL(e.target.files[0]);

            setSelectedFiles({
                ...selectedFiles,
                banner: e.target.files[0],
            });

            setBannerPreview(imagePreview);

            setShowBannerPreview(true);
        } else if (e.target.files && e.target.name === "secundarias") {
            let all_images = [];
            let all_previews = [];

            for (let i = 0; i < e.target.files.length; i++) {
                const urlImage = URL.createObjectURL(e.target.files[i]);

                all_images.push({
                    imagen: e.target.files[i],
                    id: `${i}${e.target.files.item(0)?.name}`,
                    isPreview: true,
                    urlImage: urlImage,
                });

                all_previews.push({
                    id: `${i}${e.target.files.item(0)?.name}`,
                    imagen: urlImage,
                    isPreview: true,
                    urlImage: null,
                });
            }

            setSelectedFiles({
                ...selectedFiles,
                imagenes_secundarias: [
                    ...selectedFiles.imagenes_secundarias,
                    ...all_images,
                ],
            });
            setSecundariasPreview([
                ...selectedFiles.imagenes_secundarias,
                ...all_previews,
            ]);
            setShowSecundariasPreview(true);
        }
    };

    const updateImagenes = async () => {
        const formBanner = new FormData();
        const formSecundarias = new FormData();

        formBanner.append("banner_casa", selectedFiles.banner!);

        formSecundarias.append("id_casa", `${id_casa}`);
        selectedFiles.imagenes_secundarias.map((imagen) =>
            formSecundarias.append("imagenes_casa", imagen.imagen)
        );

        try {
            if (showSecundariasPreview) {
                const data = await fetchAxiosToken({
                    url: `/casa_imagenes/`,
                    method: "post",
                    body: formSecundarias,
                });
            }

            if (showBannerPreview) {
                const dataBanner = await fetchAxiosToken({
                    url: `/casa/putBanner/${id_casa}`,
                    method: "put",
                    body: formBanner,
                });
            }

            dispatchDominio();
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async () => {
        try {
            await updateImagenes();
            const pagina = await fetchAxiosToken({
                url: `/casa/putPagina/${id_casa}`,
                method: "put",
                body: {
                    pagina: "comodidades",
                },
            });

            navigate(`/dashboard-hotel/onboarding/comodidades`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            {espacioNew && (
                <ModalNewEspacio
                    setEspacioNew={setEspacioNew}
                    casaId={id_casa}
                />
            )}
            {espaciosId && (
                <ModalEditEspacio
                    setEspaciosEdit={setEspaciosId}
                    casaId={id_casa}
                    espacio={espaciosId}
                />
            )}
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={imagenes}
                        alt="imagenes"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Ahora sube las imágenes de los espacios principales
                            de tu hotel
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-0 flex flex-col justify-center p-6 text-center text-black bg-white lg:absolute lg:w-1/2 lg:h-screen">
                <div className=" lg:overflow-x-auto">
                    <div className="relative ">
                        <ButtonRegresar
                            url={`${"/dashboard-hotel/onboarding/tema"}`}
                            className="absolute top-1 lg:top-6 left-1 lg:left-10"
                            arrowColor="black"
                            textColor="black"
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center pt-20 ">
                        <p className="my-2 text-lg font-medium 2xl:text-xl">
                            Sube tu imagen de portada
                        </p>
                        <div className="cursor-pointer mt-4 relative flex justify-center w-[350px] h-52 border-2 border-dashed border-[#E9E9E9]  rounded-lg hover:cursor-pointer ">
                            {!selectedFiles.banner ? (
                                <>
                                    <div className="absolute">
                                        <div className="flex flex-col w-full mx-auto ">
                                            <div className="relative top-4 ">
                                                <span className="block mt-16 text-base font-semibold text-center ">
                                                    Agrega o arrastra una foto
                                                    aquí
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="banner"
                                        className="w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                        onChange={handleChangeImage}
                                    />
                                </>
                            ) : !showBannerPreview ? (
                                <>
                                    <img
                                        className="object-cover w-full rounded-lg"
                                        src={`${process.env.REACT_APP_PROXY}/casa/getImage/${selectedFiles.banner}`}
                                        alt="banner del alojamiento"
                                    />
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="banner"
                                        className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                        onChange={handleChangeImage}
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        className="object-cover w-full rounded-lg"
                                        src={bannerPreview}
                                        alt="banner del alojamiento"
                                    />
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="banner"
                                        className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                        onChange={handleChangeImage}
                                    />
                                </>
                            )}
                        </div>

                        <div className="flex flex-col items-center justify-center mt-5">
                            <p className="mt-10 text-lg font-medium">
                                Ahora sube las imágenes de los espacios
                                principales de tu hotel
                            </p>
                            <p className="mt-2 text-xs font-medium text-gray-400">
                                Las imagenes de las habitaciones las colocaras
                                mas adelante{" "}
                                <span className="text-red-500">*</span>
                            </p>
                            <div className="w-full">
                                <div>
                                    <p
                                        onClick={() => setEspacioNew(true)}
                                        className="flex items-center p-2 mx-auto my-6 text-center border rounded-lg cursor-pointer border-blue-blueFrom text-blue-blueFrom w-36 hover:scale-105">
                                        {" "}
                                        + Nuevo espacio
                                    </p>
                                    <div>
                                        <p className="text-xl font-semibold">
                                            Espacios:
                                        </p>
                                        <ReactSortable
                                            handle=".handle"
                                            className="gap-2 mt-4"
                                            group="espacios"
                                            animation={200}
                                            delay={2}
                                            list={espacios}
                                            setList={setEspacios}>
                                            {espacios?.map((espacio, index) => (
                                                <div
                                                    key={index}
                                                    className="relative p-6 mt-2 border border-gray-200 rounded-md handle">
                                                    <p
                                                        onClick={() =>
                                                            setEspaciosId(
                                                                espacio
                                                            )
                                                        }
                                                        className="cursor-pointer ">
                                                        {espacio.nombre}
                                                    </p>
                                                    <div
                                                        onClick={() =>
                                                            handleDeleteEspacio(
                                                                espacio.id
                                                            )
                                                        }
                                                        className="absolute z-10 rounded-md cursor-pointer top-1 right-1 bg-slate-200 hover:scale-105">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />{" "}
                                                            <path d="M10 10l4 4m0 -4l-4 4" />
                                                        </svg>
                                                    </div>
                                                    <div className="absolute z-10 rounded-md cursor-move bottom-1 right-1 bg-slate-200 hover:scale-105 ">
                                                        <svg
                                                            className="w-6 h-6 text-blue-500"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round">
                                                            {" "}
                                                            <polyline points="5 9 2 12 5 15" />{" "}
                                                            <polyline points="9 5 12 2 15 5" />{" "}
                                                            <polyline points="15 19 12 22 9 19" />{" "}
                                                            <polyline points="19 9 22 12 19 15" />{" "}
                                                            <line
                                                                x1="2"
                                                                y1="12"
                                                                x2="22"
                                                                y2="12"
                                                            />{" "}
                                                            <line
                                                                x1="12"
                                                                y1="2"
                                                                x2="12"
                                                                y2="22"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            ))}
                                        </ReactSortable>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[350px]">
                                <ButtonSiguiente
                                    onSubmit={() => handleSubmit()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

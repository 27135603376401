import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import { useAppSelector } from "../../hooks/hooks";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { CapacidadesHotel } from "../capacidades/CapacidadesHotel";
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale";
import { formatearNumero } from "../../components/helpers/formatearNumero";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";

import { ModalHabitacion } from "../modalEdit/ModalHabitacion";
import Spinner from "../../components/helpers/Spinner";

interface Habitacion {
    id: number;
    nombre: string;
    descripcion: string;
    banner: string;
    precio_base: string;
    personas: string;
    mascotas: string;
    banos: string;
    camas: string;
    landing: number;
    check_in: string;
    check_out: string;
    id_hotel: number;
    hotel_habitaciones_imagenes: any[];
}

interface huespedesProps {
    name: "mascotas" | "ninos" | "adultos";
    action: "sumar" | "restar";
}

const LandingHabitaciones = () => {
    const { tipo_moneda, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );

    const [huespedes, setHuespedes] = useState({
        adultos: 1,
        ninos: 0,
        mascotas: 0,
    });

    const [loading, setLoading] = useState<boolean>(false);

    const [fechaQuery, setFechaQuery] = useState<{
        fecha_ingreso: string | undefined;
        fecha_salida: string | undefined;
    }>({
        fecha_ingreso: undefined,
        fecha_salida: undefined,
    });

    const [habitaciones, setHabitaciones] = useState<Habitacion[]>();
    const [habitacionSelected, setHabitacionSelected] = useState<Habitacion>();
    const [modalHabitacion, setModalHabitacion] = useState<boolean>(false);

    const { price_to_currency } = usePriceToSelectedCurrency();
    useEffect(() => {
        getHabitaciones();
    }, [casa_id]);

    const getHabitaciones = async () => {
        const { fecha_ingreso, fecha_salida } = fechaQuery;
        setLoading(true);
        let query_fechas = "";

        // Verificar que las fechas no estén undefined
        if (fecha_ingreso && fecha_salida) {
            // Convertir fechas al formato adecuado (por ejemplo, ISO)
            const fechaInicio = fecha_ingreso.split("/").reverse().join("-");
            // De 17/03/2025 a 2025-03-17
            const fechaSalida = fecha_salida.split("/").reverse().join("-");
            // De 17/03/2025 a 2025-03-17

            // Verificar que la fecha de ingreso sea menor que la de salida
            if (new Date(fechaInicio) >= new Date(fechaSalida)) {
                console.error(
                    "La fecha de ingreso debe ser anterior a la fecha de salida."
                );
                return;
            }

            // Construir el query string con las fechas
            query_fechas = `&fecha_inicio=${fechaInicio}&fecha_final=${fechaSalida}`;
        }

        const total_huespedes = huespedes.adultos + huespedes.ninos;

        try {
            const response = await fetchAxiosToken({
                url: `/hotel_habitaciones/getAllByHotel/${casa_id}?query=Publicado&personas=${total_huespedes}${query_fechas}`,
                method: "get",
            });

            setHabitaciones(response);
        } catch (error) {
            console.error("Error al obtener habitaciones:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputClick = ({ name, action }: huespedesProps) => {
        if (action === "sumar") {
            switch (name) {
                case "adultos":
                    /*  if (TOTAL_HUESPEDES === infoCasa.personas) return; */
                    setHuespedes({
                        ...huespedes,
                        [name]: huespedes[name] + 1,
                    });
                    break;
                case "ninos":
                    /*   if (TOTAL_HUESPEDES === infoCasa.personas) return; */
                    setHuespedes({
                        ...huespedes,
                        [name]: huespedes[name] + 1,
                    });
                    break;
                /*    case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.min(
                            infoCasa.mascotas,
                            huespedes[name] + 1
                        ),
                    });
                    break; */
                default:
                    break;
            }
        } else {
            switch (name) {
                case "adultos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(1, huespedes[name] - 1),
                    });
                    break;
                case "ninos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                default:
                    break;
            }
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setHuespedes({
            ...huespedes,
            [event.target.name]: inputValue !== "" ? Number(inputValue) : "",
        });
    };

    const handleInputChangeFecha = (name: string) => (date: Date | null) => {
        if (date) {
            const formattedDate = `${`${date.getDate()}`.padStart(2, "0")}/${`${
                date.getMonth() + 1
            }`.padStart(2, "0")}/${date.getFullYear()}`;

            setFechaQuery({
                ...fechaQuery,
                [name]: formattedDate, // Guardar en formato "dd/MM/yyyy"
            });
        } else {
            setFechaQuery({
                ...fechaQuery,
                [name]: "", // Si no hay fecha, guardar vacío
            });
        }
    };

    const parseDate = (dateStr: string) => {
        if (!dateStr) return null;
        const [day, month, year] = dateStr.split("/").map(Number);
        return new Date(year, month - 1, day);
    };

    const handleClick = (habitacion: Habitacion) => {
        setModalHabitacion(true);
        setHabitacionSelected(habitacion);
    };

    return (
        <div className="bg-white 2xl:px-[8%] overflow-clip h-full">
            <Navbar />
            {modalHabitacion && (
                <ModalHabitacion
                    modalVisible={modalHabitacion}
                    setModalVisible={setModalHabitacion}
                    id_habitacion={habitacionSelected?.id ?? 0}
                    fecha_ingreso={fechaQuery.fecha_ingreso || ""}
                    fecha_salida={fechaQuery.fecha_salida || ""}
                    total_huespedes={huespedes.adultos + huespedes.ninos}
                />
            )}
            <div className="flex justify-start items-start w-full">
                <div className="w-1/4">
                    <div className="flex flex-col justify-center items-center w-full mt-8 gap-7">
                        <div className="flex flex-col w-1/2 mx-auto gap-1 text-left">
                            <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                Fecha Ingreso
                            </label>
                            <div className=" w-full grid-flow-col gap-2">
                                <DatePicker
                                    className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                    placeholderText="Fecha de ingreso"
                                    locale={es}
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    value={fechaQuery.fecha_ingreso}
                                    selected={
                                        fechaQuery.fecha_ingreso
                                            ? parseDate(
                                                  fechaQuery.fecha_ingreso
                                              )
                                            : null
                                    }
                                    onChange={handleInputChangeFecha(
                                        "fecha_ingreso"
                                    )}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col w-1/2 mx-auto gap-1 text-left">
                            <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                Fecha Salida
                            </label>
                            <div className=" w-full grid-flow-col gap-2">
                                <DatePicker
                                    className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                    placeholderText="Fecha de salida"
                                    locale={es}
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                    value={fechaQuery.fecha_salida}
                                    selected={
                                        fechaQuery.fecha_salida
                                            ? parseDate(fechaQuery.fecha_salida)
                                            : null
                                    }
                                    onChange={handleInputChangeFecha(
                                        "fecha_salida"
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-1/2 mx-auto gap-1 text-left">
                            <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                Adultos
                            </label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>

                                <input
                                    className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                    type="number"
                                    name="adultos"
                                    value={huespedes.adultos}
                                    onChange={handleInputChange}
                                />
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col w-1/2 mx-auto gap-1 text-left">
                            <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                Niños
                            </label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <input
                                    className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                    type="number"
                                    name="ninos"
                                    value={huespedes.ninos}
                                    onChange={handleInputChange}
                                />
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div
                            onClick={() => getHabitaciones()}
                            className="bg-[#1C4CBB] text-white p-2 rounded-xl hover:scale-105 cursor-pointer w-1/2 mx-auto text-center"
                        >
                            Ver Disponibilidad
                        </div>
                    </div>
                </div>
                <div className=" w-3/4 flex flex-col gap-8 p-4 bg-white border shadow-lg lg:py-10 lg:px-16 h-full">
                    {loading ? (
                        <>
                            <Spinner />
                        </>
                    ) : (
                        habitaciones?.map((habitacion, i) => (
                            <div
                                key={i}
                                className={` w-full h-36 shadow-md cursor-pointer rounded-md flex justify-start px-5 items-center gap-10`}
                                onClick={() => handleClick(habitacion)}
                            >
                                <img
                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${habitacion.banner}`}
                                    alt="habitacion"
                                    className=" w-24 h-24 object-cover rounded-md"
                                />
                                {/* <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black to-transparent"></div> */}
                                <div className=" flex flex-col items-center justify-center">
                                    <p className=" font-bold text-base text-[#1C4CBB]">
                                        {habitacion.nombre}
                                    </p>
                                    <div className="flex flex-col items-center justify-center w-full ">
                                        <CapacidadesHotel
                                            personas={habitacion.personas}
                                            mascotas={habitacion.mascotas}
                                            banos={habitacion.banos}
                                            camas={habitacion.camas}
                                            id_habitacion={habitacion.id}
                                            toggleButton={false}
                                        />
                                    </div>

                                    <div className=" text-lg text-center text-[#1C4CBB] align-middle ">
                                        <p className="font-light select-none">
                                            Desde{" "}
                                            <span className="font-semibold">{`${tipo_moneda} ${formatearNumero(
                                                price_to_currency(
                                                    Number(
                                                        habitacion.precio_base
                                                    )
                                                )
                                            )} `}</span>{" "}
                                            /por noche
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default LandingHabitaciones;

import { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditComforts } from "../modalEdit/ModalEditComforts";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";

import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";

import { ModalGaleriaImagenes } from "../modalEdit/ModalGaleriaImagenes";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import { formatearNumero } from "../../components/helpers/formatearNumero";
interface Props {
    toggleButton: boolean;
    tipo_landing: number;
}

interface Input {
    precio_base: string;
    tipo_moneda: string;
}
const Servicios = ({ toggleButton, tipo_landing }: Props) => {
    const {
        id_propietario,
        banner,
        casa_comodidades,
        casa_imagenes,
        tipo_moneda,
        precio_base,
        estadia_minima,
        id: casa_id,
    } = useAppSelector((state) => state.landing_house.house);
    const dispatchDominio = useDispatchDominio();
    const [modalVisibleComodidades, setModalVisibleComodidades] =
        useState<boolean>(false);
    const [savingPrice, setSavingPrice] = useState<boolean>(false);
    const [editPrice, setEditPrice] = useState<boolean>(false);
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const { price_to_currency } = usePriceToSelectedCurrency();

    const [input, setInput] = useState<Input>({
        precio_base: precio_base,
        tipo_moneda: SELECTED_CURRENCY,
    });

    const [modalImagesVisible, setModalImagesVisible] = useState(false);

    const putPrice = async () => {
        if (input.precio_base === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "El precio no puede estar vacio",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        setSavingPrice(true);
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putPrecio/${casa_id}`,
                method: "put",
                body: {
                    precio_base: input.precio_base,
                    tipo_moneda: input.tipo_moneda,
                },
            });
            const dataCasa = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${casa_id}`,
            });

            dispatchDominio();

            setEditPrice(false);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado",
                text: "Precio actualizado con exito",
                icon: "success",
                confirmButtonText: "Ok",
            });

            setSavingPrice(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    return (
        <div className="flex flex-col lg:flex-row  lg:mx-16 mt-5 lg:mt-14 lg:divide-x-2 lg:divide-[#1c4cbb] ">
            {modalVisibleComodidades && (
                <ModalEditComforts
                    setModalVisibleComodidades={setModalVisibleComodidades}
                    modalVisibleComodidades={modalVisibleComodidades}
                />
            )}

            {modalImagesVisible && (
                <ModalGaleriaImagenes
                    imagen_principal={banner}
                    imagenes={casa_imagenes}
                    modalVisible={modalImagesVisible}
                    setModalVisible={setModalImagesVisible}
                />
            )}
            <div
                className={`flex flex-col items-center w-full  ${
                    tipo_landing !== 8 ? "lg:w-1/2" : ""
                } `}>
                <div className="flex gap-5">
                    <div className=" text-[#0096fc] text-xl md:text-2xl 2xl:text-3xl underline decoration-[#0096fc] underline-offset-8 font-bold">
                        SERVICIOS
                    </div>
                    <div>
                        {toggleButton && (
                            <div
                                onClick={() => setModalVisibleComodidades(true)}
                                title="editar servicios"
                                className="flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100">
                                <svg
                                    className="w-6 h-6 text-blue-500 md:w-8 md:h-8"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Servicios
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 2xl:mt-8 mt-5 ml-14  max-h-[260px] overflow-y-auto">
                    {casa_comodidades.map((servicio) => (
                        <div
                            key={servicio.id}
                            className="flex items-center gap-8 mt-5 ">
                            <img
                                className="object-contain md:w-10 md:h-10 w-7 h-7 "
                                src={
                                    `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/comodidad/getImage/` +
                                    servicio.comodidade.icono
                                }
                                alt=""
                            />
                            <h2 className="font-semibold text-[#666666] text-sm lg:text-sm 2xl:text-base">
                                {servicio.comodidade.nombre}
                            </h2>
                        </div>
                    ))}
                </div>
            </div>
            {tipo_landing !== 8 && (
                <div className="flex flex-col items-center justify-center w-full mt-10 lg:w-1/2 lg:mx-10 lg:mt-0">
                    <div className="flex justify-center item center">
                        <div className="w-full justify-center text-center lg:text-right text-[#1c4cbb] mt-5 lg:mt-0">
                            <p className="mb-1 text-xl font-light ">DESDE</p>
                            <p className="mb-1 text-2xl font-bold md:text-4xl lg:text-5xl 2xl:text-6xl ">
                                {SELECTED_CURRENCY}{" "}
                                {formatearNumero(
                                    price_to_currency(Number(precio_base))
                                )}
                            </p>
                            <p className="mt-1 text-xl font-light lg:text-3xl 2xl:text-4xl ">
                                por noche
                            </p>
                            <p className="mt-1 text-lg lg:text-2xl 2xl:text-3xl xs:mb-8 opacity-60 ">
                                Mínimo {estadia_minima}{" "}
                                {estadia_minima === 1 ? "noche" : "noches"}
                            </p>
                        </div>
                        <div>
                            {toggleButton && (
                                <div
                                    onClick={() => setEditPrice(!editPrice)}
                                    title="editar Precio base"
                                    className="flex flex-col items-center justify-center px-2 py-1 mx-auto my-2 ml-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100">
                                    <svg
                                        className="w-8 h-8 text-[#1C4CBB]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                        <line
                                            x1="13.5"
                                            y1="6.5"
                                            x2="17.5"
                                            y2="10.5"
                                        />
                                    </svg>
                                    <div className="text-xs text-[#1C4CBB] text-center">
                                        Editar Precio
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {editPrice && (
                        <>
                            <div className="w-full mx-auto my-1 text-left">
                                <header className="mb-1  text-[#1C4CBB]  text-base">
                                    Precio base:
                                </header>
                            </div>
                            <div className="w-full mx-auto">
                                {savingPrice ? (
                                    <Spinner />
                                ) : (
                                    <div className="flex mb-3 ">
                                        <input
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    precio_base: e.target.value,
                                                })
                                            }
                                            type="number"
                                            value={input.precio_base}
                                            placeholder="Ej: Casa Bella"
                                            className="w-full mr-2  pl-1 xs:px-4 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                        />
                                        <select
                                            name="tipo_estadia"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    tipo_moneda: e.target.value,
                                                })
                                            }
                                            className="w-full mr-2  pl-1 xs:px-4 rounded-lg py-2 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                            value={input.tipo_moneda}>
                                            <option value="PEN">PEN</option>
                                            <option value="USD">USD</option>
                                        </select>
                                        <div className="flex justify-center ">
                                            <button
                                                onClick={() => putPrice()}
                                                className="p-3 text-white border rounded-lg xs:px-5 bg-[#1C4CBB] hover:scale-105">
                                                Actualizar
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    <div
                        onClick={() => setModalImagesVisible(true)}
                        className="flex justify-center items-center mt-4 2xl:mt-6 bg-[#0096fc] text-white  text-base md:text-lg 2xl:text-2xl py-3 md:py-4 px-12 md:px-16 rounded-lg mx-auto text-center cursor-pointer">
                        GALERÍA
                    </div>
                </div>
            )}
        </div>
    );
};

export default Servicios;

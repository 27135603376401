import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";

import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { ReactSortable } from "react-sortablejs";
import ModalNewEspacio from "./ModalNewEspacio";
import ModalEditEspacio from "./ModalEditEspacio";
import { useParams } from "react-router-dom";

interface Images {
    id: number;
    id_casa: number;
    imagen: string;
}

export interface Espacios {
    id: number;
    id_casa: number;
    nombre: string;
    orden: number;
    imagenes: EspaciosImagenes[];
}

export interface EspaciosImagenes {
    id: number;
    id_habitacion_hotel: number;
    imagen: string;
    orden: number;
}
interface Props {
    modalVisible: boolean;
    setModalVisible: (s: boolean) => void;
    banner: string | File;
    imagenes_habitacion: EspaciosImagenes[];
    id_habitacion: number;
}

export const ModalEditImageHabitacion = ({
    modalVisible,
    setModalVisible,
    banner,
    id_habitacion,
}: Props) => {
    const { id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );

    const dispatchDominio = useDispatchDominio();
    const [bannerCasa, setBannerCasa] = useState<string | File>("");

    const [espaciosImagenes, setEspaciosImagenes] = useState<
        EspaciosImagenes[]
    >([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [espacios, setEspacios] = useState<Espacios[]>([]);
    const [espaciosId, setEspaciosId] = useState<Espacios | null>(null);
    const [espacioNew, setEspacioNew] = useState<boolean>(false);

    useEffect(() => {
        getOne();
    }, []);

    const getOne = async () => {
        setLoading(true);
        const response = await fetchAxiosToken({
            url: `/hotel_habitaciones_imagenes/getImages/${id_habitacion}`,
            method: "get",
        });

        setEspaciosImagenes(response);
        setLoading(false);
    };

    const multiLoadFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const form = new FormData();

        setLoading(true);
        form.append("id_hotel_habitaciones", `${id_habitacion}`);
        if (event.target.files) {
            if (event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    form.append("habitaciones_hotel", event.target.files[i]);
                }
            }
        }

        try {
            const data = await fetchAxiosToken({
                url: `/hotel_habitaciones_imagenes/`,
                method: "post",
                body: form,
            });
            getOne();
            dispatchDominio();
        } catch (error: any) {
            console.log(error.response);
        }

        setLoading(false);
    };

    const handleChangeBanner = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setBannerCasa(e.target.files[0]);
        }
    };

    const cambioDeEspacios = async () => {
        let array_orden: number[] = [];
        espacios.forEach((imagen) => {
            array_orden.push(imagen.id);
        });
        console.log(array_orden);

        const data = await fetchAxiosToken({
            url: `/espacios_imagenes/updateOrden`,
            method: "put",
            body: { array_espacios: array_orden },
        });
    };

    useEffect(() => {
        espacios.length && cambioDeEspacios();
    }, [espacios]);

    const handleSubmit = async () => {
        if (typeof bannerCasa !== "string") {
            setSaving(true);

            const form = new FormData();
            form.append("habitaciones_hotel", bannerCasa);
            try {
                const data = await fetchAxiosToken({
                    url: `/hotel_habitaciones/updateBanner/${id_habitacion}`,
                    method: "put",
                    body: form,
                });

                dispatchDominio();

                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizada",
                        text: "Imagen actualizada con exito",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });

                    setSaving(false);
                    setModalVisible(false);
                }, 2500);
            } catch (error: any) {
                console.log(error.response);
            }
        } else {
            setModalVisible(false);
        }
    };
    const handleDelete = async (id: number) => {
        setLoading(true);

        const data = await fetchAxiosToken({
            url: `/hotel_habitaciones_imagenes/delete/${id}`,
            method: "delete",
        });

        dispatchDominio();
        getOne();
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            espacioNew={espacioNew}
            setModalVisible={setModalVisible}>
            {!espacioNew && !espaciosId && (
                <div className="flex flex-col items-center max-w-xl gap-4 p-4 mx-auto ">
                    <h2 className="text-base font-medium">Editar imagenes</h2>
                    <hr className="w-full bg-gray-300" />

                    <p className="w-full ">Banner</p>

                    <div className="w-full">
                        <div className="relative flex items-center justify-center w-full h-48 mt-1 rounded-lg hover:cursor-pointer">
                            <div className="absolute w-full">
                                <div>
                                    <img
                                        src={
                                            typeof bannerCasa === "string"
                                                ? `${process.env.REACT_APP_PROXY}/hotel_habitaciones/getImage/${banner}`
                                                : URL.createObjectURL(
                                                      bannerCasa
                                                  )
                                        }
                                        className="object-cover w-full h-48 mx-auto rounded-lg 2xl:h-52"
                                        alt="Banner"
                                    />
                                </div>
                            </div>
                            <input
                                accept="image/*"
                                type="file"
                                name="icono_si"
                                className="w-full rounded-lg opacity-0 cursor-pointer h-52 "
                                onChange={handleChangeBanner}
                            />
                        </div>
                    </div>
                    <hr className="w-full mt-4 bg-gray-300" />
                    <div>Imágenes Secundarias</div>
                    {!loading && (
                        <div className="w-full">
                            <div className="mt-4 relative flex justify-center w-full h-52 border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                <div className="absolute">
                                    <div className="flex flex-col w-full mx-auto ">
                                        <div className="relative top-4 ">
                                            <span className="block mt-16 text-base font-semibold text-center ">
                                                Agrega o arrastra una foto aquí
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    accept="image/*"
                                    multiple
                                    type="file"
                                    className="w-full h-full opacity-0 cursor-pointer"
                                    onChange={multiLoadFile}
                                />
                            </div>

                            <ReactSortable
                                handle=".handle"
                                className="flex flex-wrap gap-2 lg:grid lg:grid-cols-1 "
                                group="groupName"
                                animation={200}
                                delay={2}
                                list={espaciosImagenes}
                                setList={setEspaciosImagenes}>
                                {espaciosImagenes?.map((image, index) => (
                                    <div
                                        key={index}
                                        className={`relative w-full mt-4 border rounded-lg handle cursor-move`}>
                                        <img
                                            src={`${process.env.REACT_APP_PROXY}/hotel_habitaciones/getImage/${image.imagen}`}
                                            className={` object-cover w-full mx-auto rounded-lg h-44 `}
                                            alt="imagenes_secundarias"
                                        />
                                        <div
                                            onClick={() =>
                                                handleDelete(image.id)
                                            }
                                            className="absolute rounded-md cursor-pointer top-1 right-1 bg-slate-200 hover:scale-105 ">
                                            <svg
                                                className="w-6 h-6 text-blue-500"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <circle cx="12" cy="12" r="9" />{" "}
                                                <path d="M10 10l4 4m0 -4l-4 4" />
                                            </svg>
                                        </div>
                                    </div>
                                ))}
                            </ReactSortable>
                        </div>
                    )}

                    <div className="flex flex-col gap-4 mt-4 2xl:mt-8">
                        {saving ? (
                            <Spinner />
                        ) : (
                            <button
                                onClick={() => handleSubmit()}
                                className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm">
                                Guardar cambios
                            </button>
                        )}
                    </div>
                </div>
            )}
        </ResponsiveModalAbsolute>
    );
};

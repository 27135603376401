import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import dividirFechaDatePicker from "../../../helpers/dividirFechaDatePicker";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import ToggleButton from "../../../components/helpers/ToggleButton";

interface Input {
    fecha_inicio: string;
    fecha_final: string;
    precio: string;
    motivo: string;
    otro_motivo: string;
    tipo: string;
}

interface VariablePrice {
    id: number;
    id_habitacion: number;
    fecha: string;
    precio: string;
}
interface Fechas {
    fecha: string;
    motivo: string;
}
export interface Selected {
    price: string;
    date: string;
    fechaNoDisponible: Fechas | false;
}

interface Props {
    setModalVisiblePrice: (modalVisiblePrice: boolean) => void;
    modalVisiblePrice: boolean;
    idCasa: number;
    moneda: string;
    getCasa: () => void;
    selected: Selected;
    setVariablePrice: (variablePrice: VariablePrice[]) => void;
}

export const ModalEditVariablePrice = ({
    idCasa,
    setModalVisiblePrice,
    modalVisiblePrice,
    moneda,
    selected,
    setVariablePrice,
    getCasa,
}: Props) => {
    registerLocale("es", es);

    const [saving, setSaving] = useState<boolean>(false);
    const [input, setInput] = useState<Input>({
        fecha_inicio: selected.date,
        fecha_final: selected.date,
        precio: selected.price,
        motivo:
            selected.fechaNoDisponible !== false
                ? selected.fechaNoDisponible.motivo
                : "Alquilado",
        otro_motivo: "",
        tipo: "fecha",
    });
    const [toggleButton, setToggleButton] = useState(
        selected.fechaNoDisponible !== false ? false : true
    );

    const handleSubmit = async () => {
        if (input.precio === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Para actualizar un precio variable , este no puede estar vacio ",
                icon: "error",
                confirmButtonText: "Ok",
            });
            return;
        }
        setSaving(true);
        try {
            if (!toggleButton) {
                const dataDia = await fetchAxiosToken({
                    url: `/disponibilidades_habitaciones_hoteles`,
                    body: {
                        id_habitacion: idCasa,
                        ...input,
                    },
                    method: "post",
                });
            }

            const data = await fetchAxiosToken({
                url: `/precios_variables_habitaciones`,
                method: "post",
                body: {
                    id_habitacion: idCasa,
                    fecha_inicio: input.fecha_inicio,
                    fecha_final: input.fecha_final,
                    precio: input.precio,
                },
            });

            if (data.message === "registro exitoso") {
                try {
                    getCasa();
                    const data = await fetchAxiosToken({
                        url: `/precios_variables_habitaciones/getAll/${idCasa}`,
                    });

                    setVariablePrice(data);
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizada",
                        text: "Precio actualizado con exito",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });

                    setSaving(false);
                    setModalVisiblePrice(false);
                } catch (error: any) {
                    console.log(error.response);
                }
            }
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisiblePrice}
            setModalVisible={setModalVisiblePrice}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <div className="relative z-20 flex items-center justify-center">
                    <h2 className="mr-1 text-base font-medium">
                        Precio variable
                    </h2>
                    <BotonInformacion text="Aca Ingresa el precio que desees ajustar, con las fechas en que cobraras" />
                </div>
                <hr className="w-full bg-gray-300" />

                <div className="flex items-center justify-end w-full gap-2 font-medium">
                    <p>
                        {toggleButton ? "Dia disponible" : "Dia no disponible"}
                    </p>
                    <ToggleButton
                        isToggled={toggleButton}
                        onChangeToggle={(e) => setToggleButton(e)}
                    />
                </div>
                {!toggleButton && (
                    <div className="">
                        <>
                            <label className="flex w-full max-w-xs mb-2 ml-2 text-sm font-medium">
                                Motivo
                            </label>
                            <select
                                value={input.motivo}
                                name="motivo"
                                onChange={handleInputChange}
                                className="w-full max-w-xs p-3 mb-2 border rounded-lg shadow-md placeholder:italic">
                                <option value={"Bluecorner"}>Bluecorner</option>
                                <option value={"Airbnb"}>Airbnb</option>
                                <option value={"Booking.com"}>
                                    Booking.com
                                </option>
                                <option value={"Alquilado"}>Alquilado</option>
                                <option value={"En mantenimiento"}>
                                    En mantenimiento
                                </option>
                                <option value={"Evento privado"}>
                                    Evento privado
                                </option>
                                <option value={"Otro"}>Otro</option>
                            </select>
                        </>
                        {input.motivo === "Otro" && (
                            <>
                                <label className="flex w-full max-w-xs mb-2 ml-2 text-sm font-medium">
                                    Detalle
                                </label>
                                <input
                                    value={input.otro_motivo}
                                    onChange={handleInputChange}
                                    className="w-full max-w-xs p-3 border rounded-lg shadow-md placeholder:italic"
                                    type="text"
                                    name="otro_motivo"
                                />
                            </>
                        )}
                    </div>
                )}
                <div className="flex flex-col mt-1  text-[#3C3C3C]">
                    <div className="flex ">
                        <div className="">
                            <label className="ml-1 text-base font-semibold ">
                                Desde:
                            </label>

                            <DatePicker
                                className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                placeholderText="Seleccionar fecha"
                                locale="es"
                                autoComplete="off"
                                name="fechaDesde"
                                dateFormat="dd/MM/yyyy"
                                selected={
                                    new Date(
                                        dividirFechaDatePicker(
                                            input.fecha_inicio
                                        )[0],
                                        dividirFechaDatePicker(
                                            input.fecha_inicio
                                        )[1] - 1,
                                        dividirFechaDatePicker(
                                            input.fecha_inicio
                                        )[2]
                                    )
                                }
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        fecha_inicio: e
                                            ? `${e.getFullYear()}-${`${
                                                  e.getMonth() + 1
                                              }`.padStart(
                                                  2,
                                                  "0"
                                              )}-${`${e.getDate()}`.padStart(
                                                  2,
                                                  "0"
                                              )}`
                                            : "",
                                    })
                                }
                            />
                        </div>
                        <div>
                            <label className="ml-1 text-base font-semibold ">
                                Hasta:
                            </label>

                            <DatePicker
                                className="w-11/12 p-2 bg-white border rounded-lg cursor-pointer"
                                placeholderText="Seleccionar fecha"
                                locale="es"
                                autoComplete="off"
                                name="fechaHasta"
                                dateFormat="dd/MM/yyyy"
                                selected={
                                    new Date(
                                        dividirFechaDatePicker(
                                            input.fecha_final
                                        )[0],
                                        dividirFechaDatePicker(
                                            input.fecha_final
                                        )[1] - 1,
                                        dividirFechaDatePicker(
                                            input.fecha_final
                                        )[2]
                                    )
                                }
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        fecha_final: e
                                            ? `${e.getFullYear()}-${`${
                                                  e.getMonth() + 1
                                              }`.padStart(
                                                  2,
                                                  "0"
                                              )}-${`${e.getDate()}`.padStart(
                                                  2,
                                                  "0"
                                              )}`
                                            : "",
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className="mt-2 ">
                        <div className="flex items-center justify-center ">
                            <div className="mb-2 ">
                                <label className="ml-1 mr-1 text-base font-semibold ">
                                    {`Precio por noche (${moneda})`}
                                </label>
                                <input
                                    className="md:ml-3 p-2 pt-[9.5px] rounded-lg border border-[#E9E9E9] outline-none w-28 placeholder:text-center text-center"
                                    value={input.precio}
                                    placeholder="100"
                                    type="number"
                                    step=".01"
                                    name="precio"
                                    onChange={(e) =>
                                        setInput({
                                            ...input,
                                            precio: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#029BFC] hover:scale-105">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

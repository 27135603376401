import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { logOut } from "../../redux/reducers/authReducer";
import logo from "../../img/Logo-BluStay-02.png";

interface Props {
    className: string;
    titles: {
        id: number;
        title: string;
        url?: string;
        sublist?: { id: number; title: string; url: string }[];
    }[];
    handleSmoothScroll: (title: string) => void;
}

const BurguerMenu = ({ className, titles, handleSmoothScroll }: Props) => {
    const [showOptions, setShowOptions] = useState(false);
    const dispatch = useAppDispatch();
    const { tags } = useParams();
    const { id, avatar, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const [openSublist, setOpenSublist] = useState<number | null>(null); // Estado para rastrear el sublistado abierto

    const toggleSublist = (id: number) => {
        setOpenSublist((prev) => (prev === id ? null : id)); // Alterna entre abrir/cerrar el sublistado
    };

    const [burguerMenuVisible, setBurguerMenuVisible] =
        useState<boolean>(false);
    const navigate = useNavigate();
    const [showRedBanner, setShowRedBanner] = useState(true);
    return (
        <div className="relative">
            <div
                className={`${className} flex justify-center p-3 bg-[#0E1928] text-white px-5 border-b-2 border-white text-[10px]  md723:text-base `}
            >
                {showRedBanner && (
                    <div
                        onClick={(event) => {
                            navigate(`/impact/${tags ? tags : "6"}`);
                            event.stopPropagation();
                        }}
                        className="flex items-center justify-between w-full gap-6 px-1 py-2 text-center transition-transform transform bg-[#C0D345]/10  text-[#C0D345] rounded-3xl cursor-pointer xs:w-10/12 lg:mr-14 "
                    >
                        <div></div>
                        <div className="font-semibold ">
                            Por tiempo limitado: ¡Obtén la Guía de Plan de
                            Marketing para Propietarios de forma gratuita!
                        </div>
                        <div
                            onClick={(event) => {
                                setShowRedBanner(false);
                                event.stopPropagation();
                            }}
                            className="text-base text-[#C0D345]  mr-3"
                        >
                            X
                        </div>
                    </div>
                )}
            </div>
            <div
                className={`${className} flex justify-between items-center p-3 bg-[#0E1928] text-white px-5`}
            >
                <span
                    onClick={() => handleSmoothScroll("inicio")}
                    className="mr-8 font-medium cursor-pointer lg:text-lg"
                >
                    <img src={logo} className="w-20" alt="logo blustay" />
                </span>
                <svg
                    onClick={() => setBurguerMenuVisible(true)}
                    className="w-8 h-8 text-white cursor-pointer"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="4" y1="6" x2="20" y2="6" />
                    <line x1="4" y1="12" x2="20" y2="12" />
                    <line x1="4" y1="18" x2="20" y2="18" />
                </svg>
            </div>
            <div
                className={`${
                    burguerMenuVisible ? "w-full xs:w-1/2 md818:w-0 p-3" : "w-0"
                } transition-all ease-out duration-700 h-screen absolute bg-[#0E1928]  text-white top-0 right-0  flex gap-4 md818:hidden`}
            >
                <svg
                    onClick={() => setBurguerMenuVisible(false)}
                    className="text-white cursor-pointer h-7 w-7"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                <div className="flex flex-col w-full gap-5 mt-10">
                    {titles.map((title) => (
                        <div key={title.id} className="relative">
                            {/* Opción principal */}
                            <div
                                onClick={() => {
                                    if (title.sublist) {
                                        toggleSublist(title.id); // Alternar sublistado
                                    } else {
                                        if (title.url) {
                                            setBurguerMenuVisible(false);
                                            handleSmoothScroll(title.url);
                                        }
                                    }
                                }}
                                className="font-medium capitalize cursor-pointer flex justify-between items-center"
                            >
                                <span>{title.title}</span>
                            </div>
                            <hr className="mt-2 bg-white" />

                            {/* Sublistado */}
                            {title.sublist && (
                                <div
                                    className={`overflow-hidden flex flex-col transition-all ease-in-out duration-700 gap-2 ml-4 mt-2  ${
                                        openSublist === title.id
                                            ? "max-h-[200px] opacity-100"
                                            : "max-h-0 opacity-0"
                                    }`}
                                >
                                    {title.sublist.map((subItem) => (
                                        <div
                                            key={subItem.id}
                                            onClick={() => {
                                                setBurguerMenuVisible(false);
                                                handleSmoothScroll(subItem.url);
                                            }}
                                            className="font-normal text-gray-300 capitalize cursor-pointer hover:text-white"
                                        >
                                            {subItem.title}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="flex items-center">
                        {id ? (
                            <div
                                className="relative flex flex-col items-center"
                                onBlur={() => setShowOptions(false)}
                            >
                                <button onClick={() => setShowOptions(true)}>
                                    <img
                                        src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`}
                                        className="z-40 object-cover w-12 h-12 border border-gray-600 rounded-full cursor-pointer"
                                        alt="foto de perfil"
                                    />
                                </button>
                                <div
                                    className={`${
                                        showOptions
                                            ? "h-max opacity-100 visible"
                                            : "h-0 opacity-0 invisible"
                                    }  absolute flex flex-col z-0  top-[3.1rem] text-black left-1 bg-white transition-all duration-100 delay-200 ease-out`}
                                >
                                    <div className="flex flex-col w-40 p-3 border ">
                                        <div
                                            onClick={() =>
                                                navigate(
                                                    tipo_usuario < 3
                                                        ? "/dashboard-admin/mi-cuenta"
                                                        : "/dashboard"
                                                )
                                            }
                                            className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom"
                                        >
                                            <svg
                                                className="w-6 h-6 text-blue-blueFrom"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2.5"
                                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                                />
                                            </svg>
                                            <span className="text-sm">
                                                Mis reservas
                                            </span>
                                        </div>
                                        <hr
                                            className={
                                                tipo_usuario < 3
                                                    ? "hidden"
                                                    : "my-2"
                                            }
                                        />
                                        <div
                                            onClick={() =>
                                                navigate(
                                                    "/dashboard/cambiar-contraseña"
                                                )
                                            }
                                            className={
                                                tipo_usuario < 3
                                                    ? "hidden"
                                                    : "flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom"
                                            }
                                        >
                                            <svg
                                                className="w-6 h-6 text-blue-blueFrom"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                                />
                                            </svg>
                                            <span className="text-sm">
                                                Cambiar contraseña
                                            </span>
                                        </div>
                                        <hr className="my-2" />
                                        <div
                                            onClick={() => {
                                                navigate("/");
                                                dispatch(logOut());
                                            }}
                                            className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom"
                                        >
                                            <svg
                                                className="ml-0.5 w-6 h-6"
                                                stroke="#0060ff"
                                                fill="none"
                                            >
                                                <g>
                                                    <path d="M19.2854 12.0002L11.2727 12.0002"></path>
                                                    <path d="M16.5101 15.6364L19.9999 12L16.5101 8.36363"></path>
                                                    <path d="M13.4545 7V4H4V20H13.4545V17"></path>
                                                </g>
                                            </svg>
                                            <span className="absolute ml-8 text-sm">
                                                Cerrar sesión
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                onClick={() =>
                                    navigate("/registro-propietario")
                                }
                                className="flex items-center gap-2 cursor-pointer"
                            >
                                <button>
                                    <svg
                                        className="w-6 h-6 text-white cursor-pointer hover:fill-current"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                        />
                                    </svg>
                                </button>
                                <span className="font-medium capitalize cursor-pointer">
                                    Registrarse
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BurguerMenu;

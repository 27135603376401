import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import imagenes from "../../../img/onboarding/imagenes.jpg";

import { ReactSortable } from "react-sortablejs";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { ButtonSiguiente } from "../../onboardingHotel/ButtonSiguiente";
import axios from "axios";
import Spinner from "../../../components/helpers/Spinner";

interface customFile extends File {
    imagen?: string;
    id?: number;
    isPreview?: boolean;
    urlImage?: string | null;
}

interface selectedFile {
    banner: File | null;
}

export interface Espacios {
    id: number;
    id_casa: number;
    nombre: string;
    orden: number;
    imagenes: EspaciosImagenes[];
}

export interface EspaciosImagenes {
    id: number;
    id_hotel_habitaciones: number;
    imagen: string;
    orden: number;
}

export const ImagenesHabitacionHotel = () => {
    const { idCasa } = useParams();
    const [selectedFiles, setSelectedFiles] = useState<selectedFile>({
        banner: null,
    });
    const [loading, setLoading] = useState(false);
    const [espaciosImagenes, setEspaciosImagenes] = useState<
        EspaciosImagenes[]
    >([]);
    const [showBannerPreview, setShowBannerPreview] = useState<boolean>(false);
    const [showSecundariasPreview, setShowSecundariasPreview] =
        useState<boolean>(false);
    const [bannerPreview, setBannerPreview] = useState<string | undefined>(
        undefined
    );
    const [secundariasPreview, setSecundariasPreview] = useState<
        (
            | {
                  id: string;
                  imagen: string;
                  isPreview: boolean;
                  urlImage: string | null;
              }
            | customFile
        )[]
    >([]);

    const navigate = useNavigate();
    console.log("selectedFiles", selectedFiles);

    const getOneHabitacion = async () => {
        fetchAxiosToken({
            url: `/hotel_habitaciones/detalle/${idCasa}`,
        })
            .then((res) => {
                console.log(res);
                setSelectedFiles({ banner: res.banner });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) getOneHabitacion();
    }, []);

    console.log(secundariasPreview);

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.name === "banner") {
            const imagePreview = URL.createObjectURL(e.target.files[0]);

            setSelectedFiles({
                ...selectedFiles,
                banner: e.target.files[0],
            });

            setBannerPreview(imagePreview);

            setShowBannerPreview(true);
        }
    };

    const multiLoadFile = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const form = new FormData();

        setLoading(true);
        form.append("id_hotel_habitaciones", `${idCasa}`);

        if (event.target.files) {
            if (event.target.files.length > 0) {
                for (let i = 0; i < event.target.files.length; i++) {
                    form.append("habitaciones_hotel", event.target.files[i]);
                }
            }
        }

        try {
            const data = await fetchAxiosToken({
                url: `/hotel_habitaciones_imagenes/`,
                method: "post",
                body: form,
            });
        } catch (error: any) {
            console.log(error.response);
        }
        getAllImagenesSegundarias();
        setLoading(false);
    };

    const getAllImagenesSegundarias = async () => {
        const dataGetOne = await fetchAxiosToken({
            url: `/hotel_habitaciones_imagenes/getImages/${idCasa}`,
            method: "get",
        });
        console.log("dataGetOne", dataGetOne);
        setEspaciosImagenes(dataGetOne);
        setLoading(false);
    };

    const updateImagenes = async () => {
        const formBanner = new FormData();

        formBanner.append("habitaciones_hotel", selectedFiles.banner!);

        try {
            if (showBannerPreview) {
                const dataBanner = await fetchAxiosToken({
                    url: `/hotel_habitaciones/updateBanner/${idCasa}`,
                    method: "put",
                    body: formBanner,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async () => {
        try {
            await updateImagenes();

            let array_orden: number[] = [];
            espaciosImagenes.forEach((imagen) => {
                array_orden.push(imagen.id);
            });
            console.log(array_orden);

            const data = await fetchAxiosToken({
                url: `/hotel_habitaciones_imagenes/updateOrden`,
                method: "put",
                body: { array_imagenes: array_orden },
            });

            console.log(data);
            navigate(`/dashboard-hotel/onboarding/precio-habitacion/${idCasa}`);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (id: number) => {
        setLoading(true);

        const json = await fetchAxiosToken({
            url: `/hotel_habitaciones_imagenes/delete/${id}`,
            method: "delete",
        });

        getAllImagenesSegundarias();
    };

    useEffect(() => {
        getAllImagenesSegundarias();
    }, []);

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={imagenes}
                        alt="imagenes"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Ahora sube las imágenes de los espacios principales
                            de tu habitación
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-0 flex flex-col justify-center text-center text-black bg-white lg:absolute lg:w-1/2 lg:h-screen">
                <div className=" lg:overflow-x-auto">
                    <div className="relative ">
                        <ButtonRegresar
                            url={`${`/dashboard-hotel/onboarding/capacidad-habitacion/${idCasa}`}`}
                            className="absolute top-1 lg:top-6 left-1 lg:left-10"
                            arrowColor="black"
                            textColor="black"
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center pt-20 ">
                        <p className="my-2 text-lg font-medium 2xl:text-xl">
                            Sube tu imagen de portada
                        </p>
                        <div className="cursor-pointer mt-4 relative flex justify-center w-[350px] h-52 border-2 border-dashed border-[#E9E9E9]  rounded-lg hover:cursor-pointer ">
                            {!selectedFiles.banner ? (
                                <>
                                    <div className="absolute">
                                        <div className="flex flex-col w-full mx-auto ">
                                            <div className="relative top-4 ">
                                                <span className="block mt-16 text-base font-semibold text-center ">
                                                    Agrega o arrastra una foto
                                                    aquí
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="banner"
                                        className="w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                        onChange={handleChangeImage}
                                    />
                                </>
                            ) : !showBannerPreview ? (
                                <>
                                    <img
                                        className="object-cover w-full rounded-lg"
                                        src={`${process.env.REACT_APP_PROXY}/hotel_habitaciones/getImage/${selectedFiles.banner}`}
                                        alt="banner del alojamiento"
                                    />
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="banner"
                                        className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                        onChange={handleChangeImage}
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        className="object-cover w-full rounded-lg"
                                        src={bannerPreview}
                                        alt="banner del alojamiento"
                                    />
                                    <input
                                        accept="image/*"
                                        type="file"
                                        name="banner"
                                        className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                        onChange={handleChangeImage}
                                    />
                                </>
                            )}
                        </div>

                        <div className="flex flex-col items-center justify-center mt-5">
                            <p className="mt-10 text-lg font-medium">
                                Ahora sube las imágenes de los espacios
                                principales de tu habitación
                            </p>

                            <div className="w-full px-4">
                                {!loading && (
                                    <div>
                                        <div className="mt-4 relative flex justify-center h-52 w-full lg:h-72 xl:h-96 2xl:h-52 border border-[#E9E9E9] rounded-lg hover:cursor-pointer">
                                            <div className="absolute">
                                                <div className="flex flex-col w-full mx-auto ">
                                                    <div className="relative top-4 ">
                                                        <span className="block mt-16 text-base font-semibold text-center ">
                                                            Agrega o arrastra
                                                            una foto aquí
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                accept="image/*"
                                                multiple
                                                type="file"
                                                className="w-full h-full opacity-0 cursor-pointer"
                                                onChange={multiLoadFile}
                                            />
                                        </div>

                                        <ReactSortable
                                            handle=".handle"
                                            className="flex flex-wrap gap-2 lg:grid lg:grid-cols-2 "
                                            group="groupName"
                                            animation={200}
                                            delay={2}
                                            list={espaciosImagenes}
                                            setList={setEspaciosImagenes}>
                                            {espaciosImagenes?.map(
                                                (image, index) => (
                                                    <div
                                                        key={index}
                                                        className={`relative w-full mt-4 border rounded-lg handle cursor-move`}>
                                                        <img
                                                            src={`${process.env.REACT_APP_PROXY}/hotel_habitaciones/getImage/${image.imagen}`}
                                                            className={` object-cover w-full mx-auto rounded-lg h-52 `}
                                                        />
                                                        <div
                                                            onClick={() =>
                                                                handleDelete(
                                                                    image.id
                                                                )
                                                            }
                                                            className="absolute rounded-md cursor-pointer top-1 right-1 bg-slate-200 hover:scale-105 ">
                                                            <svg
                                                                className="w-6 h-6 text-blue-500"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round">
                                                                {" "}
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                />{" "}
                                                                <circle
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="9"
                                                                />{" "}
                                                                <path d="M10 10l4 4m0 -4l-4 4" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </ReactSortable>
                                    </div>
                                )}
                                {loading && <div>{<Spinner />}</div>}
                            </div>
                            <div className="w-[350px]">
                                <ButtonSiguiente
                                    onSubmit={() => handleSubmit()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "./routes/AppRouter";
import "./index.css";
import { store } from "./store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoadScript } from "@react-google-maps/api";
import "./assets/css/swiper_preload.css";
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <GoogleOAuthProvider clientId="383088837702-krlquqek9sronsemmle6lov3lcvaeguh.apps.googleusercontent.com">
        <Provider store={store}>
            <LoadScript
                loadingElement={<p>Cargando...</p>}
                libraries={["places"]}
                googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
            >
                <AppRouter />
            </LoadScript>
        </Provider>
    </GoogleOAuthProvider>
);

import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { useParams } from "react-router-dom";

interface Props {
    modalVisible: boolean;
    setModalVisible: (s: boolean) => void;
    precio_base: string;
    id_habitacion: number;
    check_in: string;
    check_out: string;
}

export interface PrecioBase {
    precio_base: string;
    check_in: string;
    check_out: string;
    /*   tipo_moneda: string;
    estadia_minima: number;
    estadia_maxima: number; */
}

export const ModalEditPrecioBaseHabitacion = ({
    modalVisible,
    setModalVisible,
    precio_base,
    check_in,
    check_out,
    id_habitacion,
}: Props) => {
    const { tipo_moneda, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );

    const dispatchDominio = useDispatchDominio();

    const [saving, setSaving] = useState<boolean>(false);
    const [input, setInput] = useState<PrecioBase>({
        precio_base: precio_base,
        check_in: check_in,
        check_out: check_out,
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        if (Number(input.precio_base) === 0)
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "El precio base debe ser mayor a cero (0)",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });

        try {
            setSaving(true);
            await fetchAxiosToken({
                url: `/hotel_habitaciones/updatePrecio/${id_habitacion}`,
                method: "put",
                body: input,
            });

            dispatchDominio();
            setTimeout(() => {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Actualizado",
                    text: "Habitación actualizada",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
                setSaving(false);

                setModalVisible(false);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}>
            <div className="flex flex-col items-center justify-center max-w-lg gap-4 p-4 mx-auto text-gray-600 ">
                <h2 className="2xl:text-4xl text-3xl text-[#1C4CBB] text-center font-medium">
                    Editar Precio Base
                </h2>
                <hr className="w-full bg-gray-300" />

                <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Precio por noche
                    </label>
                    <div className="flex items-center justify-start w-full space-x-2">
                        <input
                            type={"number"}
                            min={0}
                            name="precio_base"
                            value={input.precio_base}
                            onChange={handleInputChange}
                            placeholder="Ingresa el precio por base"
                            className="w-1/2 max-w-sm 2xl:p-3 p-2 2xl:text-base text-sm  mt-2 text-black border border-[#1C4CBB] rounded-lg placeholder:italic"
                        />
                        <div className="text-xl font-bold text-gray-800 ">
                            {tipo_moneda}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Check - In
                    </label>
                    <input
                        type={"time"}
                        min={0}
                        name="check_in"
                        value={input.check_in}
                        onChange={handleInputChange}
                        className="w-full max-w-sm 2xl:p-3 p-2 2xl:text-base text-sm mx-auto mt-2 text-black border border-[#1C4CBB] rounded-lg placeholder:italic"
                    />
                </div>

                <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Check - Out
                    </label>
                    <input
                        type={"time"}
                        min={0}
                        name="check_out"
                        value={input.check_out}
                        onChange={handleInputChange}
                        className="w-full max-w-sm 2xl:p-3 p-2 2xl:text-base text-sm mx-auto mt-2 text-black border border-[#1C4CBB] rounded-lg placeholder:italic"
                    />
                </div>
                {/*  <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl">
                        Estadia Minima
                    </label>
                    <input
                        type={"number"}
                        min={0}
                        name="estadia_minima"
                        value={input.estadia_minima}
                        onChange={handleInputChange}
                        placeholder="Ingresa el minimo de noches"
                        className="w-full max-w-sm 2xl:p-3 p-2 2xl:text-base text-sm mx-auto mt-2 text-black border border-[#1C4CBB] rounded-lg placeholder:italic hover:border-[#1C4CBB] "
                    />
                </div> */}

                {/*   <div className="flex flex-col justify-center w-10/12">
                    <label className="ml-2 text-lg font-medium 2xl:text-xl ">
                        Estadia Maxima
                    </label>
                    <input
                        type={"number"}
                        min={0}
                        name="estadia_maxima"
                        value={input.estadia_maxima}
                        onChange={handleInputChange}
                        placeholder="Ingresa el maximo de noches"
                        className="w-full max-w-sm 2xl:p-3 p-2 2xl:text-base text-sm mx-auto mt-2 text-black border border-[#1C4CBB] rounded-lg placeholder:italic focus:border-blue-500"
                    />
                    <label className="text-xs italic font-medium text-red-500">
                        Si desea no tener un limite de máximo de noches, debera
                        dejar en cero (0)
                    </label>
                </div> */}

                <div className="w-10/12 mt-4 2xl:mt-8 ">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="w-full p-3 px-5 text-white rounded-lg bg-[#1C4CBB] hover:scale-105  2xl:text-base text-sm">
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};

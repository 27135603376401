import { ChangeEvent, useState } from "react";
import axios from "axios";

function SendMediaMessage({
    chatId,
    file,
    setFile,
    mediaBase64,
    setMediaBase64,
    mediaName,
    setMediaName,
}: {
    chatId: string;
    file: File | null;
    setFile: (f: File | null) => void;
    mediaBase64: string;
    setMediaBase64: (f: string) => void;
    mediaName: string;
    setMediaName: (f: string) => void;
}) {
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = (reader.result as string).split(",")[1];
                setMediaBase64(base64Data);
                setMediaName(selectedFile.name);
                setFile(selectedFile);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    return (
        <div className="flex items-center gap-1">
            {/* <input
                type="file"
                accept="image/*,video/*,audio/*,.pdf"
                onChange={handleFileChange}
            /> */}
            <div className="relative flex items-center justify-center w-8 h-8 mt-1 rounded-lg hover:cursor-pointer">
                <div className="absolute w-full">
                    <div>
                        <svg
                            className="w-8 h-8 text-blue-500 cursor-pointer"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                        </svg>
                    </div>
                </div>
                <input
                    accept="image/*,video/*,audio/*,.pdf"
                    type="file"
                    className="w-8 h-8 rounded-lg opacity-0 cursor-pointer "
                    onChange={handleFileChange}
                />
            </div>
            {/* <button onClick={sendMediaMessage}>Send Media</button> */}

            {/* Optionally preview the media before sending */}
            {file && (
                <div>
                    {file.type.startsWith("image/") && (
                        <img
                            src={`data:${file.type};base64,${mediaBase64}`}
                            alt="Preview"
                            className="w-40 h-40 rounded"
                        />
                    )}
                    {file.type.startsWith("video/") && (
                        <video controls className="w-40 h-40 rounded">
                            <source
                                src={`data:${file.type};base64,${mediaBase64}`}
                                type={file.type}
                            />
                            Su navegador no soporta la reproducción de vídeo.
                        </video>
                    )}
                    {file.type.startsWith("audio/") && (
                        <audio controls>
                            <source
                                src={`data:${file.type};base64,${mediaBase64}`}
                                type={file.type}
                            />
                            Su navegador no soporta la reproducción de audio.
                        </audio>
                    )}
                    {!file.type.startsWith("image/") &&
                        !file.type.startsWith("video/") &&
                        !file.type.startsWith("audio/") && (
                            <p className="text-sm">
                                Archivo seleccionado: {mediaName}
                            </p>
                        )}
                </div>
            )}
        </div>
    );
}

export default SendMediaMessage;

import axios, { Axios, AxiosError } from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { useAppSelector } from "../../hooks/hooks";
import useHeaders from "../../hooks/useHeaders";
import { Navbar } from "./Navbar";
import { NavbarMobile } from "./NavbarMobile";
import { NavbarAdministrativa } from "../dashboardAdministrativo/NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../dashboardAdministrativo/NavbarAdministrativaMobile";
import { NavbarCliente } from "../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../dashboardClientes/NavbarMobileCliente";
import { NavbarBroker } from "../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../dashboardHotel/NavbarMobileHotel";
import { NavbarClienteHotel } from "../dashboardClientesHotel/NavbarClienteHotel";
import { NavbarMobileClienteHotel } from "../dashboardClientesHotel/NavbarMobileClienteHotel";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

const INPUTS = [
    {
        input_id: 0,
        label: "Contraseña actual",
        nombre: "current_password",
        type: "text",
    },
    {
        input_id: 1,
        label: "Nueva contraseña",
        nombre: "new_password",
    },
];

export const CambiarPassword = () => {
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        current_password: "",
        new_password: "",
    });
    const [showPass, setShowPass] = useState(false);
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const { headers } = useHeaders();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        try {
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY}/usuarios/updatePassword/${id}`,
                userInfo,
                { headers }
            );
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                position: "top-end",
                icon: "success",
                title: "Tus datos han sido guardados correctamente!",
                showConfirmButton: false,
                timer: 1500,
            });
            setUserInfo({
                current_password: "",
                new_password: "",
            });
        } catch (error: any) {
            console.log(error.response?.data);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: error.response.data.error,
                text: "Intente ingresar los datos nuevamente",
                icon: "error",
                showConfirmButton: false,
                timer: 2500,
            });
        }
    };

    const render = () => {
        return (
            <div className="flex flex-col-reverse items-center justify-end w-full sm:mb-0">
                <div className="flex flex-col items-center w-full p-6">
                    {INPUTS.map(({ nombre, input_id, type, label }) => (
                        <div
                            key={input_id}
                            className="relative flex flex-col w-full max-w-xl">
                            <label className="font-medium">{label}</label>
                            <div className="flex items-center">
                                <input
                                    className="w-full p-2 mt-1 border rounded-xl"
                                    placeholder={label}
                                    name={nombre}
                                    value={userInfo[nombre]}
                                    onChange={handleInputChange}
                                    type={showPass ? "text" : "password"}
                                />
                                <span className="absolute right-0 mx-2 mt-1 italic text-gray-400">
                                    {showPass ? (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                />
                                            </svg>
                                        </>
                                    ) : (
                                        <>
                                            <svg
                                                className={`w-6 h-6 mx-2 cursor-pointer`}
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}>
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                                />
                                            </svg>
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    ))}
                    <button
                        onClick={() => handleSave()}
                        className="p-3 mt-4 font-medium text-white border rounded-lg bg-[#1C4CBB] hover:scale-105 active:translate-y-1">
                        Guardar cambios
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="flex">
            {tipo_usuario <= 2 ? (
                <NavbarAdministrativa titulo="Cuenta">
                    {render()}
                    <NavbarAdministrativaMobile />
                </NavbarAdministrativa>
            ) : tipo_usuario === 5 ? (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            ) : tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Cambiar contraseña">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Cambiar contraseña">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : tipo_usuario === 8 ? (
                <NavbarHotel titulo="Cambiar contraseña">
                    {render()}
                    <NavbarMobileHotel />
                </NavbarHotel>
            ) : tipo_usuario === 9 ? (
                <NavbarClienteHotel>
                    {render()}
                    <NavbarMobileClienteHotel />
                </NavbarClienteHotel>
            ) : null}
        </div>
    );
};

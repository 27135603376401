import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { infoCasa } from "../../designs/calendario-reserva/typeCasa";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";

const INITIAL_STATE: infoCasa = {
    banner: "",
    casa_actividades: [],
    casa_comodidades: [],
    casa_habitaciones: [],
    casa_imagenes: [],
    casa_estadias_minimas: [],
    ciudad: "",
    departamento: "",
    fecha_registro: "",
    habilitado: "",
    id: 0,
    id_propietario: 0,
    latitud: "",
    longitud: "",
    nombre: "",
    pagina: "bienvenida",
    disponibilidades: {
        arrayDias: [],
        arrayFechas: [],
    },
    pedidos: {
        arrayFechasPedido: [],
    },
    descuentos_tiempos: [],
    personas_adicionales: [],
    precios_variables: [],
    servicios_adicionales: [],
    hotel_habitaciones: [],
    pais: "",
    precio_base: "0",
    tema: "",
    terminado: "No",
    tipo_moneda: "",
    whatsapp: "",
    personas: 0,
    banos: "0",
    habitaciones: "0",
    mascotas: 0,
    checking: true,
    estadia_maxima: 2,
    estadia_minima: 3,
    clave: "",
    resenas: [],
    check_in: "",
    check_out: "",
    garantia: "0",
    is_mercado_pago: "No",
    mensaje_bienvenida: "",
    tipo_garantia: "",
    plan: 0,
    precios_variables_semanas: [],
    descripcion_1: "",
    descripcion_2: "",
    titulo_descripcion_1: "",
    titulo_descripcion_2: "",
    tipo_descripcion: "",
    imagen_descripcion: "",
    video_descripcion: "",
    tiempo_respuesta: "",
    dominio: "",
    dias_restantes: 0,
    prueba: "No",
    direccion: "",
    tiktok: "",
    facebook: "",
    instagram: "",
    id_airbnb: "",
    id_booking: "",
    id_tripadvisor: "",
    token_google_calendar: "",
    isChatbot: "",
    tipo_usuario: 0,
};

export const getHouse = createAsyncThunk(
    "onboarding/getHouse",
    async (id_usuario: number) => {
        try {
            const userHouse = await fetchAxiosToken({
                url: `/usuarios/getCasa/${id_usuario}`,
            });
            // console.log("userHouse",userHouse)
            if (userHouse.id_casa) {
                const houseInfo = await fetchAxiosToken({
                    url: `/casa/getOne/id/${userHouse.id_casa}`,
                });
                console.log("houseInfo", houseInfo);
                return { ...houseInfo, checking: false };
            } else return INITIAL_STATE;
        } catch (error) {
            console.log(error);
            return { ...INITIAL_STATE, checking: false };
        }
    }
);

export const onboarding_house = createSlice({
    name: "onboarding_house",
    initialState: INITIAL_STATE,
    reducers: {
        // logIn: (state, action) => {
        //     return {
        //         ...action.payload,
        //         checking: false,
        //     };
        // },
    },
    extraReducers(builder) {
        builder.addCase(getHouse.fulfilled, (state, action) => {
            return {
                ...action.payload,
            };
        });
    },
});

// Action creators are generated for each case reducer function
// export const { logIn, logOut } = onboarding_house.actions;

export default onboarding_house.reducer;

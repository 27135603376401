import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import precio from "../../../img/onboarding/precio.jpg";
import { useAppSelector } from "../../../hooks/hooks";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { ButtonSiguiente } from "../../onboardingHotel/ButtonSiguiente";

interface PreciosForm {
    precio_base?: string | number;
    tipo_moneda?: string;
    check_in?: string;
    check_out?: string;
    garantia?: string | number;
    tipo_garantia?: string;
}

type errorObject = { [key: string]: string };

export const PrecioHabitacionHotel = () => {
    const { idCasa } = useParams();
    const casa = useAppSelector((state) => state.landing_house.house);
    const [input, setInput] = useState<PreciosForm>({
        precio_base: "",
        tipo_moneda: casa.tipo_moneda,
        check_in: "",
        check_out: "",
        garantia: "",
        tipo_garantia: "Alquiler",
    });
    const [errors, setErrors] = useState<PreciosForm>({});

    const navigate = useNavigate();

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: PreciosForm) => {
        let errores: errorObject = {};

        if (!form.precio_base || Number(form.precio_base) <= 0) {
            errores.precio_base = "Debe ingresar un precio válido";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                const data = await fetchAxiosToken({
                    url: `/hotel_habitaciones/updatePrecio/${idCasa}`,
                    method: "put",
                    body: input,
                });

                setTimeout(() => {
                    navigate(`/`);
                }, 3000);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getOneHabitacion = async () => {
        fetchAxiosToken({
            url: `/hotel_habitaciones/detalle/${idCasa}`,
        })
            .then((res) => {
                console.log(res);
                setInput(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) getOneHabitacion();
    }, []);

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/3 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={precio}
                        alt="precio"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Ahora definamos un precio competitivo para tu
                            habitación
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`${`/dashboard-hotel/onboarding/imagenes-habitacion/${idCasa}`}`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col items-center pb-4 mt-14 2xl:mt-24 xl:mt-80 lg:mt-80">
                    <h2 className="text-lg font-medium lg:text-xl 2xl:text-2xl">
                        Define tu precio base por noche
                    </h2>
                    <div className="2xl:mt-10 lg:mt-5 mt-3 w-full flex gap-4 md:w-1/2 max-w-[468px] ">
                        <div className="flex flex-col flex-1 text-start">
                            <label className="text-sm font-medium">
                                Precio por noche
                            </label>
                            <input
                                value={input.precio_base}
                                onChange={handleInputChange}
                                name="precio_base"
                                type={"number"}
                                className="w-full p-3 mt-1 text-black border rounded-lg shadow-md placeholder:italic"
                                placeholder="100"
                            />
                            {errors.precio_base && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.precio_base}
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col ">
                            <label className="text-sm font-medium">
                                Moneda
                            </label>
                            <p className="w-full p-3 mt-1 text-black border rounded-lg shadow-md cursor-not-allowed">
                                {casa.tipo_moneda}
                            </p>
                        </div>
                    </div>
                </div>

                <hr className="px-10 border border-gray-300" />

                <div className="flex flex-col items-center pb-4 mt-5 lg:mt-8 2xl:mt-10 ">
                    <h2 className="text-lg font-medium lg:text-xl 2xl:text-2xl">
                        Define la garantía
                    </h2>
                    <div className="2xl:mt-10 lg:mt-5 mt-3 w-full flex flex-col md:w-1/2 gap-1 max-w-[468px] ">
                        <div className="flex flex-col flex-1 text-start">
                            <label className="text-sm font-medium">
                                Valor de la garantía
                            </label>

                            <div className="flex items-center w-full p-1 italic text-gray-500 border rounded-lg shadow-md outline-none text- base placeholder:italic">
                                <input
                                    value={input.garantia}
                                    onChange={handleInputChange}
                                    name="garantia"
                                    type={"number"}
                                    className=" w-full mt-2 mb-2 ml-4  outline-none text-[#3C3C3C]"
                                />{" "}
                                <p className="w-full text-center">
                                    {input.tipo_moneda}
                                </p>
                            </div>
                            {errors.garantia && (
                                <p className="my-1 text-sm font-bold text-red-500 text-md">
                                    {errors.garantia}
                                </p>
                            )}
                        </div>
                        <div className="flex flex-col flex-1 mt-8 text-start">
                            <label className="text-sm font-medium">
                                Tipo de garantía
                            </label>

                            <select
                                className="w-full px-3 py-3 my-3 text-black bg-transparent bg-white border rounded-lg outline-none focus:outline-none placeholder:font-bold"
                                value={input.tipo_garantia}
                                name="warrantyType"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        tipo_garantia: e.target.value,
                                    })
                                }
                            >
                                <option value={"Alquiler"}>
                                    {" "}
                                    Cobrarlo en la reserva
                                </option>
                                <option value={"Efectivo"}>
                                    {" "}
                                    Cobrarlo en el check in
                                </option>
                            </select>
                            <div>
                                {input.tipo_garantia === "Alquiler" && (
                                    <p className="mt-2 text-xs text-center text-gray-400 lg:text-sm 2xl:text-base">
                                        El cliente debera pagar la garantía al
                                        momento de hacer la reserva
                                    </p>
                                )}
                                {input.tipo_garantia === "Efectivo" && (
                                    <p className="mt-2 text-xs text-center text-gray-400 lg:text-sm 2xl:text-base">
                                        El cliente debera pagar la garantía al
                                        momento de llegar a la propiedad
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="px-10 border border-gray-300" />

                <div className="flex flex-col items-center pb-4 mt-5 lg:mt-8 2xl:mt-10 ">
                    <h2 className="text-lg font-medium lg:text-xl 2xl:text-2xl">
                        Check-in y Check-out
                    </h2>
                    <div className="2xl:mt-10 lg:mt-5 mt-3 w-full flex flex-col md:w-1/2 gap-1 max-w-[468px] ">
                        <div className="flex flex-col flex-1 text-start">
                            <label className="text-sm font-medium">
                                Establece el horario de entrada y salida para
                                los huéspedes.
                            </label>

                            <div className="grid w-full grid-cols-2 gap-2 lg:gap-5">
                                <div>
                                    <div className="mt-5 mb-2 ml-2 2xl:text-base text-sm italic text-[#4D4D4D] font-medium">
                                        <p>Check-In</p>
                                    </div>
                                    <div className="flex items-center text-base italic text-gray-500 border-2  border-[#CCCCCC] w-full rounded-lg outline-none">
                                        <input
                                            type="time"
                                            placeholder="Dias"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    check_in: e.target.value,
                                                })
                                            }
                                            className=" 2xl:p-2 p-1.5 outline-none cursor-pointer text-[#3C3C3C] rounded-lg w-full 2xl:text-base text-sm"
                                            value={input.check_in}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <div className="mt-5 mb-2 ml-2 2xl:text-base text-sm italic text-[#4D4D4D] font-medium ">
                                        <p>Check-Out</p>
                                    </div>
                                    <div className="flex items-center 2xl:text-base text-sm italic text-gray-500 border-2  border-[#CCCCCC] w-full rounded-lg outline-none">
                                        <input
                                            type="time"
                                            placeholder="Dias"
                                            onChange={(e) =>
                                                setInput({
                                                    ...input,
                                                    check_out: e.target.value,
                                                })
                                            }
                                            className=" 2xl:p-2 p-1.5 2xl:text-base text-sm outline-none cursor-pointer text-[#3C3C3C] rounded-lg w-full"
                                            value={input.check_out}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/2 mx-auto">
                    <ButtonSiguiente
                        onSubmit={() => handleSubmit()}
                        texto="Finalizar"
                    />
                </div>
            </div>
        </div>
    );
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { logOut } from "../redux/reducers/authReducer";

export const NavbarsAvatar = () => {
    const [showOptions, setShowOptions] = useState(false);
    const { id, avatar, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleNavigateContrasena = () => {
        if (tipo_usuario <= 2) {
            navigate("/dashboard-admin/cambiar-contraseña");
        } else if (tipo_usuario === 5) {
            navigate("/dashboard-cliente/cambiar-contraseña");
        } else if (tipo_usuario === 6) {
            navigate("/dashboard-corredor/cambiar-contraseña");
        } else {
            navigate("/dashboard/cambiar-contraseña");
        }
    };
    const handleNavigateDashboard = () => {
        if (tipo_usuario <= 2) {
            navigate("/dashboard-admin/casas");
        } else if (tipo_usuario === 5 || tipo_usuario === 7) {
            navigate("/dashboard-cliente/reservas");
        } else if (tipo_usuario === 6) {
            navigate("/dashboard-corredor/casas");
        } else if (tipo_usuario === 8) {
            navigate("/dashboard-hotel/habitaciones");
        } else if (tipo_usuario === 9) {
            navigate("dashboard-cliente-hotel/chat");
        } else {
            navigate("/dashboard/reservas");
        }
    };
    return (
        <>
            {id ? (
                <div
                    className="relative flex flex-col items-center"
                    onBlur={() => setShowOptions(false)}>
                    <button onClick={() => setShowOptions(true)}>
                        <img
                            src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`}
                            className="z-40 object-cover border border-gray-600 rounded-full cursor-pointer w-9 h-9"
                            alt="foto de perfil"
                        />
                    </button>
                    <div
                        className={`${
                            showOptions
                                ? "h-max opacity-100 visible"
                                : "h-0 opacity-0 invisible"
                        }  absolute flex flex-col z-0 ml-4 top-[2.3rem] -left-40 w-max text-black right-1 bg-white transition-all duration-100 delay-200 ease-out`}>
                        <div className="flex flex-col p-3 border">
                            <div
                                onClick={() => handleNavigateDashboard()}
                                className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom">
                                <svg
                                    className="w-6 h-6 text-blue-blueFrom"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2.5"
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                    />
                                </svg>
                                <span className="text-sm">
                                    {" "}
                                    {tipo_usuario === 3
                                        ? "Mi dashboard"
                                        : tipo_usuario === 5
                                        ? "Mis reservas"
                                        : tipo_usuario === 6
                                        ? "Mis casas"
                                        : tipo_usuario === 8
                                        ? "Mis Habitaciones"
                                        : tipo_usuario === 7
                                        ? "Mis reservas"
                                        : "Mis reservas"}{" "}
                                </span>
                            </div>
                            <hr className="my-2" />
                            <div
                                onClick={() => handleNavigateContrasena()}
                                className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom">
                                <svg
                                    className="w-6 h-6 text-blue-blueFrom"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                                    />
                                </svg>
                                <span className="text-sm">
                                    Cambiar contraseña
                                </span>
                            </div>
                            <hr className="my-2" />
                            <div
                                onClick={() => {
                                    navigate("/");
                                    dispatch(logOut());
                                }}
                                className="flex items-center gap-2 cursor-pointer hover:scale-105 hover:text-blue-blueFrom">
                                <svg
                                    className="ml-0.5 w-6 h-6"
                                    stroke="#0060ff"
                                    fill="none">
                                    <g>
                                        <path d="M19.2854 12.0002L11.2727 12.0002"></path>
                                        <path d="M16.5101 15.6364L19.9999 12L16.5101 8.36363"></path>
                                        <path d="M13.4545 7V4H4V20H13.4545V17"></path>
                                    </g>
                                </svg>
                                <span className="absolute ml-8 text-sm">
                                    Cerrar sesión
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <button
                    className="flex items-center justify-center gap-2 px-2 h-[33.6px] bg-white border rounded-lg"
                    onClick={() => navigate("/login")}>
                    <p className=" bg-[#1C4CBB] rounded-full p-1">
                        <svg
                            className="w-4 h-4"
                            id="Capa_2"
                            data-name="Capa 2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 79.9 100">
                            <g id="Testimonios">
                                <g>
                                    <path
                                        d="m39.86,99.95c-12.39,0-24.78-.05-37.17.05-2.16.02-2.71-.71-2.69-2.76.1-7.8.02-15.6.05-23.4.05-12.19,9.19-22.67,21.26-24.23.83-.11,2.15.51,2.62,1.21,8.05,11.93,24.16,11.96,31.97-.04.71-1.09,1.47-1.41,2.69-1.26,11.6,1.43,21.14,11.95,21.26,23.62.08,8.16-.03,16.33.06,24.49.02,1.86-.66,2.36-2.43,2.35-12.54-.06-25.08-.03-37.61-.03"
                                        fill="white"
                                    />
                                    <path
                                        d="m39.92,0c11.68,0,21.09,9.3,21.12,20.86.03,11.9-9.33,21.31-21.16,21.29-11.69-.02-21.04-9.46-21.01-21.23C18.9,9.32,28.27,0,39.92,0"
                                        fill="white"
                                    />
                                </g>
                            </g>
                        </svg>
                    </p>
                    <div className="text-[#1C4CBB] text-[9px]">
                        <p className="font-bold">Mi cuenta</p>
                        <p> acceso</p>
                    </div>
                </button>
            )}
        </>
    );
};

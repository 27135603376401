import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../hooks/hooks";
import { useDispatchDominioCorredor } from "../../../hooks/useDispatchDominioCorredor";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import nombreAlojamiento from "../../../img/onboarding/escoger_tema.jpg";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { ButtonSiguiente } from "../../onboardingPropietario/ButtonSiguiente";

interface selectedFile {
    banner: File | string;
}
export const RedesSocialesCorredor = () => {
    const {
        descripcion,
        id: id_corredor,
        avatar,
        banner,
        tiktok,
        instagram,
        facebook,
        plan,
    } = useAppSelector((state) => state.corredor);
    const dispatchDominio = useDispatchDominioCorredor();
    const [input, setInput] = useState({
        instagram: instagram,
        facebook: facebook,
        tiktok: tiktok,
    });

    const { id: id_usuario } = useAppSelector((state) => state.usuario_logeado);

    const navigate = useNavigate();

    const handleInputChange = (e: any) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        try {
            if (id_corredor) {
                await fetchAxiosToken({
                    url: `/corredor/updateRedesSociales/${id_corredor}`,
                    method: "put",
                    body: input,
                });

                // await fetchAxiosToken({
                //     url: `/casa/putPagina/${id_corredor}`,
                //     method: "put",
                //     body: {
                //         pagina: "capacidad",
                //     },
                // });
                dispatchDominio();
                navigate(`/`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={nombreAlojamiento}
                        alt="nombreAlojamiento"
                        className="w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Mantente conectado con tus huéspedes
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`${
                        plan > 0
                            ? "/dashboard-corredor/onboarding/datos"
                            : "/dashboard-corredor/onboarding/suscripcion"
                    }
                `}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col pb-4">
                    <div className="flex flex-col justify-center w-10/12 mx-auto">
                        <label className="ml-2 text-xl font-medium">
                            Instagram
                        </label>
                        <input
                            type={"text"}
                            min={0}
                            name="instagram"
                            value={input.instagram}
                            onChange={handleInputChange}
                            className="w-full max-w-sm p-3 mx-auto mt-2 text-black border border-gray-500 rounded-lg placeholder:italic"
                        />
                    </div>
                    <div className="flex flex-col justify-center w-10/12 mx-auto">
                        <label className="ml-2 text-xl font-medium">
                            Facebook
                        </label>
                        <input
                            type={"text"}
                            min={0}
                            name="facebook"
                            value={input.facebook}
                            onChange={handleInputChange}
                            className="w-full max-w-sm p-3 mx-auto mt-2 text-black border border-gray-500 rounded-lg placeholder:italic"
                        />
                    </div>
                    <div className="flex flex-col justify-center w-10/12 mx-auto">
                        <label className="ml-2 text-xl font-medium">
                            Tiktok
                        </label>
                        <input
                            type={"text"}
                            min={0}
                            step={1}
                            name="tiktok"
                            value={input.tiktok}
                            onChange={handleInputChange}
                            className="w-full max-w-sm p-3 mx-auto mt-2 text-black border border-gray-500 rounded-lg placeholder:italic"
                        />
                    </div>

                    <div className="w-2/3 mx-auto">
                        <ButtonSiguiente
                            onSubmit={() => handleSubmit()}
                            texto="Finalizar"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

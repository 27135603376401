import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { usuario } from "../../pages/dashboardPropietarios/subUsuario/ModalActualizarContrasenaUsuario";

const INITIAL_STATE = {
    checking: true,
    id: "",
    nombre: "",
    apellido: "",
    correo: "",
    celular: "",
    grupo_trabajo: "",
    avatar: "",
    tipo_usuario: null,
};

export const verifyToken = createAsyncThunk("auth/getUser", async () => {
    try {
        const token = await fetchAxiosToken({
            url: `/validateToken/`,
            method: "post",
        });

        const data = await fetchAxiosToken({
            url: `/usuarios/getOne/${token.id}`,
        });

        return { ...data.usuario };
    } catch (error) {
        console.log(error);
        return { ...INITIAL_STATE, checking: false };
    }
});

export const usuario_logeado = createSlice({
    name: "usuario_logeado",
    initialState: INITIAL_STATE,
    reducers: {
        logIn: (state, action) => {
            return {
                ...action.payload,
                checking: false,
            };
        },
        logOut: () => {
            localStorage.removeItem("token");
            return {
                ...INITIAL_STATE,
                checking: false,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(verifyToken.fulfilled, (state, action) => {
            return {
                ...action.payload,
            };
        });
    },
});

// Action creators are generated for each case reducer function
export const { logIn, logOut } = usuario_logeado.actions;

export default usuario_logeado.reducer;

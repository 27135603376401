import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ToggleButton from "../../components/helpers/ToggleButton";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { set_landing_house } from "../../redux/reducers/landingReducer";
import SpinnerVerifyToken from "../../routes/SpinnerVerifyToken";
import { ModalChat } from "../modalEdit/ModalChat";
import Mapa from "./Mapa";

import Carrousel from "./Carrousel";
import Habitaciones from "./Habitaciones";

import Navbar from "./Navbar/Navbar";

import Servicios from "./Servicios";

import Descripciones from "./Descripciones";
import Reviews from "./Reviews";

import Eventos from "./Eventos";
import { FooterLanding } from "../modalEdit/FooterLanding";
import HabitacionesHotel from "../design1New/HabitacionesHotel";

const Design3 = (tipo_landing: { tipo_landing: number }) => {
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [toggleButton, setToggleButton] = useState(false);

    const { id_propietario, checking, banner } = useAppSelector(
        (state) => state.landing_house.house
    );
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    return (
        <>
            {!banner ? (
                <SpinnerVerifyToken />
            ) : (
                <div className="">
                    <Navbar />
                    <div className="flex flex-col bg-white shadow-lg">
                        <div id="fotos">
                            <Carrousel
                                tipo_landing={tipo_landing.tipo_landing}
                                toggleButton={toggleButton}
                            />
                        </div>

                        <div className="flex flex-col gap-8 px-4 py-5 bg-white lg:px-16">
                            {(id_propietario === id || tipo_usuario === 4) && (
                                <div className="flex justify-end w-10/12 mt-3">
                                    <p>
                                        <span className="text-[#053465] font-medium mr-2">
                                            Activar edicion rapida
                                        </span>
                                    </p>
                                    <ToggleButton
                                        isToggled={false}
                                        onChangeToggle={(e) =>
                                            setToggleButton(e)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div
                            id="servicios"
                            className="flex flex-col-reverse grid-cols-2 text-lg lg:grid px-2 xs:px-10 md:px-28 bg-[#F2F5FF] py-5 lg:py-10 2xl:py-20 gap-14"
                        >
                            <Descripciones toggleButton={toggleButton} />
                            <Servicios toggleButton={toggleButton} />
                        </div>

                        {tipo_landing.tipo_landing !== 8 && (
                            <div
                                id="habitaciones"
                                className="p-4 py-5 bg-white 2xl:py-10 lg:px-16"
                            >
                                <Habitaciones toggleButton={toggleButton} />
                            </div>
                        )}

                        {tipo_landing.tipo_landing === 8 && (
                            <div
                                className="px-4 py-5 bg-white lg:px-16"
                                id="habitaciones"
                            >
                                <HabitacionesHotel
                                    toggleButton={toggleButton}
                                />
                            </div>
                        )}

                        <div id="actividades">
                            <Eventos toggleButton={toggleButton} />
                        </div>

                        <div className="px-1 2xl:p-4 lg:px-16 " id="resenas">
                            <Reviews color={"[#baad85]"} />
                        </div>

                        <div className="px-4 2xl:p-4 lg:px-16 " id="ubicacion">
                            <Mapa toggleButton={toggleButton} />
                        </div>
                        <div>
                            <FooterLanding toggleButton={toggleButton} />
                        </div>
                    </div>
                    <ModalChat bg={"bg-black"} />
                </div>
            )}
        </>
    );
};

export default Design3;

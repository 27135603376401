import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowDateToUser } from "../../components/ShowDateToUser";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import { useDescuentoEstadia } from "../../hooks/useDescuentoEstadia";
import { usePrecioPersonasAdicionales } from "../../hooks/usePrecioPersonasAdicionales";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import { useReservaFinalPrice } from "../../hooks/useReservaFinalPrice";
import {
    insert_servicios_adicionales,
    remove_servicios_adicionales,
    set_precio_servicios_adicionales,
} from "../../redux/reducers/precioReservaReducer";
import { getPriceAditionalServicePerDay } from "./getPriceAditionalServicePerDay";
import { infoCasa } from "./typeCasa";
import { ModalCupon } from "../modalEdit/ModalCupon";

interface Props {
    infoCasa: infoCasa;
    servicios_adicionales: any[];
}

interface huespedesProps {
    name: "mascotas" | "ninos" | "adultos";
    action: "sumar" | "restar";
}

export const BodyReserva = ({ servicios_adicionales, infoCasa }: Props) => {
    const [huespedes, setHuespedes] = useState({
        adultos: 0,
        ninos: 0,
        mascotas: 0,
    });
    const [checkedInputs, setCheckedInputs] = useState<any[]>([]);
    const reserva = useAppSelector((state) => state.precios_reserva);
    const infoCasaRedux = useAppSelector((state) => state.landing_house.house);
    const [cupon, setCupon] = useState(0);
    const { reservaFinal } = useReservaFinalPrice({});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const { price_to_currency } = usePriceToSelectedCurrency();
    const precio_alquiler = price_to_currency(
        reserva.precio_alquiler + reserva.precio_personas_adicionales
    );
    const [precioServiciosAdicionales, setPrecioServiciosAdicionales] =
        useState(0);
    usePrecioPersonasAdicionales({ huespedes, infoCasa });
    useDescuentoEstadia(infoCasa);

    useEffect(() => {
        dispatch(remove_servicios_adicionales());

        const newCheckedInputs = servicios_adicionales.filter(
            (service) => service.opcional === "No"
        );

        setCheckedInputs(newCheckedInputs.map((input) => Number(input.id)));

        servicios_adicionales.forEach((currentService) => {
            const service_final_price = getPriceAditionalServicePerDay({
                dia_inicial: reserva.dates_selected.initial_date,
                dia_final: reserva.dates_selected.final_date,
                servicio_adicional: currentService,
            });

            dispatch(
                insert_servicios_adicionales({
                    id: currentService.id,
                    nombre: currentService.nombre,
                    precio_final: service_final_price,
                    opcional: currentService.opcional,
                })
            );
        });
    }, [reserva.dates_selected]);

    useEffect(() => {
        const services = servicios_adicionales.filter((service) =>
            checkedInputs.includes(service.id)
        );

        const final_price = services.reduce((acc, currentService, idx) => {
            const service_final_price = getPriceAditionalServicePerDay({
                dia_inicial: reserva.dates_selected.initial_date,
                dia_final: reserva.dates_selected.final_date,
                servicio_adicional: currentService,
            });

            return (acc += service_final_price);
        }, 0);

        setPrecioServiciosAdicionales(price_to_currency(final_price));
        dispatch(set_precio_servicios_adicionales(final_price));
    }, [checkedInputs]);

    const handleInputClick = ({ name, action }: huespedesProps) => {
        const TOTAL_HUESPEDES = huespedes.adultos + huespedes.ninos;

        if (action === "sumar") {
            switch (name) {
                case "adultos":
                    if (TOTAL_HUESPEDES === infoCasa.personas) return;
                    setHuespedes({
                        ...huespedes,
                        [name]: huespedes[name] + 1,
                    });
                    break;
                case "ninos":
                    if (TOTAL_HUESPEDES === infoCasa.personas) return;
                    setHuespedes({
                        ...huespedes,
                        [name]: huespedes[name] + 1,
                    });
                    break;
                case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.min(
                            infoCasa.mascotas,
                            huespedes[name] + 1
                        ),
                    });
                    break;
                default:
                    break;
            }
        } else {
            switch (name) {
                case "adultos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                case "ninos":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                case "mascotas":
                    setHuespedes({
                        ...huespedes,
                        [name]: Math.max(0, huespedes[name] - 1),
                    });
                    break;
                default:
                    break;
            }
        }
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked)
            setCheckedInputs((prev) => [...prev, Number(e.target.value)]);
        else
            setCheckedInputs((prev) =>
                prev.filter((input) => input !== Number(e.target.value))
            );
    };

    const handleSubmit = () => {
        if (huespedes.adultos === 0) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Por favor primero indica la cantidad de huéspedes",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        const servicios_adicionales_seleccionados =
            reserva.servicios_adicionales.filter((servicio) =>
                checkedInputs.includes(servicio.id)
            );

        const descuento_estadia = reserva.descuento_estadia
            ? reserva.descuento_estadia
            : { descuento: 0 };

        localStorage.setItem(
            "reserva",
            JSON.stringify({
                ...reserva,
                precio_alquiler: price_to_currency(reserva.precio_alquiler),
                garantia: price_to_currency(Number(infoCasaRedux.garantia)),
                tipo_garantia: infoCasa.tipo_garantia,
                precio_personas_adicionales: price_to_currency(
                    reserva.precio_personas_adicionales
                ),
                descuento_estadia: {
                    ...descuento_estadia,
                    descuento: price_to_currency(descuento_estadia.descuento),
                },
                precio_servicios_adicionales: precioServiciosAdicionales,
                precio_total: reservaFinal - cupon,
                huespedes,
                servicios_adicionales: servicios_adicionales_seleccionados.map(
                    (service) => {
                        return {
                            ...service,
                            precio_final: price_to_currency(
                                service.precio_final
                            ),
                        };
                    }
                ),
                datos_alojamiento: {
                    nombre: infoCasa.nombre,
                    moneda: SELECTED_CURRENCY,
                },
                cupon: cupon,
            })
        );

        navigate("/checkout");
    };

    return (
        <div className="w-full p-2 mt-2 text-left border rounded-lg">
            {reserva.error_message ? (
                <div className="font-medium text-center text-red-500">
                    {reserva.error_message}
                </div>
            ) : (
                <>
                    <div>
                        <span>Reserva desde el</span>
                        <ShowDateToUser
                            className="mx-1 font-medium"
                            date={reserva.dates_selected?.initial_date}
                        />
                        <span>hasta el</span>
                        <ShowDateToUser
                            className="mx-1 font-medium"
                            date={reserva.dates_selected?.final_date}
                        />
                    </div>
                    <div className="flex w-full mt-2 gap-7">
                        <div className="flex flex-col w-full gap-1 text-left">
                            <label className="text-sm ">Adultossss</label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <span className="w-full p-2 font-medium text-center border  rounded-lg">
                                    {huespedes.adultos}
                                </span>
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "adultos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-1 text-left">
                            <label className="text-sm">Niños</label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <span className="w-full p-2 font-medium text-center border rounded-lg">
                                    {huespedes.ninos}
                                </span>
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "ninos",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    {infoCasa.mascotas > 0 ? (
                        <div className="flex flex-col gap-1 text-left">
                            <label className="text-sm">Mascotas</label>
                            <div className="grid grid-cols-[20%_1fr_20%] w-1/2 pr-4 grid-flow-col gap-2">
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "restar",
                                            name: "mascotas",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                                <span className="w-full p-2 font-medium text-center border rounded-lg">
                                    {huespedes.mascotas}
                                </span>
                                <button
                                    onClick={() =>
                                        handleInputClick({
                                            action: "sumar",
                                            name: "mascotas",
                                        })
                                    }
                                    className="flex items-center justify-center w-full border rounded-lg hover:scale-105"
                                >
                                    <svg
                                        className="w-6 h-6 text-blue-blueFrom"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex justify-between w-full mt-4">
                        <span className="underline">Precio de alquiler: </span>
                        <span className="font-medium">
                            {precio_alquiler} {SELECTED_CURRENCY}
                        </span>
                    </div>
                    <div className="flex w-full mt-4 ">
                        {<ModalCupon setCupon={setCupon} cupon={cupon} />}
                    </div>
                    {reserva.descuento_estadia ? (
                        <div className="flex justify-between w-full mt-4 text-green-600">
                            <span className="underline">
                                Descuento por larga estadía:{" "}
                            </span>
                            <span className="font-medium text-end min-w-max">
                                -{" "}
                                {price_to_currency(
                                    reserva.descuento_estadia["descuento"]
                                )}{" "}
                                {SELECTED_CURRENCY}
                            </span>
                        </div>
                    ) : null}

                    {infoCasa.garantia !== "0.00" &&
                        infoCasa.tipo_garantia === "Pago con reserva" && (
                            <div className="flex justify-between w-full mt-4">
                                <span className="underline">Garantia: </span>
                                <span className="font-medium">
                                    {infoCasa.garantia} {SELECTED_CURRENCY}
                                </span>
                            </div>
                        )}

                    <div className="flex flex-col">
                        <div className="flex flex-col w-full gap-1 mt-4 text-left">
                            <span className="underline">
                                Servicios adicionales:{" "}
                            </span>
                            {reserva.servicios_adicionales?.map((service) => (
                                <div
                                    key={service.id}
                                    className="flex items-center justify-between"
                                >
                                    <div className="flex items-center gap-2">
                                        <input
                                            type={"checkbox"}
                                            disabled={service.opcional === "No"}
                                            checked={checkedInputs.includes(
                                                service.id
                                            )}
                                            value={service.id}
                                            onChange={handleCheckbox}
                                        />
                                        <span>{service.nombre}</span>
                                    </div>

                                    {service.precio_final ? (
                                        <span
                                            className={
                                                !checkedInputs.includes(
                                                    service.id
                                                )
                                                    ? "font-medium text-gray-300"
                                                    : "font-medium"
                                            }
                                        >
                                            {price_to_currency(
                                                service.precio_final
                                            )}{" "}
                                            {SELECTED_CURRENCY}{" "}
                                        </span>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-between w-full mt-4">
                        <span className="underline">
                            Precio total de la reserva:{" "}
                        </span>
                        <span className="font-medium">
                            {reservaFinal - cupon} {SELECTED_CURRENCY}
                        </span>
                    </div>
                    <button
                        onClick={() => handleSubmit()}
                        className="flex w-8/12 p-2 mx-auto mt-4 text-white border rounded-lg bg-gradient-to-tr from-blue-blueFrom to-blue-blueTo hover:scale-105"
                    >
                        <span className="w-full text-center">
                            Confirmar reserva
                        </span>
                    </button>

                    {infoCasa.garantia !== "0.00" &&
                        infoCasa.tipo_garantia === "Pago al ingreso casa" && (
                            <div className="flex w-full px-1 mt-4 bg-blue-200 border-4 border-blue-300 rounded-lg">
                                <span className="underline">Garantía: </span>
                                <span className="ml-2 italic font-thin ">
                                    Deberás pagar {infoCasa.garantia}{" "}
                                    {SELECTED_CURRENCY} en efectivo al momento
                                    de llegar a la propiedad
                                </span>
                            </div>
                        )}
                </>
            )}
        </div>
    );
};

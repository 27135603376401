import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { DiaNoDisponible } from "./DiasNoDisponiblesHabitacionHotel";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { InputDate } from "../../../components/InputDate";

const DAYS = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
];

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    getAllDias: () => void;
    selectedDay: DiaNoDisponible | null;
}

type errorObject = { [key: string]: string };

export const ModalEditarDia = ({
    modalVisible,
    setModalVisible,
    getAllDias,
    selectedDay,
}: ModalProps) => {
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<DiaNoDisponible>({
        tipo: "dia",
        fecha_inicio: "",
        fecha_final: "",
        dia_inicio: "Lunes",
        dia_final: "Lunes",
        motivo: "",
        otro_motivo: "",
    });
    const [errors, setErrors] = useState<DiaNoDisponible>({});
    const [pasos, setPasos] = useState(1);
    useEffect(() => {
        if (selectedDay) {
            setInput({
                ...selectedDay,
            });
        }
    }, [selectedDay]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: DiaNoDisponible) => {
        let errores: errorObject = {};

        if (pasos === 1) {
            if (input.tipo === "dia") {
                if (!form.dia_inicio) {
                    errores.dia_inicio = "Debe ingresar un día válido";
                } else if (!form.dia_final) {
                    errores.dia_final = "Debe ingresar un día válido";
                }
            }
            if (!form.fecha_inicio) {
                errores.fecha_inicio = "Debe ingresar una fecha válida";
            } else if (!form.fecha_final) {
                errores.fecha_final = "Debe ingresar una fecha válida";
            }
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            if (pasos === 0) {
                setPasos(1);
            } else if (pasos === 1) {
                setPasos(2);
            } else if (pasos === 2) {
                try {
                    setLoading(true);
                    const data = await fetchAxiosToken({
                        url: `/disponibilidades_habitaciones_hoteles/put/${selectedDay?.id}`,
                        body: input,
                        method: "put",
                    });

                    setLoading(false);
                    setModalVisible(false);
                    setPasos(0);
                    setInput({
                        tipo: "dia",
                        fecha_final: "",
                        fecha_inicio: "",
                        dia_final: "",
                        dia_inicio: "",
                        otro_motivo: "",
                    });
                    setErrors({});
                    getAllDias();
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Creado con exito",

                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            onClose={() => {
                setErrors({});
                setInput({
                    tipo: "dia",
                    fecha_final: "",
                    fecha_inicio: "",
                    dia_final: "",
                    dia_inicio: "",
                    otro_motivo: "",
                });
            }}>
            <div className="flex flex-col items-center gap-2 p-3 2xl:p-6">
                <h1 className="text-base font-medium 2xl:text-lg">
                    Editar días no disponibles
                </h1>
                {pasos === 0 && (
                    <>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Elige si deseas bloquear por dias de la semana o por
                            fechas
                        </label>
                        <select
                            value={input.tipo}
                            name="tipo"
                            onChange={handleInputChange}
                            className="w-full max-w-xs p-2 border rounded-lg shadow-md 2xl:p-3 placeholder:italic">
                            <option value={"dia"}>Dias de la semana</option>
                            <option value={"fecha"}>Fechas especiales</option>
                        </select>
                    </>
                )}

                {pasos === 1 && (
                    <>
                        {/*  <div
                            onClick={() => setPasos(0)}
                            className={`text-black flex w-full max-w-xs py-5 items-center 2xl:text-base text-sm  cursor-pointer hover:underline hover:scale-105`}
                        >
                            <svg
                                className="w-5 h-5 mt-0.5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <polyline points="15 18 9 12 15 6" />
                            </svg>
                            <span className="mx-2">Regresar</span>
                        </div> */}

                        {input.tipo === "dia" ? (
                            <label className="flex w-full max-w-xs mt-3 ml-2 text-sm font-medium text-blue-500">
                                Selecciona un rango de fechas que desees
                                bloquear los dias de la semana
                            </label>
                        ) : (
                            <label className="flex w-full max-w-xs mt-3 ml-2 text-sm font-medium text-blue-500">
                                Selecciona en que rango de fechas deseas
                                bloquear tu calendario
                            </label>
                        )}

                        <label className="flex w-full max-w-xs mt-2 ml-2 text-sm font-medium">
                            Fecha inicial
                        </label>
                        <InputDate
                            selectedDay={input.fecha_inicio}
                            onChange={(date) =>
                                setInput({
                                    ...input,
                                    fecha_inicio: date,
                                })
                            }
                            className="flex max-w-xs p-2 mx-auto border rounded-lg shadow-md 2xl:p-3 placeholder:italic"
                            placeholderText="21/02/2023"
                        />
                        {errors.fecha_inicio && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.fecha_inicio}
                            </p>
                        )}
                    </>
                )}

                {pasos === 1 && (
                    <>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Fecha final
                        </label>
                        <InputDate
                            selectedDay={input.fecha_final}
                            onChange={(date) =>
                                setInput({
                                    ...input,
                                    fecha_final: date,
                                })
                            }
                            className="flex max-w-xs p-2 mx-auto border rounded-lg shadow-md 2xl:p-3 placeholder:italic"
                            placeholderText="21/02/2023"
                        />
                        {errors.fecha_final && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.fecha_final}
                            </p>
                        )}
                    </>
                )}

                {pasos === 1 && input.tipo === "dia" && (
                    <>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium text-blue-500">
                            Elige los dias de la semana deseas bloquear tu
                            calendario
                        </label>
                        <label className="flex w-full max-w-xs mt-2 ml-2 text-sm font-medium">
                            Dia de la semana inicio
                        </label>
                        <select
                            value={input.dia_inicio}
                            name="dia_inicio"
                            onChange={handleInputChange}
                            className="w-full max-w-xs p-2 border rounded-lg shadow-md 2xl:p-3 placeholder:italic">
                            {DAYS.map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </select>
                        {errors.dia_inicio && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.dia_inicio}
                            </p>
                        )}
                    </>
                )}

                {pasos === 1 && input.tipo === "dia" && (
                    <>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Dia de la semana final
                        </label>
                        <select
                            value={input.dia_final}
                            name="dia_final"
                            onChange={handleInputChange}
                            className="w-full max-w-xs p-2 border rounded-lg shadow-md 2xl:p-3 placeholder:italic">
                            {DAYS.map((day) => (
                                <option key={day} value={day}>
                                    {day}
                                </option>
                            ))}
                        </select>
                        {errors.dia_final && (
                            <p className="my-1 text-sm font-bold text-red-500 text-md">
                                {errors.dia_final}
                            </p>
                        )}
                    </>
                )}

                {pasos === 2 && (
                    <>
                        <div
                            onClick={() => setPasos(1)}
                            className={`text-black flex w-full max-w-xs py-5 items-center 2xl:text-base text-sm  cursor-pointer hover:underline hover:scale-105`}>
                            <svg
                                className="w-5 h-5 mt-0.5 text-black"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                <polyline points="15 18 9 12 15 6" />
                            </svg>
                            <span className="mx-2">Regresar</span>
                        </div>
                        <label className="flex w-full max-w-xs mt-3 ml-2 text-sm font-medium">
                            Motivo
                        </label>
                        <select
                            value={input.motivo}
                            name="motivo"
                            onChange={handleInputChange}
                            className="w-full max-w-xs p-2 border rounded-lg shadow-md 2xl:p-3 placeholder:italic">
                            <option value={"Bluecorner"}>Bluecorner</option>
                            <option value={"Airbnb"}>Airbnb</option>
                            <option value={"Booking.com"}>Booking.com</option>
                            <option value={"Alquilado"}>Alquilado</option>
                            <option value={"En mantenimiento"}>
                                En mantenimiento
                            </option>
                            <option value={"Evento privado"}>
                                Evento privado
                            </option>
                            <option value={"Otro"}>Otro</option>
                        </select>
                    </>
                )}
                {pasos === 2 && input.motivo === "Otro" && (
                    <>
                        <label className="flex w-full max-w-xs ml-2 text-sm font-medium">
                            Detalle
                        </label>
                        <input
                            value={input.otro_motivo}
                            onChange={handleInputChange}
                            className="w-full max-w-xs p-2 border rounded-lg shadow-md 2xl:p-3 placeholder:italic"
                            type="text"
                            name="otro_motivo"
                        />
                    </>
                )}

                <button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                    className="p-3 mt-6 font-medium 2xl:text-base text-sm text-white border rounded-lg bg-[#1C4CBB] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default">
                    {Number(pasos) > 1
                        ? "Añadir dia no disponible"
                        : "Siguiente"}
                </button>
            </div>
        </ResponsiveModalAbsolute>
    );
};
